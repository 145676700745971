import { helpers } from '../utils/helpers';
import { constants } from '../utils/constants';
import { translate } from '../utils/translation';
import { icons } from '../components/base/icon/icon';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { map } from '../components/app/map/map';
import { layerActions } from '../components/app/layers/layer/layer';
import { layers } from '../components/app/layers/layers';

const _ = require("lodash");

export const geoFeeds = {    
    getGeoFeeds: (o) =>{
        return legacyEndpoints.service({
            name: 'GetFilteredGeoFeedLayers',
            parameters: {
                Columns: 'isShared,name,type,userId',
                Page: 0,
                ResultCount: 10,
                Filter: '',
                SortColumn: 1,
                SortDirection: 'asc',
                Type: -1
            }
        });
    },   
    getGeoFeedLayer: async (o) =>{
        return await legacyEndpoints.service({
            name: 'GetGeoFeedLayer',
            parameters: {
                id: o.id      
            }
        });
    },      
    defaultGeoFeed: function() {
        return {
                id: helpers.emptyGuid(),
                name: '',
                type: 0,
                description: '',
                sources: []
        };
    },    
    createGeoFeed: async (o) =>{
        return await legacyEndpoints.service({
            name: 'CreateGeoFeedLayer',
            parameters: {
                layer: o      
            }
        });
    },    
    updateGeoFeed: async (o) =>{
        return await legacyEndpoints.service({
            name: 'UpdateGeoFeedLayer',
            parameters: {
                layer: o      
            }
        });
    },       
    deleteGeoFeed: async (o) =>{
        return await legacyEndpoints.service({
            name: 'DeleteGeoFeedLayers',
            parameters: {
                idList: [o]      
            }
        });
    },          
    getLayers: function() {
        return map.layers.filter(layer => layer.group === constants.layers.groups.geoFeeds)
      },
    loadGeoFeed: async (geoFeed) =>{
    
        const geoFeedLayer = await legacyEndpoints.service({
            name: 'GetGeoFeedLayer',
            parameters: {
                id: geoFeed.id      
            }
        });

        if (!_.isObject(geoFeedLayer)) return;
        
        var geoFeedUrl = await legacyEndpoints.handlers.getGeoFeedUrl({ id: geoFeed.id });
        
        var existingLayer = geoFeeds.getLayers().find(layer => { return layer.id.toLowerCase() === geoFeed.id });
        
        if (_.isObject(existingLayer))
            return;
                
        const isImageOverlay = geoFeedLayer.sources.some(source => source.isImageOverlay === true);

        var imageUrl;
        if(!isImageOverlay) {
            imageUrl = () =>{
                return legacyEndpoints.handlers.getGeoFeedUrl({ id: geoFeed.id });
            }
        } else {
            imageUrl = () =>{
                return legacyEndpoints.handlers.getTileBoundingBoxUrl({
                    id: geoFeed.id,
                    IsImageOverlayGeoFeed: true,
                    upperLeftLat: map.bounds.northWest.lat,
                    upperLeftLon: map.bounds.northWest.lon,
                    lowerRightLat: map.bounds.southEast.lat,
                    lowerRightLon: map.bounds.southEast.lon,
                    zoom: map.zoom,
                    allowLabels: false,
                    hiddenRanges: null,
                    pinPointCount: null
                });
            }
        }    

        var layer = map.addLayer({
            id: geoFeed.id,
            group: constants.layers.groups.geoFeeds,
            type: constants.layers.types.geoFeeds,
            metaData: {
                isImageOverlay: isImageOverlay
            },
            imageUrl: imageUrl,
            text: geoFeedLayer.name,
            visible: !_.isUndefined(geoFeed.visible) ? geoFeed.visible : true,
            data: {type: geoFeedLayer.type, preserveViewport: _.isBoolean(geoFeed.zoomToLayer) ? !geoFeed.zoomToLayer : false},
            actions: [{
                id: layerActions.visible,
                getActive: () => { return layer.visible; },
                getHalfActive: () => { return layer.oppositeVisibilityEntities.length > 0; },
                onClick: () =>{                        
                    layer.visible = !layer.visible;
                }
            },
            {
                id: layerActions.delete,
                onClick: () =>{
                    layer.dispose();
                }
            }],
            onChange: (c)=>{
                layers.update();
            }
        });

        if (geoFeedLayer.type === constants.geoFeed.wkt) {
            var wktData = await legacyEndpoints.dataService(geoFeedUrl);        

            var parsedData = helpers.parseWkt(wktData);

            layer.addEntity({
                id: geoFeed.id,
                text: geoFeed.name,
                type: constants.entities.polygon,
                paths: parsedData,                                         
                
            });            
        }

        layer.legend.push({
            text: geoFeedLayer.name,
            icon: icons.braille
        });

        layers.update();

        return layer;
    },        
    getType: (type) =>{
        switch (type)
        {
            default:
            case constants.geoFeed.geoRss:
                return 'GeoRSS';
            case constants.geoFeed.kml:
                return 'KML/KMZ';   
            case constants.geoFeed.geoJson:
                return 'GeoJSON';          
            case constants.geoFeed.wkt:
                return 'WKT';   
            case constants.geoFeed.gpx:
                return 'GPX';                                           
        }
    },   
    getAllowedExtensionsByType: (type) =>{
        switch (type)
        {
            default:
            case constants.geoFeed.geoRss:
                return ['rss','xml'];
            case constants.geoFeed.kml:
                return ['kml'];  
            case constants.geoFeed.kmz:
                return ['kmz'];                  
            case constants.geoFeed.geoJson:
                return ['json','geojson'];         
            case constants.geoFeed.wkt:
                return ['txt']; 
            case constants.geoFeed.gpx:
                return ['gpx','xml'];                                         
        }
    }    
};