import { ActionListener } from './actionListener'; 
import { constants } from '../utils/constants';
import { zIndexes } from '../utils/zIndex';

const _ = require("lodash"); 

export class Entity extends ActionListener  {

    constructor(layer, o) {
        super(o);

        o = _.isObject(o) ? o : {};
        
        var isClickable = _.isBoolean(o.clickable) ? o.clickable : layer.type !== constants.layers.types.temporary && layer.type !== constants.layers.types.activeEntity;
        var isTypeClickable = true;

        switch(o.type){
            case constants.entities.activePoint:
            case constants.entities.focusedPoint:
            case constants.entities.selectedPoint:
            case constants.entities.point:
            case constants.entities.pushpin:            
            case constants.entities.genericPoint:
            case constants.entities.cluster:
                isTypeClickable = layer.map._private._enablePointClicking;
                break;
            case constants.entities.activeShape:
            case constants.entities.focusedShape:
            case constants.entities.selectedShape:
            case constants.entities.circle:
            case constants.entities.polygon:
            case constants.entities.polyline:
            case constants.entities.tradeArea:
            case constants.entities.rectangle:
            case constants.entities.geoJson:
            case constants.entities.wkt:
            case constants.entities.standardGeography:
            case constants.entities.label:
                isTypeClickable = layer.map._private._enableShapeClicking;
                break;
        }

        this._private = {
            _anchor: _.isObject(o.anchor) ? o.anchor : null, 
            _area: _.isNumber(o.area) ? o.area: 0,
            _address: _.isString(o.address) ? o.address : '',            
            _child: null,
            _clickable: isTypeClickable && isClickable,
            _tempClickable: isClickable,
            _data: _.isObject(o.data) ? o.data : {},
            _description: _.isString(o.description) ? o.description : '',
            _dirty: _.isBoolean(o.dirty) && o.dirty,
            _draggable: _.isBoolean(o.draggable) && o.draggable,
            _entity: null,
            _fillColor: _.isObject(o.fillColor) ? o.fillColor : constants.polygons.defaults.fillColor,
            _preHoverFillColor: null,
            _groupId: _.isNull(o.groupId) || _.isUndefined(o.groupId) ? null : o.groupId,
            _id: _.isNull(o.id) || _.isUndefined(o.id) ? crypto.randomUUID() : o.id,
            _image: _.isString(o.image) ? o.image : null,
            _label: _.isString(o.label) ? o.label : _.isString(o.text) ? o.text : '',
            _labeled: _.isBoolean(o.labeled) && o.labeled,
            _layer: layer,
            _length: _.isNumber(o.length) ? o.length: 0,
            _location: _.isObject(o.location) ? o.location : {},
            _map: layer.map,
            _metaData: _.isObject(o.metaData) ? o.metaData : {},
            _onEdit: _.isFunction(o.onEdit) ? o.onEdit : () => {},
            _onDelete: _.isFunction(o.onDelete) ? o.onDelete : () => {},
            _onCancel: _.isFunction(o.onCancel) ? o.onCancel : () => {},
            _onMove: _.isFunction(o.onMove) ? o.onMove : () => {},
            _onReshape: _.isFunction(o.onReshape) ? o.onReshape : () => {},
            _paths: _.isArray(o.paths) ? o.paths : [],
            _hasMultiDimensionalPaths: false,
            _radius: _.isNumber(o.radius) ? o.radius: 0,
            _strokeColor: _.isObject(o.strokeColor) ? o.strokeColor : constants.polygons.defaults.strokeColor,
            _preHoverStrokeColor: null,
            _strokeStyle: _.isNumber(o.strokeStyle) ? o.strokeStyle : constants.polygons.defaults.strokeStyle,
            _strokeWidth: _.isNumber(o.strokeWidth) ? o.strokeWidth : constants.polygons.defaults.strokeWidth,
            _text: _.isString(o.text) ? o.text : '',
            _type: o.type,
            _subType: _.isInteger(o.subType) ? o.subType : null,
            _visible: _.isBoolean(o.visible) ? o.visible : layer.visible,
            _zIndex: _.isInteger(o.zIndex) ? o.zIndex: layer.zIndex,
            _width: _.isInteger(o.width) ? o.width: 0,
            _height: _.isInteger(o.height) ? o.height: 0,
            _errors: {
                _readOnly: (o) =>{
                    throw new Error('Entity "' + o.oproperty + '" property is read only and cannot be set to "' + o.value + '".');
                },
                _invalidTypeProperty: (o) => {
                    if (!_.includes(o.validTypes, this._private._type))
                        throw new Error('Entity "' + o.property + '" property is invalid ' + (_.isNull(o.value) || _.isUndefined(o.value) ? '.' : ' and cannot be changed to "' + o.value + '".'));
                }
            }
        };

        switch(this._private._type){
            default:
                break;
            case constants.entities.pushpin:                
                this.updatePushpinMetrics();
                break;
            case constants.entities.circle:
            case constants.entities.polygon:
            case constants.entities.polyline:
            case constants.entities.rectangle:
            case constants.entities.standardGeography:
                
                if (layer.type === constants.layers.types.temporary || layer.type === constants.layers.types.activeEntity)
                    break;

                this.addListener({
                    type: constants.listeners.mouseIn,
                    action: () =>{
                        
                        if (!this._private._child)
                            return;

                        this._private._preHoverStrokeColor = _.clone(this._private._strokeColor);
                        this._private._child.strokeColor = constants.polygons.hover.strokeColor;
                        
                        if (this._private._type !== constants.entities.polyline)
                        {
                            this._private._preHoverFillColor = _.clone(this._private._fillColor);
                            this._private._child.fillColor = constants.polygons.hover.fillColor;
                        }

                        this.addListener({
                            type: constants.listeners.mouseOut,
                            executeOnce: true,
                            action: () =>{
                                this._private._child.strokeColor = this._private._preHoverStrokeColor;

                                if (this._private._type !== constants.entities.polyline)
                                    this._private._child.fillColor = this._private._preHoverFillColor;

                                this._private._preHoverFillColor = null;
                                this._private._preHoverStrokeColor = null;
                            }
                        });
                     }
                });

                this.updateShapeMetrics();

                break;
        }
        
        if (this.type === constants.entities.label || this.subType === constants.entities.label)
        {
            this._private._layer._private._labels.push(this);
            this._private._zIndex = zIndexes({ type: constants.layers.types.label });
        }
        else
            this._private._layer._private._entities.push(this);
    }

    get address(){
        this._private._errors._invalidTypeProperty({ property: 'address', validTypes: [constants.entities.pushpin]  });
        return this._private._address;
    }

    set address(value){
        this._private._errors._invalidTypeProperty({ property: 'address', value: value, validTypes: [constants.entities.pushpin] });
        this._private._address = value;
    }

    get anchor(){
        this._private._errors._invalidTypeProperty({ property: 'anchor', validTypes: [constants.entities.genericPoint, constants.entities.focusedPoint, constants.entities.selectedPoint, constants.entities.activePoint, constants.entities.point, constants.entities.pushpin, constants.entities.label, constants.entities.cluster]  });
        return this._private._anchor;
    }

    set anchor(value){
        this._private._errors._invalidTypeProperty({ property: 'anchor', value: value, validTypes: [constants.entities.genericPoint, constants.entities.focusedPoint, constants.entities.selectedPoint, constants.entities.activePoint, constants.entities.point, constants.entities.pushpin, constants.entities.label, constants.entities.cluster] });
        this._private._anchor = value;
    }

    get area(){
        this._private._errors._invalidTypeProperty({ property: 'area', validTypes: [constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] }); 
        return this._private._area; 
    }
    set area(value){ 
        this._private._errors._invalidTypeProperty({ property: 'area', validTypes: [constants.entities.activeShape, constants.entities.selectedShape,constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        this._private._area = value; 
    }

    get clickable(){ return this._private._clickable; }
    set clickable(value){ this._private._clickable = value; }

    get data(){ 
        this._private._errors._invalidTypeProperty({ property: 'data', validTypes: [constants.entities.geoJson, constants.entities.wkt] });
        return this._private._data; 
    }
    set data(value){ 
        this._private._errors._invalidTypeProperty({ property: 'data', validTypes: [constants.entities.geoJson, constants.entities.wkt] });
        this._private._data = value; 
    }

    get description(){ return this._private._description; }
    set description(value){ this._private._description = value; }

    get dirty(){ return this._private._dirty; }
    set dirty(value){ this._private._dirty = value; }

    get draggable(){ return this._private._draggable; }
    set draggable(value){ this._private._draggable = value; }

    get fillColor(){
        this._private._errors._invalidTypeProperty({ property: 'fillColor', validTypes: [constants.entities.geoJson, constants.entities.wkt, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        return this._private._preHoverFillColor === null ? this._private._fillColor : this._private._preHoverFillColor;
    }
    set fillColor(value){
        this._private._errors._invalidTypeProperty({ property: 'fillColor', value: value, validTypes: [constants.entities.geoJson, constants.entities.wkt, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        this._private._fillColor = value;
    }

    get groupId(){ return this._private._groupId; }
    set groupId(value){ this._private._groupId = value; }

    get id(){ return this._private._id; }
    set id(value){ this._private._id = value; }

    get image(){
        this._private._errors._invalidTypeProperty({ property: 'image', validTypes: [constants.entities.genericPoint, constants.entities.focusedPoint, constants.entities.selectedPoint, constants.entities.activePoint, constants.entities.point, constants.entities.pushpin, constants.entities.cluster]  });
        return this._private._image;
    }

    set image(value){
        this._private._errors._invalidTypeProperty({ property: 'image', value: value, validTypes: [constants.entities.genericPoint, constants.entities.focusedPoint, constants.entities.selectedPoint, constants.entities.activePoint, constants.entities.point, constants.entities.pushpin, constants.entities.cluster]  });
        this._private._image = value;
    }

    get label(){ return this._private._label; }
    set label(value){ this._private._label = value; }

    get labeled(){ return this._private._labeled; }
    set labeled(value){ 
        this._private._labeled = value; 
        this._private._layer._private._onChange();
    }

    get length(){ return this._private._length; }
    set length(value){ this._private._length = value; }

    get location(){        
        return this._private._location;
    }

    set location(value){        
        this._private._location = value;
        this.updatePushpinMetrics();
    }

    get metaData(){ return this._private._metaData; }
    set metaData(value){ this._private._metaData = value; }

    get map(){ return this._private._map; }
    set map(value) { this._private._errors._readOnly({ property: 'map', value: value }); }

    get paths(){
        this._private._errors._invalidTypeProperty({ property: 'paths', validTypes: [constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        return this._private._paths;
    }

    set paths(value){
        this._private._errors._invalidTypeProperty({ property: 'paths', value: value, validTypes: [constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        this._private._paths = value;
        this.updateShapeMetrics();
    }

    get radius(){ return this._private._radius; }
    set radius(value){ this._private._radius = value; }

    get strokeColor(){
        this._private._errors._invalidTypeProperty({ property: 'strokeColor', validTypes: [constants.entities.geoJson, constants.entities.wkt, constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        return this._private._preHoverStrokeColor === null ? this._private._strokeColor : this._private._preHoverStrokeColor;
    }
    set strokeColor(value){
        this._private._errors._invalidTypeProperty({ property: 'strokeColor', value: value, validTypes: [constants.entities.geoJson, constants.entities.wkt, constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        this._private._strokeColor = value;
    }

    get strokeStyle() {
        this._private._errors._invalidTypeProperty({ property: 'strokeStyle', validTypes: [constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        return this._private._strokeStyle;
    }
    set strokeStyle(value) {
        this._private._errors._invalidTypeProperty({ property: 'strokeStyle', value: value, validTypes: [constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        this._private._strokeStyle = value;
    }

    get strokeWidth() {
        this._private._errors._invalidTypeProperty({ property: 'strokeWidth', validTypes: [constants.entities.geoJson, constants.entities.wkt, constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        return this._private._strokeWidth;
    }
    set strokeWidth(value) {
        this._private._errors._invalidTypeProperty({ property: 'strokeWidth', value: value, validTypes: [constants.entities.geoJson, constants.entities.wkt, constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        this._private._strokeWidth = value;
    }

    get text(){ return this._private._text; }
    set text(value) { this._private._text = value; }

    get type(){ return this._private._type; }
    set type(value) { this._private._errors._readOnly({ property: 'type', value: value }); }

    get subType(){ return this._private._subType; }
    set subType(value) { this._private._errors._readOnly({ property: 'subType', value: value }); }

    get layer(){ return this._private._layer; }
    set layer(value) { this._private._errors._readOnly({ property: 'layer', value: value }); }

    get visible(){ return this._private._visible; }
    set visible(value){
        this._private._visible = value;
        this._private._layer._private._onChange();
    }

    get hasMultiDimensionalPaths(){ return this._private._hasMultiDimensionalPaths; }

    get zIndex(){ return this._private._zIndex; }
    set zIndex(value){ this._private._zIndex = value; }

    get width(){ return this._private._width; }
    set width(value){ this._private._width = value; }

    get height(){ return this._private._height; }
    set height(value){ this._private._height = value; }

    click(e){
        this._executeListeners({ type: constants.listeners.click, properties: e });
    }

    dispose(){

        this._private._layer._private._entities = this._private._layer._private._entities.filter(entity => entity !== this);
        
        var childLabel = this._private._layer._private._labels.find(x => x.metaData?.parentId === this.id);
        if (_.isObject(childLabel))
            childLabel.dispose();

        if (_.isObject(this._private._metaData.selectionEntity) && this._private._layer.type === constants.layers.types.cosmetic)
            this._private._metaData.selectionEntity.dispose();
        
        this._private._layer._private._onChange();
    };

    updatePushpinMetrics(){
        
        if (this._private._layer.type !== constants.layers.types.cosmetic || this.type !== constants.entities.pushpin)
            return;

        var instance = this;
        this.map.geocode({ 
            query: `${this.location.lat} ${this.location.lon}`,
            callback: (o) =>{
                instance.address = _.isObject(o) && _.isObject(o.address) ? o.address.full : null;
            }
        });
    }

    updateShapeMetrics(){
        this.location = this.map.computeCenter({ locations: this.paths });
        this.length = this.map.computeLength({ locations: this.paths });        
        this._private._hasMultiDimensionalPaths = _.isArray(this.paths) && this.paths.length > 0 && _.isArray(this.paths[0]);

        if (this.type !== constants.entities.polyline)
            this.area = this.map.computeArea({ locations: this.paths });

        if (this.type === constants.entities.circle)
            this.radius = Math.sqrt(this.area/Math.PI);
    }

    onEdit(o){
        this._private._errors._invalidTypeProperty({ property: 'onEdit', validTypes: [constants.entities.pushpin, constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        this._private._onEdit(o);
    }

    onDelete(){
        this._private._errors._invalidTypeProperty({ property: 'onDelete', validTypes: [constants.entities.pushpin, constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        this._private._onDelete();
    }

    onCancel(){
        this._private._errors._invalidTypeProperty({ property: 'onCancel', validTypes: [constants.entities.pushpin, constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle, constants.entities.standardGeography] });
        this._private._onCancel();
    }

    move(){
        this._private._errors._invalidTypeProperty({ property: 'move', validTypes: [constants.entities.label, constants.entities.pushpin, constants.entities.point, constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle] });
        
        switch(this.type){
            case constants.entities.pushpin:
            case constants.entities.point:
                this.map._private._drawing._private._movePoint({ entity: this, onFinish: this._private._onMove});
                break;
            case constants.entities.polyline:
            case constants.entities.activeShape:
            case constants.entities.polygon: 
            case constants.entities.circle:
            case constants.entities.rectangle:
                this.map._private._drawing._private._move({ entity: this, onFinish: this._private._onMove});
                break;
        }        
    }

    reshape(){
        this._private._errors._invalidTypeProperty({ property: 'reshape', validTypes: [constants.entities.polyline, constants.entities.activeShape, constants.entities.selectedShape, constants.entities.polygon, constants.entities.circle, constants.entities.rectangle] });
        this.map._private._drawing._private._reshape({
            entity: this,
            onFinish: this._private._onReshape
        });
    }
};