// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { Wizard } from '../../../base/wizard/wizard';
import { DataSelection } from './dataSelection';
import { OptimizationCriteria } from './optimizationCriteria';
import { SelectionCriteria } from './selectionCriteria';
import { translate } from "../../../../utils/translation";
import { helpers } from '../../../../utils/helpers';
import { constants } from '../../../../utils/constants';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { map } from "../../map/map";

const _ = require("lodash");

var standardGeographyLayer = null;

export var marketOptimization = {
    update: () =>{}
};

export function MarketOptimization({visible, model, onClose}) {

    const [loaded, setLoaded] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [seedPointsFiles, setSeedPointsFiles] = useState([]);
    const [seedPointsFile, setSeedPointsFile] = useState(null);
    const [geographyMode, setGeographyMode] = useState(constants.marketOptimization.geographyModes.standard);
    const [standardGeographies, setStandardGeographies] = useState([]);
    const [standardGeography, setStandardGeography] = useState(null);
    const [drawnGeographies, setDrawnGeographies] = useState([]);
    const [drawnGeography, setDrawnGeography] = useState(null);
    const [protectionBuffer, setProtectionBuffer] = useState(null);
    const [cannibalizationThreshold, setCannibalizationThreshold] = useState(null);
    const [salesThreshold, setSalesThreshold] = useState(null);
    const [optimizationCriteriaOriginals, setOptimizationCriteriaOriginals] = useState(null);
    const [modelSettings, setModelSettings] = useState(null);
    const [continueDisabled, setContinueDisabled] = useState(true);
    const [goToStep, setGoToStep] = useState(-1);
    const [seedPointCountExceeded, setSeedPointCountExceeded] = useState(false);

    marketOptimization = {
        update: () =>{
            var shapes = [];

            map.layers.find(layer => layer.type === constants.layers.types.cosmetic).entities.filter(
                x => x.type === constants.entities.circle || 
                x.type === constants.entities.polygon || 
                x.type === constants.entities.rectangle ||
                x.type === constants.entities.standardGeography
            ).forEach(entity => {
                shapes.push({
                    id: entity.id,
                    group: entity.layer.text,
                    Name: entity.text,
                    Type: constants.tradeAreas.types.userDrawn,
                    CenterLat: entity.location.lat,
                    CenterLon: entity.location.lon,
                    EncodedPoints: helpers.encodedLocations(entity.paths),
                    GeographyIds: [],
                    GeographyVintageId: -1,
                    Interval: 0,
                    LengthMeasurement: constants.lengthMeasurements.miles,
                    PointFormat: constants.demographicShape.encodedString
                });
            });

            setDrawnGeographies([...shapes]);            
        }
    };

    const getStandardGeographies = useCallback( 
        async () =>{

            if (standardGeographyLayer == null || standardGeographyLayer?.metaData?.seedPointsFiles.length === 0 || standardGeographyLayer?.metaData?.seedPointsFile == null) {
                setStandardGeographies([]);
                return;
            }

            const seedFile = standardGeographyLayer.metaData.seedPointsFiles.find(file => file.id === standardGeographyLayer.metaData.seedPointsFile);
            if (!_.isObject(seedFile))
                return;

            var geographies = await legacyEndpoints.service({
                name: 'GetGeographyVintageShapes',
                parameters: {
                    vintageId: seedFile.geoVintage.id,
                    geoLevelId: seedFile.geoLevel.id,
                    currentView: {
                        polygonString: helpers.encodedLocations(helpers.createRectangle({ topLeft: map.bounds.northEast, bottomRight: map.bounds.southWest})),
                        polygonType: constants.encodedString.google
                    }
                }
            });

            // add the currently selected standard geography to the list if it's no longer in the current map view
            if (standardGeographyLayer.metaData.standardGeography != null) {
                var geo = geographies.shapes.find(x => x.id === standardGeographyLayer.metaData.standardGeography);
                if (!_.isObject(geo)) {
                    geo = standardGeographyLayer.metaData.standardGeographies.find(x => x.id === standardGeographyLayer.metaData.standardGeography);
                    if (_.isObject(geo)) {
                        geographies.shapes.push(geo);
                    }
                }
            }

            const geos = _.sortBy(geographies.shapes.map(geo => { return { ...geo, text: geo.polygon.name }; }), "text");
            standardGeographyLayer.metaData.standardGeographies = geos;
            setStandardGeographies(geos);

            // disable unnecessary dependencies warning - we need this fct to be called when seedPointsFiles and seedPointsFile change
            // we'll get their current values from the layer's metadata (state variables don't get updated properly when called from the map listener)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [seedPointsFiles, seedPointsFile]
    );

    useEffect(()=>{
        getStandardGeographies();
    }, [getStandardGeographies]);

    const getModelSettings = useCallback(
        async () =>{
            
            if (!visible || model == null || seedPointsFiles.length > 0)
                return;

            setLoaded(false);      

            // Standard Geography Layer
            standardGeographyLayer = map.addLayer({ group: constants.layers.groups.other, type: constants.layers.types.other });

            // these state variables don't have updated values within getStandardGeographies when called from the 
            // map view changed listener, so store them in the layer's metadata
            standardGeographyLayer.metaData.seedPointsFile = null;
            standardGeographyLayer.metaData.seedPointsFiles = [];
            standardGeographyLayer.metaData.standardGeography = null;
            standardGeographyLayer.metaData.standardGeographies = [];

            map.addListener({
                id: standardGeographyLayer.id,
                type: constants.listeners.viewChange,
                action: () => { getStandardGeographies(); }
            });

            var settings = await legacyEndpoints.service({
                name: 'GetMarketOptimizationModelSettings',
                parameters: {
                    id: model.id,                
                }
            });

            // Seed Files
            var seeds = [];

            seeds.push({
                id: helpers.newGuid(),
                text: 'Seeds File DMA',
                geoVintage: {
                    id: 15,
                    name: '2010 Census Q1 2015'
                },
                geoLevel: {
                    id: '56d4d339-f2cc-46cc-8bcd-7b0e4878cd50',
                    name: 'Designated Market Area'
                }
            });

            seeds.push({
                id: helpers.newGuid(),
                text: 'Seeds File MSA',
                geoVintage: {
                    id: 15,
                    name: '2010 Census Q1 2015'
                },
                geoLevel: {
                    id: '0b891899-9692-4b71-b88d-bc49e6b6f571',
                    name: 'Metropolitan Statistical Area'
                }
            });

            seeds.push({
                id: helpers.newGuid(),
                text: 'Seeds File Block Group',
                geoVintage: {
                    id: 15,
                    name: '2010 Census Q1 2015'
                },
                geoLevel: {
                    id: '4d154b98-9077-4789-8e68-57e44e8fc787',
                    name: 'Block Group'
                }
            });

            setSeedPointsFiles([...seeds]);

            // Optimization Criteria
            var optCriteria = { 
                protectionBuffer: { 
                    measurements: [ { key: constants.lengthMeasurements.miles, abbreviation: 'mi' }, { key: constants.lengthMeasurements.kilometers, abbreviation: 'km' } ],
                    rural: 0, 
                    ruralMeasurement: constants.lengthMeasurements.miles,
                    suburban: 0, 
                    suburbanMeasurement: constants.lengthMeasurements.miles,
                    urban: 0,
                    urbanMeasurement: constants.lengthMeasurements.miles
                }, 
                cannibalizationThreshold: 0, 
                salesThreshold: { 
                    rural: 0, 
                    suburban: 0, 
                    urban: 0 
                } 
            };

            optCriteria.cannibalizationThreshold = settings.cannibalizationThreshold ? settings.cannibalizationThreshold : 0;

            if (_.isArray(settings.densityClasses)) {
                settings.densityClasses.forEach(density => {
                    if (density.densityClassRuleDefinitionId === constants.marketOptimization.densityClasses.rural) {
                        optCriteria.protectionBuffer.rural = density.protectionBuffer;
                        optCriteria.protectionBuffer.ruralMeasurement = density.protectionBufferLengthMeasurement;
                        optCriteria.salesThreshold.rural = density.salesThreshold;
                    }
                    else if (density.densityClassRuleDefinitionId === constants.marketOptimization.densityClasses.suburban) {
                        optCriteria.protectionBuffer.suburban = density.protectionBuffer;
                        optCriteria.protectionBuffer.suburbanMeasurement = density.protectionBufferLengthMeasurement;
                        optCriteria.salesThreshold.suburban = density.salesThreshold;
                    }
                    else if (density.densityClassRuleDefinitionId === constants.marketOptimization.densityClasses.urban) {
                        optCriteria.protectionBuffer.urban = density.protectionBuffer;
                        optCriteria.protectionBuffer.urbanMeasurement = density.protectionBufferLengthMeasurement;
                        optCriteria.salesThreshold.urban = density.salesThreshold;
                    }
                });
            }

            setOptimizationCriteriaOriginals(optCriteria);
            setProtectionBuffer(_.cloneDeep(optCriteria.protectionBuffer));
            setCannibalizationThreshold(optCriteria.cannibalizationThreshold);
            setSalesThreshold(_.cloneDeep(optCriteria.salesThreshold));
            setModelSettings(settings);

            // Hand-Drawn Geographies
            marketOptimization.update();

            setGoToStep(-1);
            setLoaded(true);
        },
        [visible, model, seedPointsFiles, getStandardGeographies]
    );

    useEffect(() =>{
        getModelSettings();
    }, [getModelSettings]);

    useEffect(()=>{

        if (seedPointsFiles.length === 0) {
            if (standardGeographyLayer != null) {
                standardGeographyLayer.metaData.seedPointsFile = null;
                standardGeographyLayer.metaData.seedPointsFiles = [];
                standardGeographyLayer.metaData.standardGeography = null;
                standardGeographyLayer.metaData.standardGeographies = [];
            }

            setSeedPointsFile(null);

            return;
        }
                
        standardGeographyLayer.metaData.seedPointsFiles = seedPointsFiles;
        standardGeographyLayer.metaData.seedPointsFile = seedPointsFiles[0].id;
        standardGeographyLayer.metaData.standardGeography = null;
        standardGeographyLayer.metaData.standardGeographies = [];
        setSeedPointsFile(seedPointsFiles[0].id);        

    }, [seedPointsFiles]);

    const countSeedPoints = async () =>{
    
        if (drawnGeography == null)
            return;            

        setLoaded(false);

        const shape = drawnGeographies.find(x => x.id === drawnGeography);
        if (!_.isObject(shape))
            return;

        // TBD: call seed point count service and compare results to seed file threshold
        // (for testing - fake this with a call to quick reports service and check the first value)
        const results = await legacyEndpoints.service({
            name: 'GenerateChartReportTableForShape',
            parameters: {
                aChartID: '10420',
                aShape: shape,
                aZoom: map.zoom
            }
        });

        if (_.isArray(results.Values) && results.Values.length > 0) {
            const val = parseFloat(results.Values[0]);
            console.log('seed point count:' + val);
            setSeedPointCountExceeded(val > 25);
        }

        setLoaded(true);
    };

    const viewDrawnGeography = useCallback(
        () =>{
               
            if (drawnGeography == null)
                return;            
    
            const entities = map.layers.find(layer => layer.type === constants.layers.types.cosmetic).entities.filter(
                x => x.type === constants.entities.circle || 
                x.type === constants.entities.polygon || 
                x.type === constants.entities.rectangle ||
                x.type === constants.entities.standardGeography
            );
    
            if (entities.length === 0)
                return; 

            const entity = entities.find(x => x.id === drawnGeography);
    
            if (_.isObject(entity)) {
                map.fitToView([entity]);
                countSeedPoints();
            }

        }, [drawnGeography]
    );

    useEffect(()=>{
        viewDrawnGeography();
    }, [viewDrawnGeography]);
    
    const createEntity = (o) =>{

        if (standardGeographyLayer == null)
            return;

        o.id = _.isString(o.id) ? o.id : helpers.newGuid();

        const fillColor = o.fillColor ? o.fillColor : constants.polygons.defaults.fillColor;
        const strokeColor = o.strokeColor ? o.strokeColor : constants.polygons.defaults.strokeColor;
        const strokeWidth = o.strokeWidth ? o.strokeWidth : constants.polygons.defaults.strokeWidth;

        return standardGeographyLayer.addEntity({
            id: o.id,
            text: o.name,
            type: o.type,
            paths: helpers.parseWkt(o.wkt),
            fillColor: { r: fillColor.r, g: fillColor.g, b: fillColor.b, a: fillColor.a },
            strokeColor: { r: strokeColor.r, g: strokeColor.g, b: strokeColor.b, a: strokeColor.a },
            strokeWidth: strokeWidth,
            metaData: { 
                geoLevelId: o.geoLevelId, 
                geographyIds: o.geographyIds, 
                vintageId: o.vintageId,
                wkt: o.wkt
            }
        });
    };

    const mapStandardGeography = useCallback( 
        () =>{

            if (standardGeographyLayer != null)
                standardGeographyLayer.clear();

            if (standardGeographies.length === 0 || standardGeography == null || seedPointsFiles.length === 0 || seedPointsFile == null)
                return;

            const seedFile = seedPointsFiles.find(file => file.id === seedPointsFile);
            if (!_.isObject(seedFile))
                return;

            const geo = standardGeographies.find(x => x.id === standardGeography);
            if (!_.isObject(geo) || !_.isObject(geo.polygon))
                return;

            var shape = {
                id: geo.id,
                name: geo.polygon.name,
                description: geo.polygon.name,
                type: constants.entities.standardGeography,
                geoLevelId: seedFile.geoLevel.id,
                vintageId: seedFile.geoVintage.id,
                geographyIds: [standardGeography],
                wkt: geo.polygon.polygonString,
                fillColor: geo.fillColor,
                strokeColor: geo.strokeColor,
                strokeWidth: geo.strokeWidth
            };

            const entity = createEntity(shape);
            map.fitToView([entity]);

            // disable missing dependency warning - we don't need this fct to be called when standardGeographies changes
            // we just need it to run when a standardGeography is selected (which will change the map view and cause standardGeographies to be updated)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [standardGeography, seedPointsFiles, seedPointsFile]
    );

    useEffect(()=>{
        mapStandardGeography();
    }, [mapStandardGeography]);

    const clearDataSelection = () =>{
        if (seedPointsFiles.length > 0)
            setSeedPointsFile(seedPointsFiles[0].id);
    
        setStandardGeography(null); 
        setDrawnGeography(null);
        setGeographyMode(constants.marketOptimization.geographyModes.standard);
        setSeedPointCountExceeded(false);
    };

    const resetOptimizationCriteria = () =>{
        setProtectionBuffer(_.cloneDeep(optimizationCriteriaOriginals.protectionBuffer));
        setCannibalizationThreshold(optimizationCriteriaOriginals.cannibalizationThreshold);
        setSalesThreshold(_.cloneDeep(optimizationCriteriaOriginals.salesThreshold));
    };

    const generateReport = async () =>{
        setGenerating(true);

        var generateModel = _.cloneDeep(model);
        generateModel.densityClassRuleId = modelSettings.densityClassRuleId;
        generateModel.densityClasses = _.cloneDeep(modelSettings.densityClasses);

        generateModel.cannibalizationThreshold = cannibalizationThreshold;

        if (_.isArray(generateModel.densityClasses)) {
            generateModel.densityClasses.forEach(density => {
                if (density.densityClassRuleDefinitionId === constants.marketOptimization.densityClasses.rural) {
                    density.protectionBuffer = protectionBuffer.rural;
                    density.protectionBufferLengthMeasurement = protectionBuffer.ruralMeasurement;
                    density.salesThreshold = salesThreshold.rural;
                }
                else if (density.densityClassRuleDefinitionId === constants.marketOptimization.densityClasses.suburban) {
                    density.protectionBuffer = protectionBuffer.suburban;
                    density.protectionBufferLengthMeasurement = protectionBuffer.suburbanMeasurement;
                    density.salesThreshold = salesThreshold.suburban;
                }
                else if (density.densityClassRuleDefinitionId === constants.marketOptimization.densityClasses.urban) {
                    density.protectionBuffer = protectionBuffer.urban;
                    density.protectionBufferLengthMeasurement = protectionBuffer.urbanMeasurement;
                    density.salesThreshold = salesThreshold.urban;
                }
            });
        }

        var results = await legacyEndpoints.service({
            name: 'GenerateMarketOptimizationModelResults',
            parameters: {
                model: generateModel
            }
        });

        setGenerating(false);
    };

    const nextStep = (o) =>{
        if (o.step == 3)
            generateReport();
    };

    const close = () =>{

        if (standardGeographyLayer != null) {
            map.removeListener(standardGeographyLayer.id);
            standardGeographyLayer.dispose();
        }

        setSeedPointsFile(null);
        setSeedPointsFiles([]);
        setStandardGeography(null);
        setStandardGeographies([]);
        setDrawnGeography(null);
        setDrawnGeographies([]);
        setGeographyMode(constants.marketOptimization.geographyModes.standard);
        setSeedPointCountExceeded(false);
        setGoToStep(0);

        onClose();
    };

    var steps = [
        { title: model?.name, loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <DataSelection seedPointsFiles={seedPointsFiles} seedPointsFile={seedPointsFile} updateSeedPointsFile={(value) =>{ 
                    standardGeographyLayer.metaData.seedPointsFile = value; 
                    standardGeographyLayer.metaData.standardGeography = null;
                    standardGeographyLayer.metaData.standardGeographies = [];
                    setSeedPointsFile(value); 
                    setStandardGeography(null); 
                }} 
                standardGeographies={standardGeographies} standardGeography={standardGeography} updateStandardGeography={(value) =>{ 
                    standardGeographyLayer.metaData.standardGeography = value;
                    setStandardGeography(value); 
                }} 
                viewStandardGeography={() =>{ mapStandardGeography(); }}
                drawnGeographies={drawnGeographies} drawnGeography={drawnGeography} updateDrawnGeography={(value) =>{ setDrawnGeography(value); }} viewDrawnGeography={() =>{ viewDrawnGeography(); }}
                geographyMode={geographyMode} updateGeographyMode={(value) =>{ setGeographyMode(value); }} seedPointCountExceeded={seedPointCountExceeded} 
                clearDataSelection={() =>{ clearDataSelection(); }} onReadyNext={(o) =>{ setContinueDisabled(!o); }}
            />
        },
        { title: model?.name, loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <OptimizationCriteria protectionBuffer={protectionBuffer} updateProtectionBuffer={(value) =>{ setProtectionBuffer(value); }}
                cannibalizationThreshold={cannibalizationThreshold} updateCannibalizationThreshold={(value) =>{ setCannibalizationThreshold(value); }}  
                salesThreshold={salesThreshold} updateSalesThreshold={(value) =>{ setSalesThreshold(value); }}
                resetOptimizationCriteria={() =>{ resetOptimizationCriteria(); }} 
                onReadyNext={(o) => { 
                    setContinueDisabled(!o);
                }}
            />
        },
        { title: model?.name, loaded: loaded, generating: generating, continueDisabled: continueDisabled, children: 
            <SelectionCriteria />
        }
	];

    return <>
        <Wizard steps={steps} size='small' className={'app-panel-right'} goToStep={goToStep} completeText={translate('generate')} onClose={() =>{ close(); }} onNextStep={(o) =>{ nextStep(o); }}/>
    </>
}