// React imports
import { useState, useEffect, useRef } from 'react'

// App imports
import { Popover } from "../../../base/popover/popover";
import { Button } from "../../../base/button/button";
import { TextBox } from "../../../base/textBox/textBox";
import { icons } from "../../../base/icon/icon";
import { translate } from "../../../../utils/translation";
import { tradeAreas as tradeAreaModule } from "../../../../modules/tradeAreas";
import { errorToast } from "../../../base/toast/toast";

export function EditLayerName({ parentId, layerName, onLayerRenamed }) {

    const ref = useRef(null);

    const [newLayerName, setNewLayerName] = useState('');

    useEffect(()=>{
        setNewLayerName(layerName);
    },[layerName]);

    return <>
        <Popover 
            ref={ref}
            parentId={`#${parentId}`}
            showTitle={false}
            height={80}
            width={350}
        >
            <div className="app-legend-layer-edit-container">
                <div className="app-legend-layer-edit-row">
                    <TextBox 
                        className='app-legend-layer-edit-input'
                        value={newLayerName}
                        valueChangeEvent={'change keyup'}
                        onChange={(o) => { 
                            setNewLayerName(o.value);
                        }}
                    />
                    <Button className='app-selectable-items-close-button' theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} 
                        onClick={() => { 
                            setNewLayerName(layerName);
                            ref.current.instance.hide(); 
                        }} 
                    />
                    <Button theme='primary' size='small' icon={icons.check} tooltip={translate('save')}
                        onClick={()=>{
                            if (!tradeAreaModule.isUniqueLibraryName({ name: newLayerName }) && newLayerName !== layerName)
                                errorToast(translate('trade_area_library_unique_name'));
                            else {
                                if (onLayerRenamed)
                                    onLayerRenamed({ value: newLayerName });

                                ref.current.instance.hide(); 
                            }
                        }} 
                    />
                </div>
            </div>         
        </Popover>
    </>;
}