// Third party imports
import moment from 'moment';

// App imports
import { legacyEndpoints } from "../services/legacyEndpoints";
import { constants } from './constants';
import { translate } from './translation';
import { Icon, icons } from '../components/base/icon/icon';

const _ = require("lodash");

export const helpers = {

    isViewer: () =>{
        return legacyEndpoints.applicationId === 18;
    },
    formatDate: (o) => {
        return moment(o.date).format(o.format === undefined ? "LLL" : o.format);
    },
    convertJSONDate: (jsonDate) => {
        if (jsonDate.getDate == null)
            return new Date(parseInt(jsonDate.substr(6)));
        else
            return jsonDate;
    },

    getDate: (format) => {
        return moment(new Date()).format(format);
    },    

    isDateBefore: (date1, date2) => {
        console.log(date1);
        console.log(date2);
        return moment(date1) < moment(date2);;
    }, 

    formatNumber: function (number) {
        return new Intl.NumberFormat().format(number);
    },

    randomColor: function (o) {
        o = _.isObject(o) ? o : {};

        var rint = Math.round(0xffffff * Math.random());
        return { r: _.isNumber(o.r) ? o.r : (rint >> 16), g: _.isNumber(o.g) ? o.g : (rint >> 8 & 255), b: _.isNumber(o.b) ? o.b : (rint & 255), a: _.isNumber(o.a) ? o.a : 1 };
    },
    getRequiredFieldIndicator: () => {
        return <span className='required'><Icon className={'app-form-required'} icon={icons.asterisk}/></span>;
    },
    getDimensionsFromSymbolUrl: (url) =>{

        var symbolSplit = url.split('_');
        
        if (symbolSplit.length > 0)
            symbolSplit = symbolSplit[symbolSplit.length - 1].split('x');
        
        if (symbolSplit.length === 2)
            return {
                width: parseInt(symbolSplit[0]),
                height: parseInt(symbolSplit[1])
            };

        return {
            width: 0,
            height: 0
        };
    },
    getDataFromSymbolUrl: (url) => {
        var type = 0;
        var symbolName = "";
        if (url.indexOf("IsCustomImage=true") > 0){
            var startIndex = url.indexOf("Id=")+3;
            symbolName = url.slice(startIndex,startIndex+36);
            type = 1;
        }
        else {
            symbolName = url.split('Symbol=')[1].replaceAll('%252f','/');
            type = 0;
        }

        return { name: symbolName, type: type }; 
    },
    createDefaultMapLabel: () => {
        return {
            position: constants.labelPosition.topMiddle,
            style: {
                color: { r: 255, g: 255, b: 255, a: 1 },
                background: { r: 0, g: 0, b: 0, a: 1 },
                fontSize: 11,
                bold: true,
                italic: false,
                underline: false,
                textAlign: constants.textAlignment.center,
            }
        };
    },
    // label
    // text
    // parentWidth
    // parentHeight
    createSvgLabel: (o) =>{

        o.label = _.isObject(o.label) ? o.label : helpers.createDefaultMapLabel();

		// General style
		var padding = _.isNumber(o.padding) ? o.padding : 7;
        var lineSpaceOffset = _.isNumber(o.lineSpacingOffset) ? o.lineSpacingOffset : 0;

		// Text style
		var textFill = _.isObject(o.label.style.color) ? `rgba(${o.label.style.color.r},${o.label.style.color.g},${o.label.style.color.b},${o.label.style.color.a})` : 'transparent';
		var textSize = `${o.label.style.fontSize}pt`;
		var textWeight = o.label.style.bold ? 'bold' : 'normal';
		var textStyle = o.label.style.italic ? 'italic' : 'normal';
		var textDecoration = o.label.style.underline ? 'underline' : 'none';		
		var textFont = 'sans-serif';

		// Text dimensions
		var textReplacementValue = crypto.randomUUID();
		var text = o.text.replace(/(\r\n|\n|\r)/g, textReplacementValue).replace('&', '&amp;').replace('<', '&lt;').replace('>', '&gt;');
		var textSplit = text.split(textReplacementValue);
		var textLines = textSplit.length > 1 ? textSplit.length : 1;
		var textDimensions = helpers.getTextDimensions(o.text, `${textStyle} ${textWeight} ${textSize} ${textFont}`);
        
		// Text height
		var textHeight = (textLines * textDimensions.height) + padding + (lineSpaceOffset * textLines);

		// Text width
		var textWidth = 0;
		if (textSplit.length === 1)
			textWidth = textDimensions.width;
		else
			textSplit.forEach((line) => {
                line = line.trim();

                if (line.length === 0)
                    return;

				var lineDimensions = helpers.getTextDimensions(line, `${textStyle} ${textWeight} ${textSize} ${textFont}`);
                if (lineDimensions.width > textWidth)
					textWidth = lineDimensions.width;
			});

		textWidth = textWidth + padding * 2;

		// Text position        
		var textX = 0;
		var textY = textHeight / 2;
        var textAnchor = 'middle';
		switch (o.label.style.textAlign){
			case constants.textAlignment.left:
				textX = 0;
				textAnchor = 'start';
				break;
            default:
			case constants.textAlignment.center:
				textX = parseInt(textWidth / 2);
				textAnchor = 'middle';
				break;
			case constants.textAlignment.right:
				textX = textWidth;
				textAnchor = 'end';
				break;
		}

		// Text content
		text = textSplit.length === 1 ?
			encodeURIComponent(text) :
			textSplit.map((line, i) => {return `<tspan x='${textX}' y='${textDimensions.height * (i + 1)}'>${encodeURIComponent(line)}</tspan>`; }).join('');

		// Label style
		var labelRadius = 3;
		var labelAnchor = { x: 0, y: 0 };
		var labelFill = _.isObject(o.label.style.background) ? `rgba(${o.label.style.background.r},${o.label.style.background.g},${o.label.style.background.b},${o.label.style.background.a})` : 'transparent';
		var labelAnchorTopY = textHeight + (o.parentHeight / 2);
		var labelAnchorMiddleY = (o.parentHeight / 4) + (textHeight / 4);
		var labelAnchorBottomY = -o.parentHeight / 2;
		var labelAnchorLeftX =	textWidth + o.parentWidth / 2;
		var labelAnchorMiddleX = textWidth / 2;
		var labelAnchorRightX = -(o.parentWidth / 2);

		// Label position
		switch (o.label.position){
			case constants.labelPosition.topLeft:
				labelAnchor = { x: labelAnchorLeftX, y: labelAnchorTopY };
				break;
			default:
			case constants.labelPosition.topMiddle:
				labelAnchor = { x: labelAnchorMiddleX, y: labelAnchorTopY };
				break;
			case constants.labelPosition.topRight:
				labelAnchor = { x: labelAnchorRightX, y: labelAnchorTopY };
				break;
			case constants.labelPosition.middleLeft:
				labelAnchor = { x: labelAnchorLeftX, y: labelAnchorMiddleY };
				break;
			case constants.labelPosition.middleMiddle:
				labelAnchor = { x: labelAnchorMiddleX, y: labelAnchorMiddleY };
				break;
			case constants.labelPosition.middleRight:
				labelAnchor = { x: labelAnchorRightX, y: labelAnchorMiddleY };
				break;
			case constants.labelPosition.bottomLeft:
				labelAnchor = { x: labelAnchorLeftX, y: labelAnchorBottomY };
				break;
			case constants.labelPosition.bottomMiddle:
				labelAnchor = { x: labelAnchorMiddleX, y: labelAnchorBottomY };
				break;
			case constants.labelPosition.bottomRight:
				labelAnchor = { x: labelAnchorRightX, y: labelAnchorBottomY };
				break;
		}

		return {
			anchor: labelAnchor,
			icon: `<svg xmlns="http://www.w3.org/2000/svg" width="${textWidth}" height="${textHeight}" fill="${labelFill}">\
				<g> \
					<rect width='${textWidth}' height='${textHeight}' rx='${labelRadius}' ry='${labelRadius}' /> \
					<text alignment-baseline="central" \
						font-family='${textFont}' \
						x="${textX}" \
						y="${textY}" \
						text-anchor="${textAnchor}" \
						text-decoration="${textDecoration}" \
						font-style="${textStyle}" \
						font-weight="${textWeight}" \
						font-size="${textSize}" \
						fill="${textFill}"> \
							${text} \
					</text> \
				</g> \
			</svg>`
		};
    },

    getTextDimensions: (txt, font) => {
        const element = document.createElement('canvas');
        const context = element.getContext("2d");
        context.font = font;        

        var rules = context.font.split(' ');
        var fontSize = parseInt(rules.find((rule) => { return rule.endsWith('px') }) ?? 12);

        return {
            width: context.measureText(txt).width,
            height: parseInt(fontSize)
        };
    },

    htmlEncode: function(str){
        var i = str.length,
            aRet = [];

        while (i--) {
        var iC = str[i].charCodeAt();
        if (iC < 65 || iC > 127 || (iC>90 && iC<97)) {
            aRet[i] = '&#'+iC+';';
        } else {
            aRet[i] = str[i];
        }
        }
        return aRet.join('');
    },

    newGuid: function () {
        return crypto.randomUUID().toString();
    },

    emptyGuid: function () {
        return "00000000-0000-0000-0000-000000000000";
    },

    degreesToRadians: function (number) {
        return (number * Math.PI) / 180;
    },

    radiansToDegrees: function (number) {
        return (number * 180) / Math.PI;
    },

    createRectangle: (o) => {
        return [
            { lat: o.topLeft.lat, lon: o.topLeft.lon },
            { lat: o.topLeft.lat, lon: o.bottomRight.lon },
            { lat: o.bottomRight.lat, lon: o.bottomRight.lon },
            { lat: o.bottomRight.lat, lon: o.topLeft.lon },
            { lat: o.topLeft.lat, lon: o.topLeft.lon }
        ];
    },

    createCircle: function (o) {

        o.location = _.clone(o.location);

        if (!_.isNumber(o.lengthMeasurement))
            o.lengthMeasurement = constants.lengthMeasurements.meters;

        o.radius = helpers.convertLength(
            o.radius,
            o.lengthMeasurement,
            constants.lengthMeasurements.meters
        );

        var locations = [];
        var angularDistance = parseFloat(o.radius) / constants.earthRadius;

        var latRadians = helpers.degreesToRadians(o.location.lat);
        var lonRadians = helpers.degreesToRadians(o.location.lon);

        for (var i = 0; i < 60; i++) {
            var brng = helpers.degreesToRadians(i * 6);
            var calucation = Math.asin(Math.sin(latRadians) * Math.cos(angularDistance) + Math.cos(latRadians) * Math.sin(angularDistance) * Math.cos(brng));

            locations.push({
                lat: helpers.radiansToDegrees(calucation),
                lon: helpers.radiansToDegrees((lonRadians + Math.atan2(Math.sin(brng) * Math.sin(angularDistance) * Math.cos(latRadians), Math.cos(angularDistance) - Math.sin(latRadians) * Math.sin(calucation))))
            });
        }

        return locations;
    },

    isOnScreen: (elment) => {

        if (!elment)
            return false;

        var top = elment.getBoundingClientRect().top;
        return top > 0 && top <= window.innerHeight;
    },

    navigateToZendeskSupport: (o) => {
        legacyEndpoints.service({
            name: 'GenerateZendeskToken',
            parameters: {
                userName: o.userName,
                userEmail: o.userEmail,
                product: o.product
            },
            success: function (token) {
                helpers.navigateToUrl(`${process.env.REACT_APP_ZENDESK_URL}/access/jwt?jwt=${token}&return_to=${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/${o.supportPage}`);
            },
            error: () => {
                helpers.navigateToUrl('https://support.tradeareasystems.net');
            }
        });
    },

    navigateToUrl: (url) => {
        window.open(url, "_blank", "noreferrer");
        /*var link = document.createElement("a");
        link.setAttribute("href", url);
        link.style.display = "none";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.remove();
        return;*/
    },
    xyzToQuadKey: (o) => {

        var quadKey = [];

        for (var i = o.z; i > 0; i--) {

            var digit = 0;

            var mask = 1 << (i - 1);

            if ((o.x & mask) !== 0)
                digit++;

            if ((o.y & mask) !== 0)
                digit += 2;

            quadKey.push(digit.toString());
        }

        return quadKey.join('');
    },

    encodeSignedNumber: (num) => {
        var sgn_num = num << 1;

        if (num < 0) {
            sgn_num = ~(sgn_num);
        }

        return (helpers.encodeNumber(sgn_num));
    },

    encodeNumber: (num) => {
        var encodeString = "";

        while (num >= 0x20) {
            encodeString += (String.fromCharCode((0x20 | (num & 0x1f)) + 63));
            num >>= 5;
        }

        encodeString += (String.fromCharCode(num + 63));
        return encodeString;
    },

    encodedLocations: (points) => {
        var i = 0;
        var plat = 0;
        var plng = 0;
        var encoded_points = "";

        for (i = 0; i < points.length; ++i) {
            var point = points[i];
            var lat = point.lat;
            var lng = point.lon;

            var late5 = parseInt(lat * 1e6);
            var lnge5 = parseInt(lng * 1e6);

            var dlat = late5 - plat;
            var dlng = lnge5 - plng;

            plat = late5;
            plng = lnge5;

            encoded_points += helpers.encodeSignedNumber(dlat) + helpers.encodeSignedNumber(dlng);
        }

        return encoded_points;
    },

    decodeLocations: (encodedString) => {
        var array = [];

        try {
            var len = encodedString.length;
            var index = 0;

            var lat = 0;
            var lon = 0;

            while (index < len) {
                var b;
                var shift = 0;
                var result = 0;

                do {
                    b = encodedString.charCodeAt(index++) - 63;
                    result |= (b & 0x1f) << shift;
                    shift += 5;
                } while (b >= 0x20);

                var dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
                lat += dlat;

                shift = 0;
                result = 0;

                do {
                    b = encodedString.charCodeAt(index++) - 63;
                    result |= (b & 0x1f) << shift;
                    shift += 5;
                } while (b >= 0x20);

                var dlon = ((result & 1) ? ~(result >> 1) : (result >> 1));
                lon += dlon;

                array.push({ lat: (lat * 1e-6), lon: (lon * 1e-6) });
            }
        }
        catch (e) {
            console.error("Could not decode encoded string:" + encodedString);
        }

        return array;
    },

    decodeLocationsToGeoJSON: (encodedString, idList) => {
        var locations = helpers.decodeLocations(encodedString);
        var geoJSON = {
            type: "FeatureCollection",
            features: []
        };

        _.each(locations, (location, index) => {
            geoJSON.features.push({
                type: "Feature",
                id: idList[index],
                geometry: {
                    type: "Point",
                    coordinates: [location.lon, location.lat]
                }
            });
        });

        return geoJSON;
    },

    hexToRgb: (hex) => {
        return ['0x' + hex[1] + hex[2] | 0, '0x' + hex[3] + hex[4] | 0, '0x' + hex[5] + hex[6] | 0];
    },

    rgbToHex: (r, g, b) => {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    },

    stringToColor: function(value, opacity) {

        var color = { r: 0, g: 0, b: 0, a: _.isNumber(opacity) ? opacity : 1};
        
        if(value.startsWith("#")){
            var hexToRgb = this.hexToRgb(value);
            color.r = hexToRgb[0];
            color.g = hexToRgb[1];
            color.b = hexToRgb[2];
            
            if (hexToRgb.length === 4)
                color.a = hexToRgb[3];
        }
        else{
            var parsedRGB = value.toLowerCase().replace('rgba(', '').replace('rgb(', '').replace(')').split(',');
            color.r = parseInt(parsedRGB[0]);
            color.g = parseInt(parsedRGB[1]);
            color.b = parseInt(parsedRGB[2]);

            if (parsedRGB.length === 4)
                color.a = parseFloat(parsedRGB[3]);
        }

        return color;
    },

    convertArea: function (area, currentMeasurement, desiredMeasurement) {

        switch (currentMeasurement) {
            case constants.areaMeasurements.squareFeet:
                switch (desiredMeasurement) {

                    case constants.areaMeasurements.acres:
                        return area / 43560;

                    case constants.areaMeasurements.squareMiles:
                        return area * .000000035870064;

                    case constants.areaMeasurements.squareMeters:
                        return area / 10.7639104;

                    case constants.areaMeasurements.squareKilometers:
                        return area * .00000009290304;
                }
                break;
            case constants.areaMeasurements.squareMiles:
                switch (desiredMeasurement) {

                    case constants.areaMeasurements.acres:
                        return area * 640;

                    case constants.areaMeasurements.squareFeet:
                        return area * 27878400;

                    case constants.areaMeasurements.squareMeters:
                        return area * 2589988;

                    case constants.areaMeasurements.squareKilometers:
                        return area * 2.589988;
                }
                break;
            case constants.areaMeasurements.squareMeters:
                switch (desiredMeasurement) {

                    case constants.areaMeasurements.acres:
                        return area * .000247;

                    case constants.areaMeasurements.squareFeet:
                        return area * 10.76391;

                    case constants.areaMeasurements.squareMiles:
                        return area * 0.000000386102159;

                    case constants.areaMeasurements.squareKilometers:
                        return area * 0.000001;
                }
                break;
            case constants.areaMeasurements.squareKilometers:
                switch (desiredMeasurement) {

                    case constants.areaMeasurements.acres:
                        return area * 247.1054;

                    case constants.areaMeasurements.squareFeet:
                        return area * 10763910;

                    case constants.areaMeasurements.squareMiles:
                        return area * .386102159;

                    case constants.areaMeasurements.squareMeters:
                        return area * 1000000;
                }
                break;
        }

        return area;
    },

    convertLength: function (length, currentMeasurement, desiredMeasurement) {

        switch (currentMeasurement) {
            case constants.lengthMeasurements.feet:
                switch (desiredMeasurement) {

                    case constants.lengthMeasurements.miles:
                        return length / 5280;

                    case constants.lengthMeasurements.meters:
                        return length * 0.3048;

                    case constants.lengthMeasurements.kilometers:
                        return length * 0.0003048;
                    
                }
                break;
            case constants.lengthMeasurements.miles:
                switch (desiredMeasurement) {

                    case constants.lengthMeasurements.feet:
                        return length * 5280;

                    case constants.lengthMeasurements.meters:
                        return length * 1609.34;

                    case constants.lengthMeasurements.kilometers:
                        return length * 1.60934;
                }
                break;
            case constants.lengthMeasurements.meters:
                switch (desiredMeasurement) {
                    case constants.lengthMeasurements.feet:
                        return length * 3.28;

                    case constants.lengthMeasurements.miles:
                        return length / 1609.34;

                    case constants.lengthMeasurements.kilometers:
                        return length / 1000;
                }
                break;
            case constants.lengthMeasurements.kilometers:
                switch (desiredMeasurement) {

                    case constants.lengthMeasurements.feet:
                        return length * 3280.84;

                    case constants.lengthMeasurements.miles:
                        return length / 1.60934;

                    case constants.lengthMeasurements.meters:
                        return length * 1000;
                }
                break;
        }

        return length;
    },
    getMeasurementLabel: function (measurement) {
        switch(measurement) {
            case constants.lengthMeasurements.feet:
                return translate("feet");
            case constants.lengthMeasurements.miles:
                return translate("miles");
            case constants.lengthMeasurements.meters:
                return translate("meters");
            case constants.lengthMeasurements.kilometers:
                return translate("kilometers");
        }
    },
    getNorthernLocation: function(locations)
    {
        var clonedLocations =  _.cloneDeep(locations);
        var currentLocation = clonedLocations[0];

        if (_.isArray(currentLocation))
        {
            clonedLocations = _.flatten(clonedLocations);
            currentLocation = clonedLocations[0];
        }

        clonedLocations.forEach((location) => {
            if (location.lat > currentLocation.lat)
                currentLocation = location;
        });

        return currentLocation;
    },

    getMidpoint: function (startLocation, endLocation) {
        var dLonRadians = this.degreesToRadians(endLocation.lon - startLocation.lon);

        var startLatRadians = this.degreesToRadians(startLocation.lat);
        var startLonRadians = this.degreesToRadians(startLocation.lon);
        var endLatRadians = this.degreesToRadians(endLocation.lat);

        var Bx = Math.cos(endLatRadians) * Math.cos(dLonRadians);
        var By = Math.cos(endLatRadians) * Math.sin(dLonRadians);

        return {
            id: helpers.newGuid(),
            lat: this.radiansToDegrees(Math.atan2(Math.sin(startLatRadians) + Math.sin(endLatRadians), Math.sqrt((Math.cos(startLatRadians) + Bx) * (Math.cos(startLatRadians) + Bx) + By * By))),
            lon: this.radiansToDegrees(startLonRadians + Math.atan2(By, Math.cos(startLatRadians) + Bx))
        };
    },

    wait: ({ sleep, maxWait, stop }) => {
        return new Promise((success) => {
            
            console.log(); // Do not remove

            var currentWait = 0;
            sleep = _.isNumber(sleep) ? sleep : 1000;
            maxWait = _.isNumber(maxWait) ? maxWait : null;           

            setTimeout(() => {
                if (stop() || currentWait >= maxWait)
                    success();
                else
                    helpers.wait({ sleep: sleep, maxWait: maxWait, stop: stop });

                currentWait += 1000;

            }, sleep);
        });        
    },

    escapeRegex: (s) => {
        return s.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    },

    convertFileSize: (s) => {
        if (s < 1024)
            return s + " B";
        else if (s < 1024 ** 2)
            return (s / 1024).toFixed(2) + " KB";
        else if (s < 1024 ** 3)
            return (s / 1024 ** 2).toFixed(2) + " MB";
        else
            return (s / 1024 ** 3).toFixed + " GB";
    },

    getFilterOperator: (operator) => {
        switch (operator) {
            default:
            case "contains":
                return constants.operators.contains;
            case "notcontains":
                return constants.operators.notContains;
            case "startswith":
                return constants.operators.startsWith;
            case "endswith":
                return constants.operators.endsWith;
            case "between":
                return constants.operators.between;
            case "=":
                return constants.operators.equals;
            case "<>":
                return constants.operators.notEquals;
            case "<":
                return constants.operators.lessThan;
            case ">":
                return constants.operators.greaterThan;
            case "<=":
                return constants.operators.lessThanEquals;
            case ">=":
                return constants.operators.greaterThanEquals;
            case "isblank":
                return constants.operators.isEmpty;
            case "isnotblank":
                return constants.operators.isNotEmpty;
            case "anyof":
                return constants.operators.isAnyOf;
            case "noneof":
                return constants.operators.isNoneOf;
        }
    },

    getFilterExpression: (expression) => {
        switch (expression)
        {
            case "and":
                return constants.expressions.and;
            case "or":
                return constants.expressions.or;
            default:
                return constants.expressions.none;
        }
    },
    
    getFilterBuilder: (o) => {

        o.useColumnIndex = _.isBoolean(o.useColumnIndex) && o.useColumnIndex;
        o.removePrefix = _.isString(o.removePrefix) ? o.removePrefix : '';

        if (_.isArray(o.filter) && o.filter.length > 1 && o.filter[0] === "!") {

            var copiedFilter = _.cloneDeep(o.filter[1]);

            for (var n = 0; n < copiedFilter.length; n++) {
                if (_.isArray(copiedFilter[n]))
                    copiedFilter[n][1] = "<>";
                else if (copiedFilter[n] === "or")
                    copiedFilter[n] = "and";
                else if (copiedFilter[1] === "=")
                    copiedFilter[1] = "<>";
            }

            return helpers.getFilterBuilder({ filter: copiedFilter, useColumnIndex: o.useColumnIndex });
        }

        var expression = _.isNumber(o.expression) ? o.expression : constants.expressions.none;
        var filterBuilders = [];

        if (!_.isArray(o.filter))
            return filterBuilders;

        var filterBuilder = {
            expression: constants.expressions.none,
            filters: []
        };

        filterBuilders.push(filterBuilder);

        if (_.isArray(o.filter[0])) {
            for (var i = 0; i < o.filter.length; i++) {
                var filter = o.filter[i];
                var isNestedArray = _.filter(filter, function (a) { return _.isArray(a); }).length > 0;

                if (isNestedArray) {
                    var builders = helpers.getFilterBuilder({ filter: filter, expression: expression, useColumnIndex: o.useColumnIndex });
                    _.each(builders, function(builder) {
                        builder.expression = constants.expressions.and;
                    });

                    filterBuilders = filterBuilders.concat(builders);
                }
                else {
                    if (_.isArray(filter)) {
                        let filterObject = {
                            expression: filterBuilder.filters.length > 0 ? expression : constants.expressions.none,
                            field: (o.useColumnIndex ? filter.columnIndex : filter[0]).toString().replace(o.removePrefix, ''),
                            operator: helpers.getFilterOperator(filter[1]),
                            filterBuilders: []
                        };
                        let value = filter[2];

                        if (_.isArray(value)) {
                            if (value.length === 2)
                            {
                                filterObject.value = value[0];
                                filterObject.maxValue = value[1];
                            }
                            else if (value.length === 1)
                            {
                                filterObject.value = value[0];
                            }
                        }

                        filterBuilder.filters.push(filterObject);
                    }
                    else {
                        expression = helpers.getFilterExpression(filter);
                    }
                }
            }
        }
        else {
            let filterObject = {
                expression: constants.expressions.none,
                field: (o.useColumnIndex ? o.filter.columnIndex : o.filter[0]).toString().replace(o.removePrefix, ''),
                operator: helpers.getFilterOperator(o.filter[1]),
                value: o.filter[2],
                filterBuilders: []
            };
            let value = o.filter[2];

            if (_.isArray(value)) {
                if (value.length === 2)
                {
                    filterObject.value = value[0];
                    filterObject.maxValue = value[1];
                }
                else if (value.length === 1)
                {
                    filterObject.value = value[0];
                }
            }

            filterBuilder.filters.push(filterObject);
        }

        return _.reject(filterBuilders, function(f) { return f.filters.length === 0; });
    },

    toTitleCase: (str) => {
        return str.replace(/\w\S*/g, function(txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    },    

    blobToBase64: function (blob) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();

            reader.onloadend = function () {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });
    },

    formatFileSize: function (bytes) {
        if (typeof bytes !== 'number')
        {
            return '';
        }

        if (bytes >= 1000000000)
        {
            return (bytes / 1000000000).toFixed(2) + ' GB';
        }

        if (bytes >= 1000000)
        {
            return (bytes / 1000000).toFixed(2) + ' MB';
        }

        return (bytes / 1000).toFixed(2) + ' KB';        
    },

     parseWkt: function(wkt) {
        var i, j, lat, lng, tmp, tmpArr,
            arr = [],
            //match '(' and ')' plus contents between them which contain anything other than '(' or ')'
            m = wkt.match(/\([^\(\)]+\)/g);
        if (m !== null) {
            for (i = 0; i < m.length; i++) {
                //match all numeric strings
                tmp = m[i].match(/-?\d+\.?\d*/g);
                if (tmp !== null) {
                    //convert all the coordinate sets in tmp from strings to Numbers and convert to LatLng objects
                    for (j = 0, tmpArr = []; j < tmp.length; j+=2) {
                        lat = Number(tmp[j]);
                        lng = Number(tmp[j + 1]);
                        tmpArr.push({ lat: lng, lon: lat });
                    }
                    arr.push(tmpArr);
                }
            }
        }
        //array of arrays of LatLng objects, or empty array
        return arr.length === 1 && _.isArray(arr[0]) ? arr[0] : arr;
    },

    getSeparator: function(options)
    {
        if (options.locale == null)
            options.locale = navigator.language || navigator.userLanguage || "en";

        // changed to 10000.1 because group delimiter is not used when only 1000.1 in some languages
        var numberWithGroupAndDecimalSeparator = 10000.1;
        var parts = Intl.NumberFormat(options.locale).formatToParts(numberWithGroupAndDecimalSeparator);

        return parts.find(x => x.type === options.type).value;
    },
    pointsToWkt: function(points) {
        var innerStringBuilder = (points) => {
            //Make ending point match starting point
            if (points[0].lat != points[points.length-1].lat || points[0].lon != points[points.length-1].lon)
                points.push(points[0]);

            return "((" + points.map(p => p.lon + " " + p.lat).join(",") + "))";
        };

        var isMultiPolygon = _.isArray(points) && points.length > 0 && _.isArray(points[0]);

        if (isMultiPolygon) {
            // Convert each polygon array to a WKT polygon string
            var polygonsWkt = points.map(polygon => { return innerStringBuilder(polygon) }).join(",");

            // Return the MULTIPOLYGON WKT string
            return "MULTIPOLYGON(" + polygonsWkt + ")";
        } else {
            // Return the POLYGON WKT string
            return "POLYGON" + innerStringBuilder(points); 
        }
    },
    compareArrays: function(a, b)
    {
        return _.isArray(a) && _.isArray(b) && a.length === b.length && a.every((element, index) => element === b[index]);
    },
    repeatString: function(options) {
        return new Array(options.number + 1).join(options.value);
    },
    truncateDecimalPlaces: function(options) {
        var myPlaces = parseInt("1" + helpers.repeatString({ value: "0", number: options.places }));
    
        return Math.floor(options.value * myPlaces) / myPlaces;
    },
    getSmallTransparentImage: function() {
        return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    },
    isLatLon: function(str) {
        const coordRegex = /^-?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*-?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
        return coordRegex.test(str);
    },
    convertSmartTagToString: function(options) {
        var result = options.Text || '';

        var sortedWords = _.sortBy(options.Words, 'Position').reverse();

        _.each(sortedWords, (word) => {
            result = this.spliceString(result, word.Start, word.End, `#{${word.MetaData.find(element => element.Key === 'key').Value}}`);
        });

        return result;
    },
    convertStringToSmartTag: function(options) {
        var result = {
            Id: options.id,
            Text: options.text,
            Words: []
        };

        var matches = options.text.match(/#{.*?}/g);

        if (matches) {
            _.each(matches, (match, i) => {
                var key = match.replace(/#{/g, '').replace(/}/g, '');
                var label = options.words.find(element => element.key == key).value
                var word = {
                    Start: result.Text.indexOf(match),
                    End: result.Text.indexOf(match) + label.length,
                    Position: i,
                    Text: label,
                    ReplaceData: null,
                    SmartTagId: this.emptyGuid(),
                    MetaData: [
                        { Key: 'key', Value: key },
                        { Key: 'html', Value: `<div style="white-space: nowrap;">${label}</div>` },
                        { Key: 'value', Value: label },
                        { Key: 'label', Value: label }
                    ]
                };

                result.Words.push(word);

                result.Text = result.Text.replace(match, label);
            });
        }

        return result;
    },
    spliceString(str, start, end, item) {
        let before = str.slice(0, start);
        let after = str.slice(end)
        return before + item + after;
    },
    parseLabelStyle: function(labelStyleString) {
        const labelStyleObject = {};

        labelStyleString.split(';').forEach(rule => {
            if (rule.trim()) {
                const [key, value] = rule.split(':').map(str => str.trim());
                labelStyleObject[key] = value;
            }
        });

        var background = { r: 0, g: 0, b: 0, a: 1 };
        if (labelStyleObject.hasOwnProperty('background-color'))
            background = helpers.stringToColor(labelStyleObject['background-color'], 1);

        var color = { r: 255, g: 255, b: 255, a: 1 };
        if (labelStyleObject.hasOwnProperty('color'))
            color = helpers.stringToColor(labelStyleObject['color'], 1);

        var fontSize = 10;
        if (labelStyleObject.hasOwnProperty('font-size'))
            fontSize = parseInt(labelStyleObject['font-size'].replace('pt', ''));

        var bold = false;
        if (labelStyleObject.hasOwnProperty('font-weight') && labelStyleObject['font-weight'].toLowerCase() === 'bold')
            bold = true;

        var italic = false;
        if (labelStyleObject.hasOwnProperty('font-style') && labelStyleObject['font-style'].toLowerCase() === 'italic')
            italic = true;

        var underline = false;
        if (labelStyleObject.hasOwnProperty('text-decoration') && labelStyleObject['text-decoration'].toLowerCase() === 'underline')
            underline = true;

        var textAlign = constants.textAlignment.center;
        if (labelStyleObject.hasOwnProperty('text-align'))
            switch (labelStyleObject['text-align'].toLowerCase()) {
                default:
                case 'center':
                    textAlign = constants.textAlignment.center;
                    break;
                case 'left':
                    textAlign = constants.textAlignment.left;
                    break;
                case 'right':
                    textAlign = constants.textAlignment.right;
                    break;
            }

        return {
            color: color,
            background: background,
            fontSize: fontSize,
            bold: bold,
            italic: italic,
            underline: underline,
            textAlign: textAlign
        };
    }
};