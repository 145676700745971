// React imports
import { useEffect, useState } from 'react';

import { DropDown } from '../../base/dropDown/dropDown';
import { Button } from '../../base/button/button';
import { constants } from '../../../utils/constants';
import { translate } from '../../../utils/translation';
import { icons } from '../../base/icon/icon';
import { RadioButtonSet } from '../../base/radioButtonSet/radioButtonSet';
import { TextBox } from '../../base/textBox/textBox';
import { ColorBox } from 'devextreme-react/color-box';
import { tradeAreas as tradeAreaModule } from '../../../modules/tradeAreas';
import { Loader } from '../../base/loader/loader';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { successToast } from '../../base/toast/toast';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { helpers } from '../../../utils/helpers';

const _ = require("lodash");

export function ProjectionSavedShape({ projection, model, onSave, shown }) {
    

    const [tradeAreaId, setTradeAreaId] = useState(null);
    const [tradeArea, setTradeArea] = useState(null);
    const [tradeAreas, setTradeAreas] = useState([]);
    const [savedShape, setSavedShape] = useState(translate('modeled_trade_area'));
    const [savedShapes, setSavedShapes] = useState([]);    
    const [saveShapeMode, setSaveShapeMode] = useState(0);   
    const [generating, setGenerating] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {        
        (async () => {

            setLoading(true);

            if (shown !== true)
                return;            

            var defaults = await tradeAreaModule.getDefaults({ 
                dataSourceId: projection.layer.dataSourceId,
                customQueryId: projection.layer.customQueryId,
                pointId: projection.pointId,
                latitude: projection.location.lat,
                longitude: projection.location.lon
            });
            
            var modeledTradeArea = _.cloneDeep(defaults.defaultTradeArea);
            modeledTradeArea.id = helpers.newGuid();
            modeledTradeArea.name = translate('modeled_trade_area');
            modeledTradeArea.type = constants.tradeAreas.types.geography;
            modeledTradeArea.fillColor = constants.polygons.selected.fillColor;
            modeledTradeArea.lineColor = constants.polygons.selected.strokeColor;
            modeledTradeArea.lineWidth = constants.polygons.selected.strokeWidth;
            modeledTradeArea.pointId = projection.pointId;
            modeledTradeArea.dataSourceGuid = projection.layer.dataSourceId;
            modeledTradeArea.geographyVintage = model.geographyVintage.Id;
            modeledTradeArea.geoLevel = model.geographyVintage.GeoLevels[0].Id;
            modeledTradeArea.geographyIds = model.geographyData.filter(geo => geo.Value.selected).map((geo) => { return geo.Key; });
            modeledTradeArea.polygon = {
                polygonString: "",
                polygonType: constants.encodedString.google
            };
            
            setTradeArea(modeledTradeArea);
            setTradeAreaId(modeledTradeArea.id);
            setTradeAreas([modeledTradeArea, ...projection.tradeAreas.filter(x => _.isString(x.tradeArea.polygon.polygonString) && x.tradeArea.polygon.polygonString.length > 0).map(x => {
                var projectionTradeArea = _.cloneDeep(defaults.defaultTradeArea);
                projectionTradeArea.id = helpers.newGuid();   
                projectionTradeArea.name = x.tradeArea.name;
                projectionTradeArea.type = constants.tradeAreas.types.userDrawn;
                projectionTradeArea.fillColor = { r: 0, g: 0, b: 0, a: 0 };
                projectionTradeArea.lineColor = { r: 0, g: 0, b: 0, a: 1 };
                projectionTradeArea.lineWidth = 1;
                projectionTradeArea.pointId = projection.pointId;
                projectionTradeArea.dataSourceGuid = projection.layer.dataSourceId;
                projectionTradeArea.polygon = x.tradeArea.polygon;
                return projectionTradeArea;
                
            })]);
            setSavedShape(defaults.savedTradeAreas.length > 0 ? defaults.savedTradeAreas[0] : null);
            setSavedShapes([...defaults.savedTradeAreas]);

            setLoading(false);
            
        })();
    }, [shown]);

    const saveTradeArea = async () => {

        var saveTradeArea = _.cloneDeep(tradeArea);

        switch(saveShapeMode)
        {
            case 0:
                saveTradeArea.id = helpers.emptyGuid();
                saveTradeArea.polygon.id = helpers.emptyGuid();
                break;
            case 1:
                saveTradeArea.id = savedShape.id;
                saveTradeArea.polygon.id = savedShape.polygon.id;
                saveTradeArea.savedType = saveTradeArea.type === constants.tradeAreas.types.savedShape ? constants.tradeAreas.types.savedShape : saveTradeArea.type;
                saveTradeArea.type = saveTradeArea.savedType;
                break;
        }        
        setGenerating(true);
        await legacyEndpoints.service({
            name: 'SaveAnalyticsProjectionShape',
            parameters: {
                shape: saveTradeArea
            }
        });
        setGenerating(false);
        successToast(translate('success'));
        onSave();
    };
            
    return <>
        {
            !_.isObject(tradeArea) || loading?  <Loader /> 
        : 
            <>
            <Loader theme='generate' loaded={!generating} />
            <div className='app-projections-save-shape-section-button'>
            {
                saveShapeMode === 0 ? 
                <Button theme='primary' size='tiny' icon={icons.check} onClick={async ()=>{await saveTradeArea()} }/>
                    :
                <ConfirmButton 
                    theme='primary' 
                    size='tiny'
                    icon={icons.check}
                    clearText={translate('overwrite')}
                    onConfirm={async () => { await saveTradeArea(); }}
                />
            }                
            </div>
            <div className='app-projections-save-shape-body'>                    
                <div className='app-projections-save-shape-section'>{translate('action')}</div>
                <RadioButtonSet>
                    <Button theme='secondary' size='medium' text={translate('new')} active={saveShapeMode === 0} icon={icons.star} onClick={()=>{setSaveShapeMode(0);}} />
                    <Button theme='secondary' size='medium' text={translate('overwrite')} active={saveShapeMode === 1} icon={icons.edit} disabled={savedShapes.length === 0} onClick={()=>{setSaveShapeMode(1);}} />
                </RadioButtonSet>
                <DropDown 
                    label={translate('trade_area')}
                    display='name'
                    valueProperty='id'
                    selected={tradeAreaId}
                    items={tradeAreas}
                    onChange={(o) => {                        
                        if (!o.userChanged)
                            return;

                        setTradeArea(tradeAreas.find(x => x.id === o.value));
                        setTradeAreaId(o.value);
                    }}
                />
                {
                    saveShapeMode === 1 ? <DropDown 
                        label={`${translate('overwrite') } ${translate('saved_shape')}`}
                        display='name'
                        selected={savedShape}
                        items={savedShapes}
                        disabled={saveShapeMode === 0}
                        onChange={(o) => {
                            if (o.userChanged)
                                setSavedShape(o.value);
                        }}
                /> : null
                }
                
                <div className='app-projections-save-shape-section'>{translate('properties')}</div>            
                <TextBox label={translate('name')} value={tradeArea.name} onChange={(o) => {
                    if (o.userChanged)
                        setTradeArea({...tradeArea, name: o.value}); 
                }} />
                <ColorBox 
                    label={translate('fill_color')} 
                    defaultValue={_.isObject(tradeArea.fillColor) ? `rgba(${tradeArea.fillColor.r},${tradeArea.fillColor.g},${tradeArea.fillColor.b},${tradeArea.fillColor.a})` : `rgba(0,0,0,0)` } 
                    value={_.isObject(tradeArea.fillColor) ? `rgba(${tradeArea.fillColor.r},${tradeArea.fillColor.g},${tradeArea.fillColor.b},${tradeArea.fillColor.a})` : `rgba(0,0,0,0)`} 
                    editAlphaChannel={true}
                    onValueChange={(o) => {                        
                        var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                        setTradeArea({...tradeArea, fillColor: { r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) }});
                    }} 
                />
                <ColorBox 
                    label={translate('line_color')} 
                    defaultValue={_.isObject(tradeArea.lineColor) ? `rgba(${tradeArea.lineColor.r},${tradeArea.lineColor.g},${tradeArea.lineColor.b},${tradeArea.lineColor.a})` : `rgba(0,0,0,0)`} 
                    value={_.isObject(tradeArea.lineColor) ? `rgba(${tradeArea.lineColor.r},${tradeArea.lineColor.g},${tradeArea.lineColor.b},${tradeArea.lineColor.a})` : `rgba(0,0,0,0)`} 
                    editAlphaChannel={true}
                    onValueChange={(o) => {
                        var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                        setTradeArea({...tradeArea, lineColor: { r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) }});
                    }} 
                />
                <DropDown 
                    label={translate('line_width')} 
                    selected={tradeArea.lineWidth} 
                    fieldRender={(data) => {
                        return <>
                            <img alt={translate('line_width')} className='app-shape-edit-line-width-image' src={data ? data.image : ''}/>
                            <TextBox readOnly={true} visible={false}/>
                        </>
                    }}
                    itemRender={(data) => { return <img alt={data.name} src={data.image} />}} 
                    valueProperty='value'
                    onChange={(o) => { 
                        if (o.userChanged)
                            setTradeArea({...tradeArea, lineWidth: o.value});
                    }}
                    items={[1, 2, 3, 4, 5, 6].map((width) => { 
                        return {
                            name: `LineWidth_${width}`,
                            value: width,
                            image: `https://tasonline.com/Content/Media/LineStyle/LineWidth_${width}.gif`
                        }
                    })}
                />
            </div>
            </>
        }            
    </>;    
};