// React imports
import { useState, useRef, useEffect, useCallback } from 'react';

// App imports
import { TaskDetail } from './taskDetail/taskDetail';
import { TaskAction } from './taskAction/taskAction';
import { TaskHistory } from './taskHistory';
import { translate } from '../../../utils/translation';
import TaskList from './taskList/taskList';
import TaskShare from './taskShare/taskShare';
import { tasks as tasksModule} from '../../../modules/tasks';

const _ = require('lodash');
export function Tasks(test = null) {

    const [task, setTask] = useState(null);
    const [taskMetaData, setTaskMetaData] = useState({});
    const [taskDetailActive, setTaskDetailActive] = useState(false);
    const [taskSupportId, setTaskSupportId] = useState(null);
    const [taskActionActive, setTaskActionActive] = useState(false);
    const [taskHistoryActive, setTaskHistoryActive] = useState(false);
    const [taskShareActive, setTaskShareActive] = useState(false);  
    const taskListRef = useRef(null);

    const memoizedSetTask = useCallback((task) => {setTask(task)}, []);
    const memoizedSetTaskDetailActive = useCallback((taskDetailActive) => {setTaskDetailActive(taskDetailActive)}, []);
    const memoizedSetTaskActionActive = useCallback((taskActionActive) => {setTaskActionActive(taskActionActive)}, []);
    const memoizedSetTaskHistoryActive = useCallback((taskHistoryActive) => {setTaskHistoryActive(taskHistoryActive)}, []);
    const memoizedSetTaskShareActive = useCallback((taskShareActive) => {setTaskShareActive(taskShareActive)}, []);
    const memoizedSetTaskSupportId = useCallback((taskSupportId) => {setTaskSupportId(taskSupportId)}, []);

    useEffect(() => {
        async function fetchData() {
            setTaskMetaData(await tasksModule.getTaskMetadata());
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (taskListRef.current)
            taskListRef.current.refreshGrid();
    }, [test]);

    useEffect(() => {
        if (task && task.id && task.id !== taskSupportId)
        {
            setTaskActionActive(false);
            setTaskHistoryActive(false);
            setTaskShareActive(false);
        }

    }, [task]);

    const handleSave = () => {
        if (taskListRef.current)
            taskListRef.current.refreshGrid();
    }

    const handleCancel = () => {
        if (taskListRef.current)
            taskListRef.current.clearSelection();
    }

    return <div className='app-tasks'>
        <div className='app-task-list-section'>
            <TaskList ref={taskListRef} setTask={memoizedSetTask} 
                setTaskDetailActive={memoizedSetTaskDetailActive} 
                setTaskActionActive={memoizedSetTaskActionActive} 
                setTaskHistoryActive={memoizedSetTaskHistoryActive} 
                setTaskShareActive={memoizedSetTaskShareActive} 
                setTaskSupportId={memoizedSetTaskSupportId}>
            </TaskList>
        </div>
        <div className='app-task-support-section'>
            <div className='app-task-detail-section'>
                { taskDetailActive ? 
                    <TaskDetail task={ {id: task.id, entity: null} } taskMetaData={taskMetaData} showHeader={true} onSave={() => {handleSave();}} onCancel={() => {handleCancel();}} /> 
                : 
                <>
                    <div className='app-task-section-empty-header'>{translate("task_details")}</div>
                    <div className='app-task-section-empty-body'>           
                        <div className='app-task-body-null'>
                            <div className='app-task-body-empty'>
                                {translate('task_detail_section')}
                            </div>		
                        </div>	
                    </div>	
                </>
                }
            </div>
            <div className='app-task-action-section'>
                { taskActionActive ? 
                    <TaskAction task={task} onCancel={()=>{setTaskActionActive(false)}}/> 
                : taskHistoryActive ? 
                    <TaskHistory task={task} onCancel={()=>{setTaskHistoryActive(false)}} /> 
                : taskShareActive ?
                    <TaskShare task={task} taskMetaData={taskMetaData} onCancel={()=>{setTaskShareActive(false)}}/>
                :
                <>
                    <div className='app-task-section-empty-header'>{translate("task_activity")}</div>
                    <div className='app-task-section-empty-body'>
                        <div className='app-task-body-null'>
                            <div className='app-task-body-empty'>
                                {translate('task_action_section')}
                            </div>		
                        </div>	
                    </div>	
                </>
                }
            </div>
        </div>
    </div>
}