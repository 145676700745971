// React imports
import { useState, useEffect } from 'react';

// Third party imports
import validator from 'validator';

// App imports
import { Loader } from '../../../base/loader/loader';
import { TextBox } from '../../../base/textBox/textBox';
import { Button }  from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { userPreferences } from '../../app';
import { successToast, errorToast } from '../../../base/toast/toast';
import { UserPhoto } from './userPhoto';
import { ModalCard } from '../../../base/modalCard/modalCard';
import { header } from '../../header/header';

export function UserProfile({userImage, onNameChange}) {

    const textBoxHeight = '55px';

    const [userProfileLoaded, setUserProfileLoaded] = useState(false);
    const [userProfile, setUserProfile] = useState(null);	
    const [showPhotoEditor, setShowPhotoEditor] = useState(false);	
    const [editPhoto, setEditPhoto] = useState(false);	
    const [currentUserImage, setCurrentUserImage] = useState(null);

    useEffect(() => {
        legacyEndpoints.service({
            name: 'GetUserInformation',
            parameters: {
                Id: userPreferences.UserGUID
            },
            success: function(r) {
                setUserProfile(r);
                setUserProfileLoaded(true);
            }
        });
        setCurrentUserImage(userImage);
    }, [userImage]);

    const editUserPhoto = () => {
        setEditPhoto(true);
        setShowPhotoEditor(!showPhotoEditor);
    };

    const uploadNewPhoto = () => {
        setEditPhoto(false);
        setShowPhotoEditor(!showPhotoEditor);
    };
    
    const updateUserImage = () => {
        const updatedUserImage = legacyEndpoints.handlers.getPhotoUrl({ isUserPhoto: true, width: 150, height: 150 }) +'&dummy=' + crypto.randomUUID();
        setCurrentUserImage(updatedUserImage);
        header.updateUserImage(updatedUserImage);
    };

    return <>
        <Loader className='app-card-body-loader' loaded={userProfileLoaded} />
        {!userProfileLoaded ? '' : 
            <div className='app-user-profile'>
                <div className='app-user-profile-image'>
                    <img className='app-user-profile-userImage' src={currentUserImage} title={translate('edit_user_photo')} alt={userProfile.Name} onClick={()=> {editUserPhoto()}}  />
                    <div className='app-user-profile-image-btns'>
                        <Button theme='tertiary' size='small' icon={icons.upload} tooltip={translate('upload_new_photo')} onClick={()=> {uploadNewPhoto();}} />
                    </div>
                </div>
                <div className='app-user-profile-details'>
                    <TextBox label={translate('display_name')} value={userProfile.Name} height={textBoxHeight} onChange={(o) => { setUserProfile({ ...userProfile, Name: o.value }); }} />
                    <TextBox label={translate('email_address')} value={userProfile.Email} height={textBoxHeight} disabled={true} />
                    <TextBox label={translate('job_title')} value={userProfile.Title} height={textBoxHeight} onChange={(o) => { setUserProfile({ ...userProfile, Title: o.value }); }} />
                    <TextBox label={translate('company')} value={userProfile.Organization} height={textBoxHeight} disabled={true} />
                    <TextBox label={translate('department')} value={userProfile.Department} height={textBoxHeight} onChange={(o) => { setUserProfile({ ...userProfile, Department: o.value }); }} />
                    <TextBox label={translate('phone_number')} value={userProfile.Phone} height={textBoxHeight} onChange={(o) => { setUserProfile({ ...userProfile, Phone: o.value }); }} />
                    <TextBox label={translate('linkedin_profile_url')} value={userProfile.LinkedIn} height={textBoxHeight} onChange={(o) => { setUserProfile({ ...userProfile, LinkedIn: o.value }); }} />
                    <div className='app-user-preferences-save'>
                        <Button theme='tertiary' size='medium' icon={icons.check} tooltip={translate('save')} onClick={()=>{ 
                            if (!userProfile.Name.trim()) {
                                errorToast(translate('user_preferences_error_display_name'));
                                return;
                            }

                            if (userProfile.Phone.trim().length > 0)
                                if (!validator.isMobilePhone(userProfile.Phone.trim())) {
                                    errorToast(translate('user_preferences_error_phone_number'));
                                    return;
                                }

                            if (userProfile.LinkedIn.trim().length > 0)
                                if (!validator.isURL(userProfile.LinkedIn.trim())) {
                                    errorToast(translate('user_preferences_error_linkedin'));
                                    return;
                                }

                            legacyEndpoints.service({
                                name: 'SaveUserInformation',
                                parameters: {
                                    Columns: ["UserName", "Phone", "Department", "Title", "LinkedInUrl"],
                                    Values: [userProfile.Name.trim(), userProfile.Phone.trim(), userProfile.Department.trim(), userProfile.Title.trim(), userProfile.LinkedIn.trim()]
                                },
                                success: function(r) {
                                    userPreferences.UserName = userProfile.Name.trim();

                                    userProfile.Name = userProfile.Name.trim();
                                    userProfile.Title = userProfile.Title.trim();
                                    userProfile.Department = userProfile.Department.trim();
                                    userProfile.Phone = userProfile.Phone.trim();
                                    userProfile.LinkedIn = userProfile.LinkedIn.trim();

                                    if (onNameChange) onNameChange({ value: userProfile.Name.trim() });

                                    successToast(translate('success'));
                                }
                            });
                        }} />
                    </div>
                </div>
                {
                    showPhotoEditor ? 
                    <ModalCard className='app-modal-card-default app-user-profile-image-modal' open={true}>
                        <UserPhoto editPhotoMode={editPhoto} onUpdate={updateUserImage} onClose={()=>{
                            // TBD: this is a hack to delay so that the autoCloser doesn't close the user profile/preferences components
                            setTimeout(function() {
                                setShowPhotoEditor(false)
                            }, 50);
                        }} />         
                    </ModalCard>
                    : null
                }        
            </div>
        }
    </>
}