// React imports
import { useRef, useEffect, useState } from 'react';

// App imports
import { DropDown } from '../../base/dropDown/dropDown';
import { PolygonStyleSwatch } from '../../base/polygonStyleSwatch/polygonStyleSwatch';
import { inputBoxTypes, TextBox } from '../../base/textBox/textBox';
import { Hideable } from '../../base/hideable/hideable';
import { Button } from '../../base/button/button';
import { Bar } from '../../base/bar/bar';
import { icons, Icon } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';
import { Loader } from '../../base/loader/loader';
import { tradeAreas as tradeAreaModule } from '../../../modules/tradeAreas';
import { helpers } from '../../../utils/helpers';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { Popover } from '../../base/popover/popover';
import { TextArea } from '../../base/textArea/textArea';
import { successToast, errorToast } from '../../base/toast/toast';

// 3rd party imports
import { DndContext, closestCenter, KeyboardSensor,PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DraggableItem } from '../../base/dragAndDrop/draggableItem';

const _ = require('lodash');

export function TradeAreas({entity, hide, filteredTypes, onSave, showZoomToResult = true, showName = true, importedTradeAreas = null}){

    const [tradeAreaDefaults, setTradeAreaDefaults] = useState(null);
    const [tradeAreas, setTradeAreas] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [templateDeleteDisabled, setTemplateDeleteDisabled] = useState(false);
    const [isTemplateDefault, setIsTemplateDefault] = useState(false);
    const [saveTemplateName, setSaveTemplateName] = useState('');
    const [saveTemplateDescription, setSaveTemplateDescription] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [zoomToResult, setZoomToResult] = useState(false);
    const [libraryName, setLibraryName] = useState('');

    const templateWindow = useRef(null);

    useEffect(()=>{
        setDefaults();
    },[]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event)=>{ 
        const {active, over} = event;
	
		if (active.id !== over.id) {
		  setTradeAreas(([...items]) => {
			const oldIndex = items.findIndex((item) => item.id === active.id);
			const newIndex = items.findIndex((item) => item.id === over.id);	

			return arrayMove(items, oldIndex, newIndex);
		  });
		}
    };

    var setDefaults = async () =>{
        setLoaded(false);

        var defaults = await tradeAreaModule.getDefaults({
            dataSourceId: entity.layer.parentId,
            customQueryId: entity.layer.id,
            pointId: entity.id,
            latitude: entity.location.lat,
            longitude: entity.location.lon,
            filteredTypes: filteredTypes
        });

        defaults.combinedTemplates = getCombinedTemplates(defaults);

        var selectedTemplate = getDefaultTemplate(defaults.combinedTemplates);
        
        if (_.isObject(selectedTemplate))
            setSelectedTemplateId(selectedTemplate.id);

        setTradeAreaDefaults(defaults);

        var layer = tradeAreaModule.getLibrary({ entity: entity });
        var layerText = tradeAreaModule.getUniqueLibraryName(entity.text);

        if (layer) {
            setTradeAreas([...layer.tradeAreas]);
            layerText = layer.text;
        }
        else if (!_.isNull(importedTradeAreas))
            setTradeAreas(importedTradeAreas);
        else if (_.isObject(selectedTemplate))
            loadTemplate(defaults.combinedTemplates, selectedTemplate.id);

        if (showName)
            setLibraryName(layerText);
        
        setLoaded(true);
    };

    const getRowClass = (type) =>{
        switch (type)
        {
            default:
            case constants.tradeAreas.types.ring:
                return 'app-trade-area-row-ring';
            case constants.tradeAreas.types.driveTime:
            case constants.tradeAreas.types.driveDistance:
                return 'app-trade-area-row-drive-time';
            case constants.tradeAreas.types.geography:
                return 'app-trade-area-row-geography';
            case constants.tradeAreas.types.userDrawn:
                return 'app-trade-area-row-user-drawn';
            case constants.tradeAreas.types.savedShape:
                return 'app-trade-area-row-saved-shape';
        }
    };

    const getTypeElement = (tradeArea) => {

        const typeComponent = () => {
            return <DropDown
                className='app-trade-area-type'
                selected={tradeArea.type}
                items={tradeAreaDefaults.types}
                label={translate('type')}
                display='name'
                valueProperty="key"
                onSelect={(o) => {
                    setTradeAreas((prevItems) =>
                        prevItems.map((item) =>
                          item.id === tradeArea.id ? { ...item, type: o.value.key } : item
                        )
                      );
                }}
            />;
        };

        const distanceComponent = () => {
            return <TextBox
                className='app-trade-area-distance'
                value={tradeArea.distance}
                type={inputBoxTypes.numeric}
                maxLength={2}                
                allowNegative={false}
                allowDecimals={true}
                allowZero={false}
                allowLeadingZeros={false}
                onChange={(o) => {
                    setTradeAreas((prevItems) =>
                        prevItems.map((item) =>
                          item.id === tradeArea.id ? { ...item, distance: o.value } : item
                        )
                      );

                }}
            />;
        };

        const measurementComponent = () => {
            return <DropDown                
                className='app-trade-area-measurement'
                selected={tradeArea.lengthMeasurement}
                items={tradeAreaDefaults.lengthMeasurements}
                display='abbreviation'
                valueProperty="key"
                onSelect={(o) => {     
                    setTradeAreas((prevItems) =>
                        prevItems.map((item) =>
                          item.id === tradeArea.id ? { ...item, lengthMeasurement: o.value.key } : item
                        )
                      );
                }}
            />;
        };

        switch (tradeArea.type)
        {
            default:
            case constants.tradeAreas.types.ring:
            case constants.tradeAreas.types.driveDistance:
                return <>
                    {typeComponent()}
                    {distanceComponent()}
                    {measurementComponent()}
                </>;
            case constants.tradeAreas.types.userDrawn:
            case constants.tradeAreas.types.savedShape:
                return typeComponent();
            case constants.tradeAreas.types.driveTime:
                return <>
                    {typeComponent()}
                    {distanceComponent()}
                    <TextBox className='app-trade-area-minutes' value={translate('minutes')} disabled={true} />                    
                </>
            case constants.tradeAreas.types.geography:
                var geographies = tradeAreaDefaults.standardGeographies.map(geography => { return geography.geoLevels.map(geoLevel => { return { key: `${geography.id}_${geoLevel.Id}`, name: geoLevel.Name, group: geography.name, geographyVintage: geography.id, geoLevel: geoLevel.Id }}) }).flat();    
                var selectedGeography = `${tradeArea.geographyVintage}_${tradeArea.geoLevel}`;

                if (geographies.filter(x => x.key === selectedGeography).length === 0) {
                    setTradeAreas((prevItems) =>
                        prevItems.map((item) =>
                          item.id === tradeArea.id ? { ...item, geographyVintage: geographies[0].geographyVintage, geoLevel: geographies[0].geoLevel } : item
                        )
                      );

                }
                else
                    return <>
                        {typeComponent()}
                        <DropDown
                            className='app-trade-area-geography'
                            selected={geographies.filter(x => x.key === selectedGeography).length === 0  ? geographies[0].key : selectedGeography}
                            items={geographies}
                            label={translate('geography')}
                            grouped={true}
                            display='name'
                            valueProperty='key'
                            onSelect={(o) => {
                                setTradeAreas((prevItems) =>
                                    prevItems.map((item) =>
                                      item.id === tradeArea.id ? { ...item, geographyVintage: o.value.geographyVintage, geoLevel: o.value.geoLevel } : item
                                    )
                                  );

                            }}
                        />
                    </>;
        }
    };

    const getSettingsElement = (tradeArea) =>{
        const driveTimeComponent = (o) => {
            var engines = o.engines.map(engine => { return engine.SpeedSpecifications.map(speed => { return { key: `${engine.Id}_${speed.Id}`, name: speed.Name, group: engine.Name, driveTimeEngine: engine.Id, driveTimeSetting: speed.Id }}) }).flat();
            var selectedEngine = `${tradeArea.driveTimeEngine}_${tradeArea.driveTimeSetting}`;

            if (engines.filter(x => x.key === selectedEngine).length === 0) {
                setTradeAreas((prevItems) =>
                    prevItems.map((item) =>
                      item.id === tradeArea.id ? { ...item, driveTimeEngine: engines[0].driveTimeEngine, driveTimeSetting: engines[0].driveTimeSetting } : item
                    )
                  );

            }
            else
                return <DropDown
                    className='app-trade-area-settings'
                    selected={selectedEngine}
                    items={engines}
                    label={translate('drive_time_setting')}
                    grouped={true}
                    display='name'
                    valueProperty="key"
                    onSelect={(o) => {
                        setTradeAreas((prevItems) =>
                            prevItems.map((item) =>
                              item.id === tradeArea.id ? { ...item, driveTimeEngine: o.value.driveTimeEngine, driveTimeSetting: o.value.driveTimeSetting } : item
                            )
                          );

                }} />;
        };

        switch (tradeArea.type)
        {
            case constants.tradeAreas.types.driveTime:
                return driveTimeComponent({ engines: tradeAreaDefaults.driveTimeEngines });
            case constants.tradeAreas.types.driveDistance:
                return driveTimeComponent({ engines: tradeAreaDefaults.driveDistanceEngines });
            case constants.tradeAreas.types.geography:

                var reports = [{ id: '-1', name: translate('no_report')}]
                    .concat(tradeAreaDefaults.demographicReports.filter(report => report.geographyVintages.filter(vintage => vintage.id === tradeArea.geographyVintage).length > 0))
                    .map(x => { return { id: parseInt(x.id), name: x.name }});
                
                var report = reports.find(x => x.id === tradeArea.demographicReport) ?? reports[0];

                if (!_.isNumber(tradeArea.demographicReport) || reports.filter(x => x.id === tradeArea.demographicReport).length === 0) {
                    setTradeAreas((prevItems) =>
                        prevItems.map((item) =>
                          item.id === tradeArea.id ? { ...item, demographicReport: reports[0].id } : item
                        )
                      );

                }
                else
                    return <DropDown
                        className='app-trade-area-settings'
                        selected={report.id}
                        items={reports}
                        label={translate('demographic_report')}
                        display='name'
                        valueProperty="id"
                        onSelect={(o) => {
                            setTradeAreas((prevItems) =>
                                prevItems.map((item) =>
                                  item.id === tradeArea.id ? { ...item, demographicReport: o.value.id } : item
                                )
                              );
        
                        }}
                    />;
                break;
            case constants.tradeAreas.types.savedShape:  
            if (!_.isString(tradeArea.polygon.id) || tradeArea.polygon.id === helpers.emptyGuid()) {
                    tradeArea.name = tradeAreaDefaults.savedTradeAreas[0].name;
                    tradeArea.polygon.id = tradeAreaDefaults.savedTradeAreas[0].id;
                    tradeArea.savedType = tradeAreaDefaults.savedTradeAreas[0].type;
                    tradeArea.fillColor = tradeAreaDefaults.savedTradeAreas[0].fillColor;                    
                    tradeArea.lineColor = tradeAreaDefaults.savedTradeAreas[0].lineColor;
                    tradeArea.lineWidth = tradeAreaDefaults.savedTradeAreas[0].lineWidth;                    
                    tradeArea.label = tradeAreaDefaults.savedTradeAreas[0].label ?? tradeAreaDefaults.defaultTradeArea.label;
                    tradeArea.autoLabel = tradeAreaDefaults.savedTradeAreas[0].autoLabel;
                    tradeArea.polygon.centroid = entity.location;
                    tradeArea.geoLevel = tradeAreaDefaults.savedTradeAreas[0].geoLevel;
                    tradeArea.geographyVintage = tradeAreaDefaults.savedTradeAreas[0].geographyVintage;
                    tradeArea.geographyIds = tradeAreaDefaults.savedTradeAreas[0].geographyIds;

                    setTradeAreas(prevItems => prevItems.map(item => 
                        item.id === tradeArea.id ? { ...item, tradeArea } : item
                      ));
                }
                else
                {
                    return <DropDown
                    className='app-trade-area-settings'
                    selected={tradeArea.polygon.id}
                    items={tradeAreaDefaults.savedTradeAreas}
                    label={translate('saved_shape')}
                    display='name'
                    valueProperty={(o) => { return o.id; }}
                    onSelect={(o) =>{
                        tradeArea.name = o.value.name;
                        tradeArea.polygon.id = o.value.id;
                        tradeArea.savedType = o.value.type;
                        tradeArea.fillColor = o.value.fillColor;                    
                        tradeArea.lineColor = o.value.lineColor;
                        tradeArea.lineWidth = o.value.lineWidth;                            
                        tradeArea.label = o.value.label ?? tradeAreaDefaults.defaultTradeArea.label;
                        tradeArea.autoLabel = o.value.autoLabel;
                        tradeArea.polygon.centroid = entity.location;
                        tradeArea.geoLevel = o.value.geoLevel;
                        tradeArea.geographyVintage = o.value.geographyVintage;
                        tradeArea.geographyIds = o.value.geographyIds;
                        
                    setTradeAreas(prevItems => prevItems.map(item => 
                        item.id === tradeArea.id ? { ...item, tradeArea } : item
                      ));

                    }}
                />;
                }
                    
        }
    };

    const getDefaultTemplate = (templates) =>{
        return templates.find(x => x.isSelected) ?? (templates.find(x => x.isOrganizationDefault) ?? templates[0]);
    };
    
    const getCombinedTemplates = (templates) =>{
        var userTemplates = templates.userTemplates.map(template => { return { id: template.id, name: template.name, description: template.description, isOrganization: false, isDefault: template.isDefault, group: translate('my_templates'), isSelected: template.isSelected, tradeAreas: template.tradeAreas, zoomToLayer: template.zoomToLayer }});
        var organizationTemplates = templates.organizationTemplates.map(template => { return { id: template.id, name: template.name, description: template.description, isOrganization: true, isDefault: template.isDefault, group: translate('organization_templates'), isSelected: template.isSelected, tradeAreas: template.tradeAreas, zoomToLayer: template.zoomToLayer }});
        return userTemplates.concat(organizationTemplates);
    };

    const setCombinedTemplates = (templates) =>{
        var updatedCombinedTemplates = getCombinedTemplates(templates);

        var selectedTemplate = getDefaultTemplate(updatedCombinedTemplates);
        
        if (_.isObject(selectedTemplate))
            setSelectedTemplateId(selectedTemplate.id);

        setTradeAreaDefaults(prevState => ({
            ...prevState,
            combinedTemplates: updatedCombinedTemplates
        }));

    };

    const loadTemplate = (templates, id) => {        
        var selectedTemplate = templates.find(x => x.id === id);        
        setSaveTemplateName(selectedTemplate.name);
        setSaveTemplateDescription(selectedTemplate.description);
        setIsTemplateDefault(selectedTemplate.isDefault);
        setTemplateDeleteDisabled(selectedTemplate.isOrganization)
        setSelectedTemplateId(id);
        setZoomToResult(selectedTemplate.zoomToLayer);

        var clonedTemplate = _.cloneDeep(selectedTemplate.tradeAreas);
        clonedTemplate.forEach(tradeArea => { 
                                                tradeArea.id = helpers.newGuid();
                                                tradeArea.dirtyName = tradeArea.name.toLowerCase() !== tradeAreaModule.calculateTradeAreaName(tradeArea).toLowerCase();
                                                tradeArea.label.title = tradeArea.label.title ?? '';
                                            });

        setTradeAreas([...clonedTemplate]);
    };

    const saveTemplate = async ({ copy }) =>{
        
        var templateToSave = _.cloneDeep(tradeAreaDefaults.combinedTemplates.find(x => x.id === selectedTemplateId) ?? tradeAreaDefaults.organizationTemplates.find(x => x.id === selectedTemplateId));

        if (copy === true)
            templateToSave.id = helpers.emptyGuid();

        templateToSave.name = saveTemplateName;
        templateToSave.description = saveTemplateDescription;
        templateToSave.tradeAreas = tradeAreas;
        templateToSave.zoomToLayer = zoomToResult;
                
        var result = await tradeAreaModule.saveTemplate({
            template: _.cloneDeep(templateToSave),
            location: entity.location
        });

        if (result === undefined)
            return;

        templateWindow.current.instance.hide();

        setCombinedTemplates(result);

        successToast(translate('success'));
    };

    const getTemplates = () =>{

        if (!tradeAreaDefaults)
            return;

        return <>
            <td>                
                <Popover ref={templateWindow} parentId='#app-trade-areas-template-save' title={translate('save')}height={335} width={400}>
                    <div className='app-projections-scenario-save'>                    
                        <Button  theme='primary' text={translate('save_as')} icon={icons.files} disabled={saveTemplateName.length === 0} onClick={async ()=>{
                            saveTemplate({ copy: true });                           
                        }}/>
                        {
                            tradeAreaDefaults.combinedTemplates.find(x => x.id === selectedTemplateId)?.isOrganization ? null :
                            <Button theme='primary' text={translate('save')} icon={icons.save} disabled={saveTemplateName.length === 0} onClick={async ()=>{                
                                saveTemplate({ copy: false });
                            }}/>
                        }                        
                    </div>
                    <TextBox 
                        label={translate('name')}
                        value={saveTemplateName} 
                        onChange={(o) => {
                            setSaveTemplateName(o.value)
                        }} 
                    />
                    <TextArea 
                        label={translate('description')} 
                        minHeight='150px' 
                        value={saveTemplateDescription}
                        onChange={(o) =>{
                            setSaveTemplateDescription(o.value);
                        }}
                    />       
                </Popover>
                <Button className='app-trade-areas-template-favorite' theme='action' icon={icons.star} tooltip={translate('favorite')} active={isTemplateDefault} onClick={async () =>{ 
                    
                    setIsTemplateDefault(!isTemplateDefault);
                    
                    setCombinedTemplates(
                        await tradeAreaModule.setDefaultTemplate({
                            id: isTemplateDefault ? helpers.emptyGuid() : selectedTemplateId,
                            location: entity.location
                        })
                    );
                                        
                }}/>
                <DropDown 
                    className='app-trade-areas-template' 
                    selected={selectedTemplateId}
                    items={tradeAreaDefaults.combinedTemplates}
                    label={translate('template')}
                    grouped={true}
                    display='name'
                    valueProperty='id'
                    onChange={(o) =>{
                        if(!o.userChanged) return;

                        loadTemplate(tradeAreaDefaults.combinedTemplates, o.value);
                    }}
                />
                <ConfirmButton className='app-trade-areas-template-reload' theme='action' icon={icons.repeatArrow} tooltip={translate('reload')} clearText={translate('reload')} onConfirm={() =>{loadTemplate(tradeAreaDefaults.combinedTemplates, selectedTemplateId);}}/>
                <ConfirmButton className='app-trade-areas-template-clear' theme='action' icon={icons.eraser} tooltip={translate('clear')} onConfirm={() =>{
                    setTradeAreas([]);
                }}/>
                <Button id='app-trade-areas-template-save' className='app-trade-areas-template-save' theme='action' icon={icons.save} tooltip={translate('save')}/>
                <ConfirmButton className='app-trade-areas-template-delete' theme='action' icon={icons.trash} tooltip={translate('delete')} disabled={templateDeleteDisabled} clearText={translate('delete')} onConfirm={async() =>{
                    setCombinedTemplates(
                        await tradeAreaModule.deleteTemplate({
                            id: selectedTemplateId,
                            location: entity.location
                        })
                    );                    
                }}/>
            </td>
        </>;
    };

    function save() {
        var layerText = null;
        var layer = tradeAreaModule.getLibrary({ entity: entity });
        if (layer)
            layerText = layer.text;

        if (!tradeAreaModule.isUniqueLibraryName({ name: libraryName }) && libraryName !== layerText) {
            errorToast(translate('trade_area_library_unique_name'));
            return;
        }

        const hasDuplicateName = tradeAreas.some((tradeArea) => 
            tradeAreas.filter(ta => ta.name.trim() === tradeArea.name.trim()).length > 1
        );

        if (hasDuplicateName) {
            errorToast(translate('trade_area_unique_name_validation'));
            return;
        }

        onSave({ libraryName: libraryName, tradeAreas: tradeAreas, tradeAreaDefaults: tradeAreaDefaults, zoomToResult: zoomToResult });
    }

	return <Hideable className='app-trade-areas' hide={hide}>
        <Loader loaded={loaded} />
        <div className='app-trade-areas-header'>
            <table>
                <tbody>
                    <tr>
                        { showName ? 
                            <td>
                                <TextBox className='app-trade-areas-name' label={translate('name')} value={libraryName} valueChangeEvent={'change keyup'} 
                                    onChange={(o) => { 
                                        setLibraryName(o.value);
                                    }}
                                />
                            </td> 
                        : ''}
                        {getTemplates()}
                        <td>
                            <div className='app-trade-areas-generation'>
                                <Button className='app-map-book-builder-button' theme='secondary' size='small' icon={icons.plus} tooltip={translate('add')} disabled={tradeAreas.length===5} onClick={() =>{
                                    var tradeArea = _.cloneDeep(tradeAreaDefaults.defaultTradeArea);
                                    tradeArea.id = helpers.newGuid();
                                    tradeArea.dirtyName = false;
                                    tradeArea.name = tradeAreaModule.calculateTradeAreaName(tradeArea)

                                    setTradeAreas([...tradeAreas, tradeArea]); 
                                }} />
                                <Button className='app-map-book-builder-button' theme='primary' size='small' icon={icons.check} tooltip={translate('save')} disabled={tradeAreas.length===0} 
                                    onClick={() =>{ save(); }}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className='app-trade-areas-body'>
            <div className='app-trade-areas-actions'>
            { !showZoomToResult ? '' : 
                <div className='app-option-selector'>
                    {zoomToResult === true ? 
                    <div className='app-option-selector-check'>
                        <Icon icon={icons.check} />
                    </div> : ''}
                    <Bar className='app-trade-areas-zoom-bar'
                        icon={icons.magnifyingGlass}
                        text={translate('zoom_to_result')}
                        active={zoomToResult === true} 
                        onClick={() => { setZoomToResult(!zoomToResult); }} 
                    /> 
                </div>
            }
            </div>
            <div className='app-trade-areas-content'>
            {
                tradeAreas.length === 0 ? <div className='app-trade-areas-empty'>{translate('add_trade_areas')}</div> :
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}> 
                    <SortableContext items={tradeAreas} strategy={verticalListSortingStrategy}>
                    {
                        tradeAreas.map((tradeArea) => {
                            var calculatedName = tradeAreaModule.calculateTradeAreaName(tradeArea);

                            tradeArea.name = tradeArea.dirtyName ? tradeArea.name : calculatedName;
        
                            return <DraggableItem key={tradeArea.id} id={tradeArea.id}>
                                <div className={`app-trade-area-row ${getRowClass(tradeArea.type)}`}>                                    
                                    {getTypeElement(tradeArea)}
                                    {getSettingsElement(tradeArea)}
                                    <TextBox className='app-trade-area-name' label={translate('name')} value={tradeArea.name} onChange={(o) => {
        
                                        var dirtyValue = false;

                                    if (o.userChanged)
                                            dirtyValue = true;    

                                        setTradeAreas((prevItems) =>
                                            prevItems.map((item) =>
                                              item.id === tradeArea.id ? { ...item, name: o.value, dirtyName: dirtyValue } : item
                                            )
                                          );

                                    }} />
                                    <div className='app-trade-area-style'>
                                        <div className='app-trade-area-style-label'>{translate('style')}</div>
                                        <PolygonStyleSwatch style={tradeArea} onChange={(style) =>{                                                                            
                                            setTradeAreas((prevItems) =>
                                                prevItems.map((item) =>
                                                  item.id === tradeArea.id ? { ...item, fillColor: style.fillColor, lineColor: style.lineColor, lineWidth: style.lineWidth } : item
                                                )
                                              );

                                        }}/>
                                    </div>
                                    <div className='app-trade-area-style'>
                                        <div className='app-trade-area-style-label'>{translate('label')}</div>
                                        <PolygonStyleSwatch textStyle={tradeArea} onChange={(style) =>{
                                            setTradeAreas((prevItems) =>
                                                prevItems.map((item) =>
                                                    item.id === tradeArea.id ? { ...item, label: {...item.label, style: style.label.style}, autoLabel: style.autoLabel } : item
                                                )
                                              );
                                        }}/>
                                    </div>
                                    <div className='app-trade-area-actions'>
                                        <Button theme='simple' icon={icons.trash} tooltip={translate('remove')} onClick={() => {
                                            setTradeAreas(tradeAreas.filter(x => x !== tradeArea));
                                        }} />
                                    </div>
                                </div>
                            </DraggableItem>
                        })
                    }
                    </SortableContext>
                </DndContext>                
            }
            </div>
        </div>
    </Hideable>
}