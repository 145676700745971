// React imports
import { React, useState } from 'react';

// App imports
import { icons, Icon } from '../../../../base/icon/icon';
import { translate } from '../../../../../utils/translation';
import { Tabs } from '../../../../base/tabs/tabs';
import { TreeViewList } from '../../../../base/treeViewList/treeViewList';
import { Bar } from '../../../../base/bar/bar';
import { helpers } from '../../../../../utils/helpers';

const _ = require("lodash");

export function CustomChannelSelector({selectedChannels, updateSelectedChannels, selectedChains, updateSelectedChains, displayShowOnlySelected = false}) {
	
	const [showOnlySelected, setShowOnlySelected] = useState(false);
	const [selectedChannelCount, setSelectedChannelCount] = useState(0);
	const [selectedChainCount, setSelectedChainCount] = useState(0);

	const rendererShowOnlySelected = () =>{
        return <>
			{displayShowOnlySelected === false ? '' :
				<div className='app-option-selector app-competitive-insights-show-only-selected-button'>
					{showOnlySelected === true ? 
					<div className='app-option-selector-check'>
						<Icon icon={icons.check} />
					</div> : ''}
					<Bar className='app-tools-bulk-bar-buttons app-tools-bulk-bar-short'
						text={translate('show_only_selected')} 
						onClick={() => { 
							setShowOnlySelected(!showOnlySelected); 
						}} 
					/> 
				</div>		
			}
		</>
    };

	var selectedChannelsDataSource = [];
	_.each(selectedChannels, function(channel)
	{
		var selectedChannelChains = [];

		_.each(channel.chains, function(chain) {
			selectedChannelChains.push({
				id: chain.chainChannelId,
				parentId: chain.channelId,
				name: chain.name,
				isSelected: chain.isSelected
			});
		});

		selectedChannelsDataSource.push({
			id: channel.id,
			parentId: helpers.emptyGuid(),
			name: channel.name,
			isSelected: channel.isSelected,
			chains: selectedChannelChains
		});
	});

	var selectedChainsDataSource = [];
	_.each(selectedChains, function(chain) {
		selectedChainsDataSource.push({
			id: chain.chainChannelId,
			parentId: helpers.emptyGuid(),
			name: chain.name,
			isSelected: chain.isSelected
		});
	});

	const tabs = [{
		id: 1,
		text: `${translate('selected_channels')} (${selectedChannelCount})`,
		component: <div className='app-custom-channels-selector-list'>
			<TreeViewList 
				keyExpr="id"
				itemsExpr="chains"
				dataStructure="tree"     
				dataSource={showOnlySelected ? selectedChannelsDataSource.filter(c => c.isSelected) : selectedChannelsDataSource}  
				selectedItems= {selectedChannelsDataSource.filter(channel => channel.isSelected).map(channel => { return channel.id; })}  
				selectedItemsChange={(o)=> {
					var index = -1;
					
					_.forEach(selectedChannelsDataSource, function(channel) {
						index++;

						if (o.findIndex(c => c === channel.id) > -1)
						{
							channel.isSelected = true;
							selectedChannels[index].isSelected = true;
						}
						else
						{
							channel.isSelected = false;
							selectedChannels[index].isSelected = false;
						}
					});

					updateSelectedChannels(selectedChannels);
					setSelectedChannelCount(o.length);
				}}    
				//height={'400'}
				displayExpr='name'
				showParentCheckboxOnly={true}
			/>
		</div>
	},
	{
		id: 2,
		text: `${translate('selected_chains')} (${selectedChainCount})`,
		component: <div className='app-custom-chains-selector-list'>
			<TreeViewList 
				keyExpr="id"
				dataStructure="plain"
				dataSource={showOnlySelected ? selectedChainsDataSource.filter(c => c.isSelected) : selectedChainsDataSource}
				selectedItems= {selectedChainsDataSource.filter(chains => chains.isSelected).map(chains => { return chains.id; })}  
				selectedItemsChange={(o)=> {
					var index = -1;

					_.forEach(selectedChainsDataSource, function(chain) {
						index++;

						if (o.findIndex(c => c === chain.id) > -1)
						{
							chain.isSelected = true;
							selectedChains[index].isSelected = true;
						}
						else
						{
							chain.isSelected = false;
							selectedChains[index].isSelected = false;
						}
					});

					updateSelectedChains(selectedChains);
					setSelectedChainCount(o.length);
				}}    
				//height={'400'}
				displayExpr='name'
				showParentCheckboxOnly={false}
				rootValue={helpers.emptyGuid()}
				scrollingMode="virtual"
				virtualModeEnabled={true}
				parentIdExpr="parentId"
			/>
		</div>
	}];

	return <>
		{ !_.isArray(selectedChannels) || ! _.isArray(selectedChains) ? '' :
			<>
				{rendererShowOnlySelected()}
				<Tabs tabs={tabs} onTabClick={(t) => {
					if (t === 2)
					{
						//e.component.updateDimensions();
						//$(".app-custom-chains-selector-list span.dx-checkbox-icon").click();
					}
				}} />
			</>
		}	
	</>
}