// App imports
import { map } from '../components/app/map/map';
import { layerActions } from '../components/app/layers/layer/layer';
import { constants } from '../utils/constants';
import { helpers } from '../utils/helpers';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { translate } from '../utils/translation';
import { userPreferences } from '../components/app/app';
import { successToast } from '../components/base/toast/toast';
import { layers } from '../components/app/layers/layers';
import { cosmetic } from './cosmetic';
import { sources } from './sources';
import { Background } from 'devextreme-react/cjs/range-selector';

const _ = require("lodash");

export const artificialIntelligence = {	
    create: ({ data, layerName }) => {

        var layer = map.addLayer({
            group: constants.layers.groups.ai,
            type: constants.layers.types.ai,
            text: layerName,
            actions: [{
                id: layerActions.label,
                getActive: () => { return layer.labeled; },
                getHalfActive: () => { return layer.oppositeLabeledEntities.length > 0; },
                onClick: () =>{							

                    layer.labeled = !layer.labeled;
                    layer.clearLabels();

                    if (layer.labeled)
                        layer.entities.filter(x => x.type === constants.entities.pushpin).forEach(entity => {
                            sources.createLabel({ layer: layer, entity: entity, properties: {
                                draggable: false,
                                style: {
                                    color: { r: 255, g: 255, b: 255, a: 1 },
                                    background: { r: 0, g: 0, b: 0, a: 1 },
                                    fontSize: 11,
                                    bold: true,
                                }
                            },
                            });
                        });                        
                }
            },
            {
                id: layerActions.delete,
                onClick: () =>{
                    layer.dispose();
                }
            }],
            onChange: ()=>{
                layers.update();                
            }
        });

        layer.dirtyLabels = [];
        layer.legened = [];

        data.forEach((point, i) => {            
            map.geocode({
                query: point.address,
                callback: (result) => {
    
                    var symbol = legacyEndpoints.handlers.getSymbolUrl({ imageUrl: userPreferences.DefaultGeocodeSymbol });
                    var symbolDimensions = helpers.getDimensionsFromSymbolUrl(legacyEndpoints.handlers.getSymbolUrl({ imageUrl: userPreferences.DefaultPushpinSymbol }));
                    var name = _.isString(point.name) && point.name.length > 0 ? point.name : point.address;

                    var entity = layer.addEntity({
                        text: name,
                        type: constants.entities.pushpin,
                        location: result.location,
                        image: symbol,
                        anchor: { x: symbolDimensions.width / 2 , y: symbolDimensions.height / 2 },
                        width: symbolDimensions.width,
                        height: symbolDimensions.height,
                        listeners: [{
                            type: constants.listeners.click,
                            action: (e) =>{                                 
                                cosmetic.showActivityHub(e.entity);
                            }
                        }]
                    });	

                    layer.legend.push({
                        groupId: 0,
                        text: name,
                        icon: symbol,
                        iconWidth: symbolDimensions.width,
                        actions: [{
                            id: layerActions.zoom,
                            onClick: () =>{									
                                map.locate({ entity: entity });
                            }
                        }]
                    });

                    if (i === data.length - 1){
                        successToast(translate('success'));
                        map.locate({ entity: entity, zoom: 10 });
                    }                        
                }
            });            
        });        
    }
};