// Third party imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToolbox, faTrainSubway, faStar, faShareFromSquare, faShareNodes, faMobileButton, faCircle, faCircleDot, faPeopleArrows, faFileLines, faCircleCheck, faEnvelope, faEnvelopeOpen, faGear, faX, 
    faEllipsisVertical, faFolderOpen, faMapPin, faRectangleList, faMapLocationDot, faCaretLeft, faObjectGroup, faFolder, faPeopleRoof, faMagnifyingGlass, faDatabase, faLayerGroup, faClockRotateLeft,
    faFloppyDisk, faImage, faFilter, faChevronsDown, faChevronsUp, faTextSlash, faBarsStaggered, faEyeSlash, faEye, faTrash, faInfoCircle, faRectangleHistoryCirclePlus, 
    faLocationCrosshairs, faMap, faFilterList, faMinus, faPerson, faPersonWalking, faHandBackFist, faArrowPointer, faRulerHorizontal, faEraser, faPrint, faMegaphone, faBell, faBicycle, faChevronLeft, faList, faPenToSquare,
    faPlus, faFileChartColumn, faGrid2, faCar, faPieChart, faChartMixed, faChevronRight, faStore, faCircleThreeQuarters, faLocationDot, faCheck, faAngleUp, faAngleDown, faQuestion,faRepeat, faSpinner,faCircleHalfStroke,
    faBraille, faFolderArrowUp, faBinoculars, faSquareRss, faRectangleHistory, faBookBookmark, faShapes, faLasso, faListDropdown, faComments, faArrowUpRightFromSquare, faTriangleExclamation, faCircleXmark,
    faGauge, faRotate, faMobile, faEmptySet, faRotateLeft, faRotateRight, faArrowUp, faArrowDown, faBullseye, faToggleLargeOn, faToggleLargeOff, faRulerTriangle, faBookAtlas, faText, faThumbTack, faCirclePlus,
    faHorizontalRule, faHexagon, faRectangle, faTag, faCaretDown, faDraftingCompass,faArrowTurnDownRight, faArrowTurnDownLeft, faMerge, faRoadBarrier, faRoad, faArrowsRotate, faClockEight, faDrawPolygon,
    faUser, faLock, faPaintBrushFine, faCarMirrors, faUpload, faHeadset, faPaperPlaneTop, faReply, faArrowsUpDown, faArrowsLeftRight, faFence, faDownload, faCopy, faSun, faMoon, faFileExcel, faFilePdf, faSuitcase,
    faBagShopping, faPeopleGroup, faTh, faFireAlt, faBars, faGlobe, faDrawCircle, faBan, faRefresh, faCarSide, faRulerCombined, faFile, faFiles, faArrowRightArrowLeft, faGaugeSimpleHigh, faTrafficLight, faUpDownLeftRight, faScreenUsers,
    faFileImage, faChartTreeMap, faPhotoFilmMusic, faHourglass, faMasksTheater, faBurgerSoda, faGasPump, faHotel, faScaleBalanced, faSuitcaseMedical, faBenchTree, faPlaceOfWorship, faSchool, faCourtSport, faPlane,
    faBus, faTruckRampBox, faWifiSlash, faFloppyDisks, faCirclesOverlap, faObjectUngroup, faDotCircle, faWindowMinimize, faWindowRestore, faBullseyeArrow, faTurnLeft, faChartMixedUpCircleDollar, faStoreSlash, faBoltLightning,
    faMicrochipAi, faExclamation, faOctagonExclamation, faBrainCircuit, faBug, faCircleQuestion, faHouse, faRectanglesMixed, faShield, faShieldHalved, faFolderPlus, faLink, faSwap, faAsterisk, faFilterCircleXmark, faCheckDouble,
    faMessage, faListCheck, faCalculator, faSquareSliders, faLockOpen, faTowerBroadcast, faPersonRunning, faBold, faItalic, faUnderline, faAlignRight, faAlignLeft, faAlignCenter, faBookFont
    } from '@fortawesome/pro-solid-svg-icons'
import { faSquareCheck, faSquare, faClock, faCirclePlus as faCirclePlusPro, faNoteSticky, faMemoCircleInfo, faPuzzle, faUmbrella, faCookieBite, faCoins } from '@fortawesome/pro-regular-svg-icons'
import { faGridRound } from "@fortawesome/pro-duotone-svg-icons";
//import {  } from '@fortawesome/pro-regular-svg-icons'
//import { } from '@fortawesome/pro-light-svg-icons'

const _ = require("lodash");

export var iconHelpers = {
    toCssClassName: (icon) => {
        var style;
    
        if (_.isObject(icon))
        {
            switch(icon.prefix)
            {
                case 'fas':
                default:
                    style = 'fa-solid';
                    break;
                case 'far':
                    style = 'fa-regular';
                    break;
                case 'fal':
                    style = 'fa-light';
                    break;
                case 'fat':
                    style = 'fa-thin';
                    break;
                case 'fad':
                    style = 'fa-duotone';
                    break;
                case 'fab':
                    style = 'fa-brands';
                    break;
            }
        
            style += ' fa-' + icon.iconName;
        }

        return style;
    },
    toSVG: (icon) => {
        if (!_.isObject(icon))
            return;
        
        return `<svg height="1em" viewBox="0 0 ${icon.icon[0]} ${icon.icon[1]}"><path d="${icon.icon[4]}"/></svg>`
    }
};

export function Icon({className, icon, spin, spinSpeed}) {  
    
    var classes = [];

    if (className)
        classes.push(className);

    if (icon === icons.spinner || spin === true)
        classes.push(spinSpeed === 'slow' ? 'app-icon-spin-slow' : 'app-icon-spin');

    return <FontAwesomeIcon icon={icon} className={classes.join(' ')} />
}

export const icons = {
    towerBroadcast: faTowerBroadcast,
    calculator: faCalculator,
    doubleCheck: faCheckDouble,
    filterCircleXmark: faFilterCircleXmark,
    asterisk: faAsterisk,
    swap: faSwap,
    sheildHalved: faShieldHalved,
    sheild: faShield,
    rectanglesMixed: faRectanglesMixed,
    brainCircuit: faBrainCircuit,
    microchipAi: faMicrochipAi,
    boltLightning: faBoltLightning,
    storeSlash: faStoreSlash,
    chartMixedUpCircleDollar: faChartMixedUpCircleDollar,
    windowMinimize: faWindowMinimize,
    windowRestore: faWindowRestore,
    dotCircle: faDotCircle,
    objectUngroup: faObjectUngroup,
    circlesOverlap: faCirclesOverlap,
    wifiSlash: faWifiSlash,
    truckRampBox: faTruckRampBox,
    bus: faBus,
    plane: faPlane,
    courtSport: faCourtSport,
    school: faSchool,
    placeOfWorship: faPlaceOfWorship,
    benchTree: faBenchTree,
    suitcaseMedical: faSuitcaseMedical,
    scaleBalanced: faScaleBalanced,
    hotel: faHotel,
    gasPump: faGasPump,
    burgerSoda: faBurgerSoda,
    masksTheater: faMasksTheater,
    hourglass: faHourglass,
    move: faUpDownLeftRight,
    traffic: faTrafficLight,
    bars: faBars,
    fire: faFireAlt,
    peopleGroup: faPeopleGroup,
    add: faCirclePlus,
    headset: faHeadset,
    drafting: faDraftingCompass,
    tag: faTag,
    rectangle: faRectangle,
    hexagon: faHexagon,
    line: faHorizontalRule,
    link: faLink,
    thumbtack: faThumbTack,
    text: faText,
    bookAtlas: faBookAtlas,
    toggleOn: faToggleLargeOn,
    toggleOff: faToggleLargeOff,
    bullseye: faBullseye,
    rotateLeft: faRotateLeft,
    rotateRight: faRotateRight,
    up: faArrowUp,
    down: faArrowDown,
    empty: faEmptySet,
    mobile: faMobile,
    rotate: faRotate,
    gauge: faGauge,
    circleX: faCircleXmark,
    warning: faTriangleExclamation,
    addScreen: faRectangleHistoryCirclePlus,
    altStar: faStar,
    announce: faMegaphone,
    arrowDown: faAngleDown,
    arrowUp: faAngleUp,
    bell: faBell,
    bicycle: faBicycle,
    binoculars: faBinoculars,
    bookBookmark: faBookBookmark,
    braille: faBraille,
    car: faCar,
    caret: faCaretLeft,
    caretDown: faCaretDown,
    chartMixed: faChartMixed,
    check: faCheck,
    chevronsDown: faChevronsDown,
    chevronsUp: faChevronsUp,
    circleCheck: faCircleCheck,
    circleThreeQuarters: faCircleThreeQuarters,
    clockRotateLeft: faClockRotateLeft,
    comments: faComments,
    crosshair: faLocationCrosshairs,
    database: faDatabase,
    edit: faPenToSquare,
    ellipses: faEllipsisVertical,
    envelope: faEnvelope,
    envelopeOpen: faEnvelopeOpen,
    eraser: faEraser,
    external: faArrowUpRightFromSquare,
    eye: faEye,
    eyeSlash: faEyeSlash,
    fileBarChart: faFileChartColumn,
    fileLines: faFileLines,
    file: faFile,
    files: faFiles,
    filter: faFilter,
    filterList: faFilterList,
    folder: faFolder,
    folderArrowUp: faFolderArrowUp,
    folderOpen: faFolderOpen,
    folderPlus: faFolderPlus,
    gear: faGear,
    grab: faHandBackFist,
    grid: faTh,
    separatedGrid: faGrid2,
    group: faObjectGroup,
    halfCircle: faCircleHalfStroke,
    infoCircle: faInfoCircle,
    lasso: faLasso,
    layers: faLayerGroup,
    leftArrow: faChevronLeft,
    list: faList,
    listDropDown: faListDropdown,
    locationDot: faLocationDot,
    magnifyingGlass: faMagnifyingGlass,
    map: faMap,
    mapLocation: faMapLocationDot,
    minus: faMinus,
    mobileButton: faMobileButton,
    peoplArrow: faPeopleArrows,
    people: faPeopleRoof,
    person: faPerson,
    personWalking: faPersonWalking,
    personRuning: faPersonRunning,
    picture: faImage,
    pieChart: faPieChart,
    pin: faMapPin,
    plus: faPlus,
    pointer: faArrowPointer,
    print: faPrint,
    question: faQuestion,
    circle: faCircle,
    radioSelected: faCircleDot,
    rectangleHistory: faRectangleHistory,
    rectangleList: faRectangleList,
    repeatArrow: faRepeat,
    reshape: faDrawPolygon,
    rightArrow: faChevronRight,
    ruler: faRulerHorizontal,
    rulerTriangle: faRulerTriangle,
    save: faFloppyDisk,
    shapes: faShapes,
    share: faShareFromSquare,
    shareAlt: faShareNodes,
    spinner: faSpinner,
    squareCheck: faSquareCheck,
    squareEmpty: faSquare,
    squareRss: faSquareRss,
    staggeredLines: faBarsStaggered,
    star: faStar,
    store: faStore,
    textSlash: faTextSlash,
    toolbox: faToolbox,
    trainSubway: faTrainSubway,
    trash: faTrash,
    x: faX,
    arrowTurnDownRight: faArrowTurnDownRight,
    arrowTurnDownLeft: faArrowTurnDownLeft,
    arrowMerge: faMerge,
    roadBarrier: faRoadBarrier,
    road: faRoad,
    arrowsRotate: faArrowsRotate,
    clockEight: faClockEight,
    user: faUser, 
    lock: faLock, 
    paintBrushFine: faPaintBrushFine, 
    carMirrors: faCarMirrors,
    upload: faUpload,
    send: faPaperPlaneTop,
    reply: faReply,
    arrowsUpDown: faArrowsUpDown,
    arrowsLeftRight: faArrowsLeftRight,
    fence: faFence,
    download: faDownload,
    copy: faCopy,
    sun: faSun,
    moon: faMoon,
    excel: faFileExcel,
    pdf: faFilePdf,
    suitcase: faSuitcase,
    shoppingBag: faBagShopping,
    globe: faGlobe,
    drawCircle: faDrawCircle,
    ban: faBan,
    refresh: faRefresh,
    carSide: faCarSide,
    rulerCombined: faRulerCombined,
    arrowRightArrowLeft: faArrowRightArrowLeft,
    gaugeHigh: faGaugeSimpleHigh,
    screenUsers: faScreenUsers,
    fileImage: faFileImage,
    legend: faChartTreeMap,
    photoFilmMusic: faPhotoFilmMusic,
    saveAs: faFloppyDisks,
    bullseyeArrow: faBullseyeArrow,
    turnLeft: faTurnLeft,
    exclamation: faExclamation,
    octogonExclamation: faOctagonExclamation,
    triangleExclamation: faTriangleExclamation,
    bug: faBug,
    house: faHouse,
    circleQuestion: faCircleQuestion,
    message: faMessage,
    task: faListCheck,
    gridRound: faGridRound,
    clock: faClock,
    addCircle: faCirclePlusPro,
    stickyNote: faNoteSticky,
    sliders: faSquareSliders,
    unlock: faLockOpen,
    memoCircleInfo: faMemoCircleInfo,
    run: faPersonRunning,
    puzzle: faPuzzle,
    protection: faUmbrella,
    cannibalization: faCookieBite,
    sales: faCoins,
    bold: faBold,
    italic: faItalic, 
    underline: faUnderline,
    alignRight: faAlignRight,
    alignLeft: faAlignLeft, 
    alignCenter: faAlignCenter,
    bookFont: faBookFont
};