import { useRef, useState } from 'react';

// App imports
import { LargePanel } from '../../base/largePanel/largePanel';
import { DataGrid } from '../../base/dataGrid/dataGrid'
import { Button } from '../../base/button/button'
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';
import { helpers } from '../../../utils/helpers';
import { Icon, icons } from '../../base/icon/icon';
import { navigation } from '../navigation/navigation';
import { successToast } from '../../base/toast/toast';
import { projections } from '../../../modules/projections';

const _ = require('lodash');

export function Jobs({ onClose }) {
    
    const grid = useRef(null);
    const [generating, setGenerating] = useState(false);

    const renderViewed = (o) =>{        
        if (o.IsViewed)
            return <Button className='app-job-management-mark-read' theme='simple' icon={icons.circle} 
                tooltip={translate('mark_as_unread')} 
                onClick={() => { 
                    markJobs([o.Id], false);
                }} 
            />
        else
            return <Button className='app-job-management-mark-unread' theme='simple' icon={icons.circle} 
                tooltip={translate('mark_as_read')} 
                onClick={() => { 
                    markJobs([o.Id], true);
                }} 
            />
    };

    const renderStatus = (status)=>{
            switch(status){
                default:
                    return null;
                case constants.jobs.statuses.pending:
                    return <span className='app-job-management-icon-wrapper' title={translate('pending')}>
                        <Icon className='app-job-management-pending app-global-icon-body' icon={icons.hourglass} />
                    </span>
                case constants.jobs.statuses.processing:
                    return <span className='app-job-management-icon-wrapper' title={translate('processing')}>
                        <Icon className='app-job-management-processing app-global-icon-body' icon={icons.rotate} />
                    </span>
                case constants.jobs.statuses.completed:
                    return <span className='app-job-management-icon-wrapper' title={translate('completed')}>
                        <Icon className='app-job-management-completed app-global-icon-body' icon={icons.circleCheck} />
                    </span>
                case constants.jobs.statuses.completedWithErrors:
                    return <span className='app-job-management-icon-wrapper' title={translate('completed_with_errors')}>
                        <Icon className='app-job-management-completed_with_errors app-global-icon-body' icon={icons.warning} />
                    </span>
                case constants.jobs.statuses.failed:
                    return <span className='app-job-management-icon-wrapper' title={translate('failed')}>
                        <Icon className='app-job-management-failed app-global-icon-body' icon={icons.circleX} />
                    </span>
        }
    };

    const renderType = (o)=>{

        const renderIcon = (icon, text) =>{
            return <span className='app-job-management-icon-wrapper' title={text}>
                <Icon className='app-global-icon-body' icon={icon} />
            </span>
        };

        switch(o.Type){
            default:
                return null;
            case constants.jobs.types.demographics:
                return renderIcon(icons.people, translate('bulk_demographics'));
            case constants.jobs.types.closestStore:
                return renderIcon(icons.store, translate('bulk_closest_stores'));
            case constants.jobs.types.mapBook:
                return renderIcon(icons.bookBookmark, translate('map_books'));
            case constants.jobs.types.custom:
                switch (o.CustomType){
                    default:
                        return null;
                    case constants.jobs.types.customTypes.managementReport:
                        return renderIcon(icons.people, translate('management_report'));
                    case constants.jobs.types.customTypes.voidAnalysis:
                        return renderIcon(icons.empty, translate('void_analysis'));
                    case constants.jobs.types.customTypes.mobility:
                        return renderIcon(icons.mobile, translate('mobility_report'));
                    case constants.jobs.types.customTypes.demographicReport:
                        return renderIcon(icons.fileLines, translate('demographic_report'));
                    case constants.jobs.types.customTypes.importExport:
                        return renderIcon(icons.folderArrowUp, translate('import_export'));                    
                    case constants.jobs.types.customTypes.submitOfflinePackage:
                        return renderIcon(icons.wifiSlash, translate('submit_offline_package'));
                    case constants.jobs.types.customTypes.analyticsProjection:
                        return renderIcon(icons.chartMixed, translate('analytics_projection'));
                    case constants.jobs.types.customTypes.createOfflinePackage:
                        return renderIcon(icons.wifiSlash, translate('create_offline_package'));
                    case constants.jobs.types.customTypes.trip2Trade:
                        return renderIcon(icons.suitcase, translate('trip_2_trade'));
                    case constants.jobs.types.customTypes.ciDashboardReport:
                        return renderIcon(icons.pieChart, translate('ci_dashboard_report'));
                    case constants.jobs.types.customTypes.bulkAnalyticsProjection:
                        return renderIcon(icons.chartMixed, translate('bulk_analytics_projection'));
                    case constants.jobs.types.customTypes.ciCannibalization:
                        return renderIcon(icons.circleThreeQuarters, translate('ci_cannibalization'));
                    case constants.jobs.types.customTypes.externalAnalyticsProjection:
                        return renderIcon(icons.external, translate('external_analytics_projection'));
                    case constants.jobs.types.customTypes.ciCustomChannels:
                            return renderIcon(icons.store, translate('ci_custom_channels'));
                    case constants.jobs.types.customTypes.analyticsRelocation:
                        return renderIcon(icons.truckRampBox, translate('analytics_relocation'));
                    case constants.jobs.types.customTypes.ciKeyInsightsReport:
                        return renderIcon(icons.binoculars, translate('ci_key_insights_report'));
                }
        }
    };
    
    const renderActions = (o)=>{
        var actions = [];

        const defaultDownloadButton = ({ action }) =>{
            actions.push(<Button 
                theme='simple' 
                icon={icons.download} 
                tooltip={translate('download')} 
                onClick={() => { 
                    action();
                }} 
            />);
        }

        const defaultDownloadAction = () =>{
            if (_.isString(o.ResultFileId) && o.ResultFileId.length > 0)
                defaultDownloadButton({ action: () => { helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: o.ResultFileId })); }});
        };

        switch(o.Type){
            default:
                defaultDownloadAction();
                break;
            case constants.jobs.types.mapBook:
                if (_.isString(o.ResultFileId) && o.ResultFileId.length > 0)
                    defaultDownloadButton({ action: () => { helpers.navigateToUrl(legacyEndpoints.handlers.getReportUrl({id: o.ResultFileId, type: 'MapBook', output: 'PDF' })); }});                    
                break;
            case constants.jobs.types.custom:
                switch (o.CustomType){
                    default:
                        defaultDownloadAction();
                        break;
                    case constants.jobs.types.customTypes.externalAnalyticsProjection:
                        actions.push(<Button 
                            theme='simple' 
                            icon={icons.folderOpen} 
                            tooltip={translate('open')} 
                            onClick={async () => { 
                                setGenerating(true);
                                await projections.openJobResults({ id: o.Id });
                                setGenerating(false);
                            }} 
                        />);
                        break;
                }
                break;
        }

        if (o.Status !== constants.jobs.statuses.pending && o.Status !== constants.jobs.statuses.processing)
            actions.push(<ConfirmButton theme='simple' icon={icons.trash} 
                tooltip={translate('delete')} 
                clearText={translate('delete')}
                onConfirm={() => { deleteJob(o); }} 
            />);

        if (actions.length === 0)
            actions.push(<Icon icon={icons.ban} />)

        return <table className='app-job-management-action-table'>
            <tbody>
                <tr>
                {
                    actions.map((action ,i) => { 
                        return <td key={i}>
                            {action}
                        </td>
                    })
                }
                </tr>
            </tbody>
        </table>
    }

    const renderName = (o) =>{
        return <div className='app-job-management-name-wrapper'>{o.Name}</div>
    };    

    const markAllRead = () => {

        setGenerating(true);
        legacyEndpoints.service({
            name: 'GetNewNotificationsForSession',
            parameters: {
                SessionKey: legacyEndpoints.authenticationToken.TokenInformation.SessionKey
            },
            success: (result) => {                
                markJobs(
                    result.Jobs.filter(x => (x.Status === constants.jobs.statuses.completed || x.Status === constants.jobs.statuses.completedWithErrors || x.Status === constants.jobs.statuses.failed) && !x.IsViewed).map(x => { return x.Id }),
                    true
                );
            }
        });
    };

    const refresh = () =>{
        grid.current.instance.refresh();
    };

    const markJobs = async (ids, viewed) =>{

        setGenerating(true);
        await legacyEndpoints.service({
            name: 'MarkJobs',
            parameters: {
                ids: ids,
                isViewed: viewed
            }
        });

        navigation.updateJobs();
        setGenerating(false);

    };

    const deleteAllJobs = async () =>{
        setGenerating(true);

        await legacyEndpoints.service({
            name: 'DeleteAllCompletedBulkProcessingJobs',
            success: () => {
                successToast(translate('success'));
            }
        });

        setGenerating(false);
    };

    const deleteJob = async (o) =>{

        setGenerating(true);

        await legacyEndpoints.service({
            name: 'DeleteBulkProcessingJob',
            parameters: {
                Id: o.Id,
                Status: o.Status
            },
            success: () => {
                successToast(translate('success'));
            }
        });

        setGenerating(false);

    };

    return  <LargePanel className='app-jobs-panel' text={translate("jobs")} loaded={true} generating={generating} onClose={() =>{ onClose(); }}>
        <div className='app-job-actions'>            
            <Button className='app-job-management-action' theme='primary' size='large' text={translate('refresh')} icon={icons.rotate} onClick={() => { refresh() }} />
            <Button className='app-job-management-action' theme='primary' size='large' text={translate('mark_all_read')} icon={icons.eye} onClick={() => { markAllRead() }} />
            <ConfirmButton className='app-job-management-action' theme='primary' size='large' text={translate('delete_all')} icon={icons.trash} 
                tooltip={translate('delete_all')} 
                clearText={translate('confirm')}
                onConfirm={() => { 
                    deleteAllJobs(); 
                }} 
            />
        </div>
        <DataGrid
            ref={grid}
            className='app-job-management'
            activeRefreshOnly={true}
            remoteOperations={ {groupPaging: true, remoteOperations: true} }
            key='Id'
            defaultPageSize={10}
            columns={[
                { name: 'Viewed', alignment:'center', caption: translate('viewed'), width: '80px', onRender: (o) => { return renderViewed(o); }},
                { name: 'Status', alignment:'center', caption: translate('status'), width: '80px', onRender: (o) => { return renderStatus(o.Status); }},
                { name: 'Type', alignment:'center', caption: translate('type'), width: '80px', onRender: (o) => { return renderType(o); }},
                { name: 'Name', caption: translate('name'), onRender: (o) => { return renderName(o); } },
                { name: 'ExpirationDate', caption: translate('expires'), width: '200px', onRender: (o) => { return helpers.formatDate({ date: o.ExpirationDate }); }},
                { name: 'Actions', alignment:'center', caption: translate('actions'), width: '100px', onRender: (o) => { return renderActions(o); }}
            ]}
            onLoad={async (o)=>{         
                var data = await legacyEndpoints.service({
                    name: 'GetFilteredBulkProcessingJobs',
                    parameters: {
                        Columns: 'Status,Type,Name,ExpirationDate,Download',
                        Page: o.skip,
                        ResultCount: o.take,
                        Filter: '',
                        SortColumn: 3,
                        SortDirection: 'desc'
                    }
                });
                
                return {
                    data: data.jobs,
                    totalCount: data.TotalRecords
                };
            }}
        />
    </LargePanel>
}