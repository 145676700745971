import { legacyEndpoints } from '../services/legacyEndpoints';
import { helpers } from '../utils/helpers';
import { constants } from '../utils/constants';
import { map } from '../components/app/map/map';
import { workBench } from '../components/base/workBench/workBench';
import { Projections } from '../components/app/projections/projections';
import { translate } from '../utils/translation';
import { DataGrid } from '../components/base/dataGrid/dataGrid';
import { Loader } from '../components/base/loader/loader';
import { navigation } from '../components/app/navigation/navigation';
import { Icon, icons } from '../components/base/icon/icon';
import { Button } from '../components/base/button/button';
import { errorToast, successToast } from '../components/base/toast/toast';
import { mapWindow } from '../components/app/map/mapWindow/mapWindow';
import { ProjectionEditForm } from '../components/app/projections/projectionEditForm';
import { cosmetic } from './cosmetic';
import { layerActions } from '../components/app/layers/layer/layer';
import { layers } from '../components/app/layers/layers';
import { inputBoxTypes, TextBox } from '../components/base/textBox/textBox';
import { userPreferences } from '../components/app/app';

const _ = require("lodash");

export const projections = {
    getIcon: (projection) => {

        if (projection.hasRecaptureModel)
            return icons.storeSlash;
        else{
            switch (projection.type)
            {
                case constants.projections.interactive:
                    return icons.chartMixed;
                case constants.projections.oneClick:
                    return icons.boltLightning;
                case constants.projections.relocation:
                    return icons.truckRampBox;
            }
        }

        return icons.chartMixed;
    },
    generate: async (o) =>{
        
        var result = true;

        if (o.type === constants.projections.interactive && o.recapture !== true)
            result = workBench.setContent({ 
                title: o.name,
                content: <Loader />,
                height: '700px',
                maxHeight: '1000px',
                startMinimized: true,
                dragEnabled: false
            });

        if (!result)
            return null;
    
        var projectionResults = await legacyEndpoints.service({
            name: 'GenerateAnalyticsProjection',
            parameters: {
                projectionId: o.id,
                customQueryId: o.customQueryId,
                pointId: o.pointId,
                latitude: o.location.lat, 
                longitude: o.location.lon, 
                modelJobId:_.isString(o.modelJobId) ? o.modelJobId : helpers.emptyGuid(),                
                forceOneClick: o.type === constants.projections.oneClick && o.recapture !== true,
                jobName: o.jobName,
                updateForm: o.updateForm ?? null
            }
        });

        if (_.isObject(o.updateForm))
            projectionResults = projections.updateSiteCharacteristics({ updateForm: o.updateForm, projectionResults: projectionResults });

        switch(o.type)
        {
            case constants.projections.oneClick:

                if (o.recapture)
                    await projections.generateRecapture({ projectionResults: projectionResults });
                else
                    navigation.updateJobs();

                break;
            case constants.projections.interactive:
                projections.openInteractiveGrid({
                    name: o.name,
                    projectionResults: projectionResults,
                    editForm: o.editForm,
                    jobName: o.jobName,
                    orginalParameters: o
                });
                break;
        }

        return projectionResults;
    },
    openJobResults: async ({ id }) =>{        

        var data = await legacyEndpoints.service({
            name: 'GetAnalyticsProjectionJobResult',
            parameters: {
                JobId: id
            }
        });

        if (_.isString(data.reportId))
            helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: data.reportId }));            
        else if (_.isObject(data))
            projections.openInteractiveGrid({
                name: data.projectionResults.name,
                projectionResults: data.projectionResults,
                //editForm: o.editForm,
                jobName: "",
                //orginalParameters: o
            });
    },
    openInteractiveGrid: async ({ name, projectionResults, jobName, scenario, orginalParameters }) =>{

        var processedResults = await projections.processProjectionResults({ projectionResults: projectionResults, orginalParameters: orginalParameters });
        
        const onClose = () =>{
            map.enableEntityClicking();
            map.layers.find(x => x.type === constants.layers.types.temporary).clear();
            map.layers.find(x => x.id === processedResults.projectionResults.id)?.dispose();
            processedResults.projectionResults.modelResults.forEach(modelResult =>{
                var modelElements = processedResults.projectionElements.find(x => x.id === modelResult.model.id);
                modelElements.layers.points?.dispose();
                modelElements.layers.overlay?.dispose();
                modelElements.layers.tradeArea?.dispose();
                modelElements.layers.polygon?.dispose();
            });
        };

        workBench.close();

        workBench.setContent({ 
            title: name,
            content: <Projections 
                projectionResults={processedResults.projectionResults}
                projectionElements={processedResults.projectionElements}
                jobName={jobName} 
                scenario={scenario}
                onClose={()=>{onClose();}}
                orginalParameters={orginalParameters}
            />,
            height: '700px',
            maxHeight: '1000px',
            startMinimized: true,
            onClose: () =>{
                onClose();                
            }
        });
    },
    generateProposedLocation: async (o) => {
        
        var result = await legacyEndpoints.service({
            name: "SubmitAnalyticsProjectionProposedSiteForm",
            parameters: {
                projectionId: o.id,
                updateForm: o.updateForm
            }
        });

        var projectionResults = await projections.generate({
            id: o.id,
            name: o.name,
            type: o.type,
            customQueryId: result[0].Key,
            pointId: result[0].Value,
            location: o.location,
            jobName: o.jobName
        });

        navigation.updateJobs();

        return {
            pointResult: result[0],
            projectionResults: projectionResults
        };

    },
    generateAdhoc: async(o) =>{
        var result = await legacyEndpoints.service({
            name: 'GenerateAdhocAnalyticsProjection',
            parameters: {
                projectionId: o.id,
                lat: o.location.lat, 
                lon: o.location.lon,
                updateForm: o.updateForm,
                jobName: o.jobName
            }
        });
        
        navigation.updateJobs();

        return result;
    },
    generateRelocation: async(o) => {
        await legacyEndpoints.service({
            name: 'SubmitAnalyticsRelocationJob',
            parameters: {
                projectionId: o.id,
                customQueryId: o.customQueryId,
                pointId: o.pointId,
                latitude: o.location.lat, 
                longitude: o.location.lon,
                relocationPoint: o.relocation,
                editForm: o.updateForm,
                jobName: o.jobName
            }
        });
        
        navigation.updateJobs();
    },
    generateRecapture: async({ projectionResults }) =>{

        var reportId = await legacyEndpoints.service({
            name: 'GenerateAnalyticsRecaptureReport',
            parameters: {
                projectionResults: projectionResults,
                filters: [],
                sorts: []
            }
        });

        helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: reportId, isOneTime: true }));

    },
    updateSiteCharacteristics: ({ updateForm, projectionResults }) =>{
        updateForm.UpdateSections.forEach(section =>{
            section.UpdateFields.forEach(field =>{

                var keyword = projectionResults.layer.keywords.find(x => x.editFormColumnId === field.FieldId);

                if (!_.isObject(keyword))
                    return;

                var data = projectionResults.data.find(x => x.id === keyword.id);

                if (!_.isObject(data))
                    return;

                data.columnValue = field.Value;
                data.modified = true;
            });
        });

        return projectionResults;
    },
    execute: async ({ name, projectionResults, callback})=>{
        return await legacyEndpoints.service({
            name: 'SubmitAnalyticsProjectionJob',
            parameters: {
                name: name,
                projectionResults: projectionResults,
                scenarioId: helpers.emptyGuid()
            },
            success: () =>{
                callback();
                navigation.updateJobs();
            }
        });
    },
    startRelocation: ({ projection, entity }) =>{
        
        var temporaryLayer = map.layers.find(x => x.type === constants.layers.types.temporary);        

        var relocationRing = null;
        if (projection.relocationRingSize > 0 && projection.relocationRingSize > 0)
        {
            map.center = entity.location;
            map.zoom = 15;
            relocationRing = temporaryLayer.addEntity({
                type: constants.entities.polygon,
                paths: helpers.createCircle({
                    location: entity.location,
                    radius: projection.relocationRingSize,
                    lengthMeasurement: constants.lengthMeasurements.miles
                }),
                fillColor: { r: 0, g: 0, b: 0, a: 0},
                strokeColor: { r: 0, g: 0, b: 0, a: 1 },
                strokeWidth: 1
            });

        }
            
        map.setCursor({ cursor: 'crosshair' });

        var id = map.addListener({
            type: constants.listeners.click,
            action: (e) => {

                if (projection.enforceRelocationRing && projection.relocationRingSize > 0 && !map.polygonContainsPoint(relocationRing.paths, e.location))
                {
                    errorToast(translate('location_must_be_within_ring'));
                    return;
                }

                cosmetic.createGeocodePushpin({ location: e.location, temporary: true });

                mapWindow.show({
                    title: translate('new_location'),
                    content: <ProjectionEditForm projection={projection} entity={entity} relocation={e.location} onGenerate={()=>{
                        mapWindow.hide();
                        successToast(translate('successfully_submitted'));
                    }} />,
                    resizable: true
                });
            }
        });

        map.showBanner({
            title: projection.name,
            content: <div>
                {`${projection.enforceRelocationRing && projection.relocationRingSize > 0 ?  translate('relocate_site_instruction_1') : translate('relocate_site_instruction_1_not_required')} ${translate('relocate_site_instruction_2')} ${translate('relocate_site_instruction_3')}`}                
            </div>,
            onClose: ()=>{
                temporaryLayer.clear();
                map.removeListener(id);
                map.setCursor({ cursor: '' });
                mapWindow.hide();
            }
        });
    },    
    processProjectionResults: async ({ projectionResults, orginalParameters }) =>{

        const projectionElements = [];
        const promises = [];

        var projectionLayer = map.addLayer({
            id: projectionResults.id,
            group: constants.layers.groups.other,
            type: constants.layers.types.other
        });

        var anchor = { x: 16, y: 16 };
        var width = 32;
        var height = 32;

        if (_.isObject(orginalParameters) && _.isObject(orginalParameters.entity))
        {
            anchor = orginalParameters.entity.anchor;
            width = orginalParameters.entity.width;
            height = orginalParameters.entity.height;
        }
        else {
        
            var entityLayer = map.layers.find(layer => layer.id.toString().toLowerCase() === projectionResults.layer.customQueryId.toString().toLowerCase());
            if (_.isObject(entityLayer))
            {
                var entity = entityLayer.entities.find(entity => entity.id.toString().toLowerCase() === projectionResults.pointId.toString().toLowerCase());
                if (_.isObject(entity))
                {
                    anchor = entity.anchor;
                    width = entity.width;
                    height = entity.height;
                }
            }
        }

        projectionLayer.addEntity({ 
            type: constants.entities.selectedPoint,
            location: projectionResults.location,
            anchor: anchor,
            width: width,
            height: height,
            clickable: true,
            listeners: [{
                type: constants.listeners.click,
                action: (e) =>{

                    var entityLayer = map.layers.find(layer => layer.id.toString().toLowerCase() === projectionResults.layer.customQueryId.toString().toLowerCase());

                    if (!_.isObject(entityLayer))
                        return;
                            
                    var entity = entityLayer.entities.find(entity => entity.id.toString().toLowerCase() === projectionResults.pointId.toString().toLowerCase());

                    if (!_.isObject(entity))
                        return;

                    e.entity = entity;
                    entity.click(e); 
                }
            }]
        });
        
        projectionResults.modelResults = _.sortBy(projectionResults.modelResults, [({model}) => { return model.type; }, ({model}) => { return model.name; }]);
        projectionResults.modelResults.forEach(modelResult => {
            promises.push(
                new Promise(async (resolve) => {

                    modelResult.sisterSiteData = _.sortBy(modelResult.sisterSiteData, [({name}) => { return name; }]);
                    modelResult.competitionData = _.sortBy(modelResult.competitionData, [({name}) => { return name; }]);

                    var modelElements = {
                        id: modelResult.model.id,
                        layers: {},
                        selectedViewId: null,
                        views: [],
                        selectedDetailId: null,
                        details: [],
                        overlayEnabled: false,
                        sumEnabled: false,
                        allowThematic: modelResult.model.output.filter(output => output.theme.hasTheme).length > 0,
                        boundaries: null,
                        loadedOnce: false,
                        onGeographySelect: () => {},
                        onPointSelect: ({ viewId, id, refreshGrid = true, forceSelectValue }) =>{

                            const toggleForecastPointSite = ({ view, value }) =>{
                                
                                var site = view.layer.sites.find(x => x.id === id);                            
                                if (_.isObject(site))
                                    site.selected = value;
                                
                                var data = view.layer.data.find(x => x.id === id);
                                if (_.isObject(data))
                                    data.selected = value;                            
                                
                                if (refreshGrid)
                                    view.grid.ref.instance.refresh();
                            };

                            var view = modelElements.views.find(view => view.id === viewId);

                            var selected = forceSelectValue;

                            if (!_.isBoolean(forceSelectValue))
                                switch (view.type)
                                {
                                    case constants.projections.views.disaggregateForecastPoint:
                                        selected = !view.layer.data.find(x => x.id === id).selected;
                                        break;
                                    case constants.projections.views.disaggregateRecapturePoint:
                                    case constants.projections.views.disaggregateCannibalizationPoint:                                    
                                        selected = !view.layer.sites.find(x => x.id === id).selected;
                                        break;
                                }

                            if (view.linked)
                                projectionElements.forEach(modelElements =>{
                                    modelElements.views.filter(x => x.type === constants.projections.views.disaggregateForecastPoint || x.type === constants.projections.views.disaggregateCannibalizationPoint).forEach(view =>{
                                        toggleForecastPointSite({
                                            view: view,
                                            value: selected
                                        });
                                    });
                                });
                            else
                                toggleForecastPointSite({
                                    view: view,
                                    value: selected
                                });
                        }
                    };

                    switch(modelResult.model.type){
                        case constants.projections.models.disaggregateForecast:

                            modelElements.selectedDetailId = modelResult.model.autoSelectDemographicReport ?  modelResult.model.demographicReportId : -1;

                            var forecastTradeAreaViewId = helpers.newGuid();

                            modelElements.views = [{ 
                                id: forecastTradeAreaViewId, 
                                name: translate('trade_area'), 
                                type: constants.projections.views.disaggregateForecastTradeArea,
                                grid: await projections.createDisaggregateForecastTradeAreaGrid({ id: forecastTradeAreaViewId, modelResult: modelResult, modelElements: modelElements })
                            }];

                            const pointPromises = [];
                            
                            [...modelResult.sisterSiteData, ...modelResult.competitionData].filter(layer => layer.editFormId !== helpers.emptyGuid()).forEach(layer =>{

                                pointPromises.push(
                                    new Promise(async (resolve) => {

                                        var viewId = helpers.newGuid();

                                        modelElements.views.push({ 
                                            id: viewId, 
                                            layer: layer,
                                            name: layer.name, 
                                            linked: true,
                                            type: constants.projections.views.disaggregateForecastPoint,
                                            grid: await projections.createDisaggregateForecastPointGrid({ viewId: viewId, modelResult: modelResult, modelElements: modelElements, layer: layer, editForm: projectionResults.bulkEditFormColumns.find(x => x.Key === layer.editFormId).Value })
                                        });

                                        resolve();
                                    })
                                );
                            });

                            modelElements.layers = await projections.populateDisaggregateForecastLayers({ 
                                modelResult: modelResult,
                                modelElements: modelElements
                            });

                            await Promise.all(pointPromises);

                            modelElements.details = [
                                { id: -1, name: translate('none')},
                                { id: modelResult.model.demographicReportId, name: translate('model_report')}, 
                                ...modelResult.demographicReports.filter(x => parseInt(x.ID) !== modelResult.model.demographicReportId).map(({ID, Name}) => { return { 
                                    id: parseInt(ID), 
                                    name: Name
                                }})
                            ];

                            modelElements.onGeographySelect = (selected) =>{

                                modelResult.geographyData = selected;
                                projections.populateDisaggregateForecastLayers({ 
                                    modelResult: modelResult,
                                    modelElements: modelElements
                                });
    
                                var geographyGrid = modelElements.views.find(x => x.type === constants.projections.views.disaggregateForecastTradeArea)?.grid.ref;
    
                                if (_.isObject(geographyGrid))
                                    geographyGrid.instance.refresh();
                            };

                            modelElements.views.filter(view => view.type === constants.projections.views.disaggregateForecastPoint).forEach(view =>{                                
                                view.layer.sites.filter(site => site.temporary === true).forEach(site =>{
                                    projections.createTempPoint({
                                        id: site.id,
                                        location: site.location,
                                        layer: modelElements.layers.points,
                                        view: view
                                    });
                                });
                            });

                            break;
                        case constants.projections.models.disaggregateRecapture:
                        case constants.projections.models.disaggregateCannibalization:
                        case constants.projections.models.disaggregateDeliveryCannibalization:

                            if (modelResult.model.type !== constants.projections.models.disaggregateRecapture)
                                modelResult.sisterSiteData[0].sites.forEach(site =>{
                                    site.selected = site.transfer !== 0;
                                });                            

                            var cannibalizationViewId = helpers.newGuid();

                            modelElements.views = [{
                                id: cannibalizationViewId,
                                layer: modelResult.sisterSiteData[0],
                                name:  modelResult.sisterSiteData[0].name,
                                linked: modelResult.model.type !== constants.projections.models.disaggregateRecapture,
                                type: modelResult.model.type === constants.projections.models.disaggregateRecapture ? constants.projections.views.disaggregateRecapturePoint : constants.projections.views.disaggregateCannibalizationPoint,
                                grid: await projections.createDisaggregateCannibalizationGrid({ viewId: cannibalizationViewId, projectionResults: projectionResults, modelResult: modelResult, modelElements: modelElements, layer: modelResult.sisterSiteData[0], editForm: projectionResults.bulkEditFormColumns.find(x => x.Key === modelResult.sisterSiteData[0].editFormId)?.Value })
                            }];

                            modelElements.details = [
                                { id: helpers.emptyGuid(), name: translate('none')},
                                ...modelResult.sisterSiteData[0].sites.map(site => { return { 
                                    id: site.id, 
                                    name: site.id 
                                }})
                            ];

                            modelElements.layers.tradeArea = map.addLayer({
                                group: constants.layers.groups.other,
                                type: constants.layers.types.other,
                                visible: false
                            });

                            break;
                    }

                    modelElements.selectedViewId = _.isNull(modelElements.selectedViewId) ? modelElements.views[0].id : modelElements.selectedViewId;
                    modelElements.selectedDetailId = _.isNull(modelElements.selectedDetailId) ? modelElements.details[0].id : modelElements.selectedDetailId;

                    projectionElements.push(modelElements);

                    resolve();
                })
            );         
        });
            
        await Promise.all(promises);

        return {
            projectionResults: projectionResults,
            projectionElements: projectionElements
        };
    },    
    changeDetail: async ({ modelResult, modelElements }) =>{

        switch(modelResult.model.type){
            case constants.projections.models.disaggregateForecast:

                var forecastObject = modelElements.views.find(x => x.type === constants.projections.views.disaggregateForecastTradeArea).grid;
                var grid = forecastObject.ref.instance;

                var data = await legacyEndpoints.service({
                    name: 'GetDisaggregateForecastTradeAreaData',
                    parameters: {
                        modelResult: {
                            model: modelResult.model,
                            geographyVintage: modelResult.geographyVintage,
                            geographyData: modelResult.geographyData 
                        },
                        reportId: modelElements.selectedDetailId
                    }
                });

                var deletedColumn = false;

                for (var i = 0; i === 0 || deletedColumn === true; i++)
                    grid.getVisibleColumns().forEach((column) => {     
                        deletedColumn = false;
                        switch(column.name)
                        {
                            default:
                                grid.deleteColumn(column.dataField);
                                deletedColumn = true;
                                break;
                            case constants.projections.disaggregateForecastTradeAreaColumns.remove:
                            case constants.projections.disaggregateForecastTradeAreaColumns.zoom:
                            case constants.projections.disaggregateForecastTradeAreaColumns.geography:
                            case constants.projections.disaggregateForecastTradeAreaColumns.distanceMiles:
                            case constants.projections.disaggregateForecastTradeAreaColumns.distanceKilometers:
                                return;
                        }
                    });
                
                data.columns.map((column) => {
                    grid.addColumn({                        
                        dataField: column.id,
                        caption: column.friendlyName,
                        cssClass: 'app-data-grid-column',                        
                        allowSorting: true,
                        columns:[{
                            name: `${column.id}_sum`,
                            dataField: `${column.id}_sum`,
                            cssClass: 'app-data-grid-column-sum',                            
                            caption: '-',
                            allowSorting: false,
                            allowFiltering: true, 
                            filterOperations: [ "=", "<>", "<", ">", "<=", ">=", "between" ],
                            dataType: "number",
                            calculateCellValue: (cellData) => {

                                if (!_.isObject(cellData[column.id]))
                                    return;

                                return cellData[column.id].value;
                            }
                        }]
                    });
                });

                data.shapes = _.sortBy(data.shapes.map(shape => {

                    var modelShape = modelResult.geographyData.find(x => x.Key === shape.id).Value;

                    var distanceInMiles = modelShape.driveTimeValue.toFixed(2);
                    var distanceInKilometers = helpers.convertLength(modelShape.driveTimeValue, constants.lengthMeasurements.miles, constants.lengthMeasurements.kilometers).toFixed(2);

                    shape.results = [
                        { id: constants.projections.disaggregateForecastTradeAreaColumns.remove, value: 0, formattedValue: 0 },
                        { id: constants.projections.disaggregateForecastTradeAreaColumns.zoom, value: 0, formattedValue: 0 },
                        { id: constants.projections.disaggregateForecastTradeAreaColumns.geography, value: shape.id, formattedValue: shape.id },
                        { id: constants.projections.disaggregateForecastTradeAreaColumns.distanceMiles, value: parseFloat(distanceInMiles), format: 'N2', formattedValue: `${distanceInMiles} mi` },
                        { id: constants.projections.disaggregateForecastTradeAreaColumns.distanceKilometers, value: parseFloat(distanceInKilometers), format: 'N2', formattedValue: `${distanceInKilometers} km` },
                        ...shape.results
                    ];

                    return shape;
                }), ({results}) => {                    
                    return results[3].value; 
                });

                forecastObject.data = data;

                grid.refresh();

                break;
            case constants.projections.models.disaggregateRecapture:
            case constants.projections.models.disaggregateCannibalization:
            case constants.projections.models.disaggregateDeliveryCannibalization:

                modelElements.boundaries = await projections.getGeographyBoundaries({ modelResult: modelResult });

                modelElements.layers.tradeArea.clear();
                modelElements.layers.tradeArea.visible = false;

                switch(modelElements.selectedDetailId)
                {
                    default:
                        
                        var store = modelResult.sisterSiteData[0].sites.find(site => site.id === modelElements.selectedDetailId);

                        if (!_.isObject(store))
                            return;

                        modelElements.layers.tradeArea.addEntity({ 
                            type: constants.entities.focusedPoint,
                            location: store.location,
                            anchor: { x: 16, y: 16},
                            width: 32,
                            height: 32
                        });

                        store.cannibalization.filter(cannibalization => cannibalization.Value.transfer !== 0).forEach(cannibalization => {
                            
                            var cannibalizationData = cannibalization.Value;
                            var geographyId = cannibalization.Key;
                            var geography = modelElements.boundaries.find(x => x.Key === cannibalization.Key);
                            
                            if (!_.isObject(geography))
                                return;

                            var parsedWkt = helpers.parseWkt(geography.Value);

                            modelElements.layers.tradeArea.addEntity({
                                id: geographyId,
                                type: constants.entities.polygon,
                                paths: parsedWkt,
                                fillColor: constants.polygons.selected.fillColor,
                                strokeColor: constants.polygons.selected.strokeColor,
                                strokeWidth: constants.polygons.selected.strokeWidth,
                                listeners: [{
                                    type: constants.listeners.click,
                                    action: (e) =>{
                                        projections.renderSelection({ 
                                        title: geographyId,
                                        wktList: [parsedWkt],
                                        location: map.latLonToXY(e.location),
                                        actions: () =>{
                                            return [
                                                [
                                                    { label: translate('miles_from_site'), formattedValue: `${cannibalizationData.distance.toFixed(2)} mi`  }, 
                                                    { label: translate('kilometers_from_site'), formattedValue: `${helpers.convertLength(cannibalizationData.distance, constants.lengthMeasurements.miles, constants.lengthMeasurements.kilometers).toFixed(2)} km` },
                                                    { label: translate('volume'), formattedValue: helpers.formatNumber(cannibalizationData.totalSales.toFixed(2)) },
                                                    { label: translate('forecast'), formattedValue: helpers.formatNumber(cannibalizationData.forecast.toFixed(2)) },
                                                    { label: translate('impact'), formattedValue:  `${(cannibalizationData.transfer * 100).toFixed(2)}%` },
                                                    { label: translate('transferred'), formattedValue: helpers.formatNumber(cannibalizationData.cannibalizedSales.toFixed(2)) }
                                                ].map((variable, i) =>{
                                                return <div key={i}>{`${variable.label}: ${variable.formattedValue}`}</div>
                                            })];
                                        }
                                    });
                                    }
                                }]
                            });
                        });

                        modelElements.layers.tradeArea.visible = true;

                        break;
                }

                break;
        }

    },
    getGeographyBoundaries: async ({ modelResult }) =>{
        return await legacyEndpoints.service({
            name: 'GetGeographyVintageShapeBoundaries',
            parameters: {
                vintageId: modelResult.geographyVintage.Id, 
                geoLevelId: modelResult.geographyVintage.GeoLevels[0].Id, 
                geographyIds: modelResult.geographyData.map(geography => { return geography.Key }), 
                outputType: constants.encodedString.wkt
            }
        });
    },
    populateDisaggregateForecastLayers: async ({ modelResult, modelElements }) =>{

        modelElements.layers =         
        _.isObject(modelElements.layers.tradeArea) && _.isObject(modelElements.layers.overlay) && _.isObject(modelElements.layers.points) 
            ? modelElements.layers 
            : {
                points: map.addLayer({
                    group: constants.layers.groups.other,
                    type: constants.layers.types.other
                }),
                overlay: map.addLayer({
                    group: constants.layers.groups.other,
                    type: constants.layers.types.modelTradeArea,
                    visible: false
                }),
                tradeArea: map.addLayer({
                    text: `${modelResult.model.name}`,
                    group: constants.layers.groups.tradeArea,
                    type: constants.layers.types.modelTradeArea,
                    actions: [{
                        id: layerActions.visible,
                        getActive: () => { return modelElements.layers.tradeArea.visible; },
                        getHalfActive: () => { return modelElements.layers.tradeArea.oppositeVisibilityEntities.length > 0; },
                        onClick: () =>{
                            modelElements.layers.tradeArea.visible = !modelElements.layers.tradeArea.visible;
                        }
                    },
                    {
                        id: layerActions.zoom,                                    
                        onClick: () =>{

                            var selectedGeography = modelResult.geographyData.find(x => x.Value.selected);

                            if (!_.isObject(selectedGeography))
                                selectedGeography = modelResult.geographyData[0];

                            map.locate({ location: selectedGeography.Value.shape.polygon.centroid });
                        }
                    }],
                    onChange: ()=>{
                        layers.update();
                    },
                    metaData: {
                        legendIcon: icons.chartMixed
                    }
                })
            };        

        modelElements.layers.overlay.clear();
        modelElements.layers.tradeArea.clear();

        modelElements.layers.tradeArea.legend = [{
            text: translate('trade_area'),
            color: constants.polygons.selected.fillColor
        }];

        layers.update();

        modelElements.boundaries = _.isArray(modelElements.boundaries) ? modelElements.boundaries : await projections.getGeographyBoundaries({ modelResult: modelResult });        

        modelResult.geographyData.forEach(({ Key, Value}) =>{

            var geographyId = Key;
            var geography = Value;
            var boundary = modelElements.boundaries.find(x => x.Key === geographyId);

            if (!_.isObject(boundary))
                return;

            var parsedWkt = helpers.parseWkt(boundary.Value);

            const select = (e) =>{
                
                var isSelected = modelResult.geographyData.find(x => x.Key === geographyId).Value.selected;

                projections.renderSelection({ 
                    title: Key,
                    wktList: [parsedWkt],
                    location: map.latLonToXY(e.location),
                    onSelect: modelElements.onGeographySelect,                    
                    actions: (applySelection) =>{
                        return [
                            <Button 
                                className='app-projections-geography-toggle' 
                                theme='action' 
                                icon={isSelected ? icons.minus : icons.add} 
                                text={isSelected ? translate('remove') : translate('add')} 
                                onClick={()=>{applySelection(isSelected ? projections.removeSelection([{ Key: geographyId, Value: geography }], modelResult.geographyData) : projections.appendSelection([{ Key: geographyId, Value: geography }], modelResult.geographyData)); }}
                            />,
                            [
                                { label: translate('miles_from_site'), formattedValue: `${geography.driveTimeValue.toFixed(2)} mi`  }, 
                                { label: translate('kilometers_from_site'), formattedValue: `${helpers.convertLength(geography.driveTimeValue, constants.lengthMeasurements.miles, constants.lengthMeasurements.kilometers).toFixed(2)} km` },
                                ...geography.shape.results
                            ].map((variable, i) =>{
                            return <div key={i}>{`${variable.label}: ${variable.formattedValue}`}</div>
                        })];
                    }
                });
            };

            modelElements.layers.overlay.addEntity({
                id: geographyId,
                type: constants.entities.polygon,
                paths: parsedWkt,
                fillColor: constants.polygons.outline.fillColor,
                strokeColor: constants.polygons.outline.strokeColor,
                strokeWidth: constants.polygons.outline.strokeWidth,
                listeners: [{
                    type: constants.listeners.click,
                    action: select
                }]
            });

            if (geography.selected)    
                modelElements.layers.tradeArea.addEntity({
                    id: geographyId,
                    type: constants.entities.polygon,
                    paths: parsedWkt,
                    fillColor: constants.polygons.selected.fillColor,
                    strokeColor: constants.polygons.selected.strokeColor,
                    strokeWidth: constants.polygons.selected.strokeWidth,
                    listeners: [{
                        type: constants.listeners.click,
                        action: select
                    }]
                });
        });

        return modelElements.layers;
    },
    createDisaggregateForecastTradeAreaGrid: async ({ id, modelResult, modelElements }) =>{

        var promises = [];
    
        var element = <DataGrid
            ref={(forwardedRef) => {
                modelElements.views.find(x => x.id === id).grid.ref = forwardedRef;                
            }}
            columns={[
                { id: constants.projections.disaggregateForecastTradeAreaColumns.remove, width: 60, dataType: 'object', caption: '', allowFiltering: false, allowSorting: false },
                { id: constants.projections.disaggregateForecastTradeAreaColumns.zoom, width: 60, dataType: 'object', caption: '', allowFiltering: false, allowSorting: false },
                { id: constants.projections.disaggregateForecastTradeAreaColumns.geography, dataType: 'string', caption: translate('geography'), allowFiltering: true, allowSorting: true },
                { id: constants.projections.disaggregateForecastTradeAreaColumns.distanceMiles, dataType: 'number', caption: `${translate('distance')} (mi)`, allowFiltering: true, allowSorting: true }, 
                { id: constants.projections.disaggregateForecastTradeAreaColumns.distanceKilometers, dataType: 'number', caption: `${translate('distance')} (km)`, allowFiltering: true, allowSorting: true }                
            ].map((column) => {
                return {
                    name: column.id,
                    dataField: column.id,
                    fixed: true,
                    fixedPosition: 'left',
                    ...column,
                    onRender: (data) => {
                        
                        if (!_.isObject(data[column.id]))
                            return;
                        return data[column.id].value;
                    }
                };
            })}
            defaultPageSize={10}
            remoteOperations={ {groupPaging: true, remoteOperations: true} }            
            showScrollbar='onHover'
            showFilterRow={true}
            onLoad={async (parameters)=>{
                
                var forecastObject = modelElements.views.find(x => x.id === id).grid;
                
                if (!_.isObject(forecastObject.data))
                    await projections.changeDetail({ modelResult: modelResult, modelElements: modelElements });

                var tradeAreaData = await legacyEndpoints.service({
                    name: 'SortAndFilterDisaggregateForecastTradeAreaData',
                    parameters: {
                        standardGeographyResult: {
                            columns: forecastObject.data.columns,
                            shapes: forecastObject.data.shapes.filter(x => modelResult.geographyData.find(y => y.Key === x.id).Value.selected) 
                        },
                        geographyVintageId: modelResult.geographyVintage.Id,
                        reportId: modelElements.sumEnabled ? modelElements.selectedDetailId : -1, 
                        skip: parameters.skip, 
                        take: parameters.take, 
                        filters: _.isArray(parameters.filter) ? helpers.getFilterBuilder({ filter: parameters.filter, removePrefix: '_sum' }) : [],
                        sorts: _.isArray(parameters.sort) ? parameters.sort.map(sort => { return { fieldId: _.isObject(sort.selector) ? sort.selector.columnIndex : sort.selector, sortOrder: sort.desc ? 1 : 0 }; }) : []
                    }
                });

                tradeAreaData.columns.forEach(column => {
                    forecastObject.ref.instance.columnOption(`${column.id}_sum`, 'caption', modelElements.sumEnabled ? `${column.formattedValue}` : '-');
                });

                var datasource = [];

                tradeAreaData.shapes.forEach(row =>{

                    var data = {
                        id: row.id,
                    };

                    row.results.forEach(column =>{
                        switch(column.id){
                            default:
                                data[column.id] = { value: column.formattedValue };
                                break;
                            case constants.projections.disaggregateForecastTradeAreaColumns.remove:
                                data[constants.projections.disaggregateForecastTradeAreaColumns.remove] = { value: <Button 
                                        className='app-bulk-info-zoom' 
                                        theme='action' 
                                        icon={icons.minus} 
                                        tooltip={translate('remove')}
                                        onClick={() =>{
                                            modelElements.onGeographySelect(projections.removeSelection([{ Key: row.id, Value: modelResult.geographyData.find(x => x.Key === row.id).Value.shape }], modelResult.geographyData));
                                        }} 
                                    />
                                };
                                break;
                            case constants.projections.disaggregateForecastTradeAreaColumns.zoom:
                                data[constants.projections.disaggregateForecastTradeAreaColumns.zoom] = { value: <Button 
                                    className='app-bulk-info-zoom' 
                                    theme='action' 
                                    icon={icons.magnifyingGlass} 
                                    tooltip={translate('zoom')}
                                    onClick={() =>{
                                        map.locate({ location: modelResult.geographyData.find(x => x.Key === row.id).Value.shape.polygon.centroid });
                                    }} 
                                /> };
                                break;                            
                            case constants.projections.disaggregateForecastTradeAreaColumns.distanceMiles:
                                data[constants.projections.disaggregateForecastTradeAreaColumns.distanceMiles] = { value: `${parseFloat(column.value).toFixed(2)} mi` };                                
                                data[constants.projections.disaggregateForecastTradeAreaColumns.distanceKilometers] = { value: `${helpers.convertLength(column.value, constants.lengthMeasurements.miles, constants.lengthMeasurements.kilometers).toFixed(2)} km` };
                                break;
                        }
                    });

                    var entity = modelElements.layers.tradeArea.entities.find(x => x.id === row.id);

                    if (_.isObject(entity))
                    {
                        var fillColor = entity.fillColor;
                        var strokeColor = entity.strokeColor;
    
                        if (row.filtered)
                        {                        
                            fillColor.a = .1;
                            strokeColor.a = .5;
                        }
                        else
                        {
                            fillColor.a = .3;
                            strokeColor.a = .7;
                        }
    
                        entity.fillColor = fillColor;
                        entity.strokeColor = strokeColor;
                    }                    

                    if (!row.hidden)
                        datasource.push(data);
                });                

                return {
                    data: datasource,
                    totalCount: tradeAreaData.total
                };
            }}
        />

        await Promise.all(promises);

        return {
            element: element,
            ref: null,
            data: null
        };
    },    
    redefineDisaggregateForecastTradeArea: async ({ location, modelResult, modelElements }) =>{

        var result = await legacyEndpoints.service({
            name: 'RedefineAnalyticsTradeArea',
            parameters: {
                latitude: location.lat,
                longitude: location.lon,
                modelResult: {
                    type: modelResult.type,
                    sisterSiteData: modelResult.sisterSiteData,
                    subjectSiteCannibalization: modelResult.subjectSiteCannibalization,
                    model: modelResult.model,
                    geographyData: modelResult.geographyData,
                    pointSearchTradeArea: modelResult.pointSearchTradeArea
                }
            }
        });

        modelElements.onGeographySelect(result.geographyData);
    },
    refreshDisaggregateForecastTradeAreaGrid: ({ modelElements }) =>{
        modelElements.views.find(x => x.type === constants.projections.views.disaggregateForecastTradeArea).grid.ref.instance.refresh();
    },
    renderSelection: ({ title, wktList, onSelect, location, actions }) =>{

        var temporaryLayer = map.layers.find(x => x.type === constants.layers.types.temporary);
        temporaryLayer.clear();

        wktList.forEach(wkt =>{
            temporaryLayer.addEntity({
                type: constants.entities.polygon,
                paths: wkt,
                fillColor: constants.polygons.pending.fillColor,
                strokeColor: constants.polygons.pending.strokeColor,
                strokeWidth: 1
            });
        });

        const applySelection = (geographies)=>{

            temporaryLayer.clear(); 
            
            map.showTooltip({
                title: title,
                pixel: location,
                sections: [<div className='app-selections-geographies'>
                    <Icon icon={icons.spinner}/> {`${translate('applying')}...`}
                </div>]
            });

            setTimeout(() =>{
                onSelect(geographies);
                map.hideTooltip();
            }, 50);
        };

        map.showTooltip({
            title: title,
            pixel: location,
            sections: actions(applySelection),
            onHide: () =>{
                temporaryLayer.clear();
            }
        });
    },
    selectSingle: ({ modelResult, modelElements }) =>{

        map.setCursor({ cursor: 'crosshair' });
        map.disableEntityClicking();

        return map.addListener({
            type: constants.listeners.click,
            action: (e) => {

                var overlayGeography = modelElements.layers.overlay.entities.find(x => x.map.polygonContainsPoint(x.paths, e.location));                

                if (!_.isObject(overlayGeography))
                    return;

                var modeGeography = modelResult.geographyData.find(x => x.Key === overlayGeography.id);

                if (!_.isObject(modeGeography))
                    return;

                modeGeography.Value.selected = !_.isObject(modelElements.layers.tradeArea.entities.find(x => x.map.polygonContainsPoint(x.paths, e.location)));
                modelElements.onGeographySelect(modelResult.geographyData);
                
                map.disableEntityClicking();
            }
        });

    },
    selectMultiple: ({ behavior, modelResult, modelElements, onFinish }) =>{
        map.startDrawing({
            type: constants.entities.polygon,			
			onTooltipRender: (result) =>{

                if (result.locations.length < 3)
					return {
						sections: [<div>{translate('add_at_least_3_vertices')}</div>]
					};

				return { sections: [] };
            },
            onFinish: async (shape) =>{
    
                setTimeout(() =>{
                    map.showTooltip({
                        title: translate('specify_selection_action'),
                        pixel: shape.locations[0],
                        sections: [<div className='app-selections-geographies'>
                            <Icon icon={icons.spinner}/> {`${translate('calculating')}...`}
                        </div>]
                    });
                }, 50);

                var selected = await legacyEndpoints.service({
                    name: 'SelectAnalyticsGeographies',
                    parameters: {
                        selectionBehavior: behavior,
                        vintageId: modelResult.geographyVintage.Id, 
                        geoLevelId: modelResult.geographyVintage.GeoLevels[0].Id, 
                        polygon: {
                            PolygonType: constants.encodedString.google,
                            PolygonString: helpers.encodedLocations(shape.locations)
                        }, 
                        geographies: modelResult.geographyData
                    }
                });

                map.hideTooltip();

                projections.renderSelection({ 
                    title: translate('specify_selection_action'),
                    location: shape.locations[0],
                    wktList: selected.map(x => { return helpers.parseWkt(modelElements.boundaries.find(boundary => boundary.Key === x.Key)?.Value) }),
                    onSelect: modelElements.onGeographySelect,
                    actions: (applySelection) =>{
                        return [<>
                            <div className='app-projections-geography-toggle-container'>
                                <Button 
                                    className='app-projections-geography-toggle'
                                    theme='action' 
                                    icon={icons.add}
                                    text={translate('append_to_trade_area')}
                                    tooltip={translate('append_geographies_selection')} 
                                    onClick={()=>{ applySelection(projections.appendSelection(selected, modelResult.geographyData)); }} 
                                />
                            </div>
                            <div className='app-projections-geography-toggle-container'>
                                <Button 
                                    className='app-projections-geography-toggle'
                                    theme='action' 
                                    icon={icons.minus}
                                    text={translate('remove_from_trade_area')} 
                                    tooltip={translate('remove_geographies_within_area')} 
                                    onClick={()=>{ applySelection(projections.removeSelection(selected, modelResult.geographyData)); }} 
                                />
                            </div>                        
                            <div className='app-projections-geography-toggle-container'>
                                <Button 
                                    className='app-projections-geography-toggle'
                                    theme='action'
                                    icon={icons.swap}
                                    text={translate('replace_trade_area')}
                                    tooltip={translate('remove_and_replace_geographies')} 
                                    onClick={()=>{ applySelection(projections.newSelection(selected, modelResult.geographyData)); }} 
                                />
                            </div>
                        </>];
                    }
                });

                onFinish();
            }
        });
    },
    stopSelection: ({ selectionListenerId }) =>{
        map.stopDrawing();
        map.setCursor({ cursor: ''});
        map.removeListener(selectionListenerId);
    },
    appendSelection: (selection, geographies) =>{

		selection.forEach(geography =>{
            geographies.find(x => x.Key === geography.Key).Value.selected = true;
        });

		return geographies;
	},	
	removeSelection: (selection, geographies) =>{

        selection.forEach(geography =>{
            geographies.find(x => x.Key === geography.Key).Value.selected = false;
        });

		return geographies;
	},
    newSelection: (selection, geographies) =>{
        
        geographies.forEach(geography => geography.Value.selected = selection.filter(x => x.Key === geography.Key).length > 0);

        return geographies;
    },
    createDisaggregateForecastPointGrid: async ({ viewId, modelElements, layer, editForm }) =>{

        var promises = [];
    
        var element = <DataGrid
            ref={(forwardedRef) => {
                modelElements.views.find(x => x.id === viewId).grid.ref = forwardedRef;
            }}
            columns={[
                { id: constants.projections.disaggregateForecastPointColumns.select, width: 60, dataType: 'object', caption: '', allowFiltering: false, allowSorting: false, onHeaderRender: ()=>{

                    var allSelected = layer.data.filter(x => x.selected).length === layer.data.length;
                    return <Button 
                        className='app-bulk-info-zoom' 
                        theme='action' 
                        active={allSelected}
                        icon={allSelected ? icons.squareCheck : icons.squareEmpty } 
                        tooltip={translate('include')}
                        onClick={(e, button) =>{
                            
                            var active = layer.data.filter(x => x.selected).length !== layer.data.length;
                            button.setActive({ active: active });
                            button.setIcon({ icon: active ? icons.squareCheck : icons.squareEmpty });
                            
                            layer.data.forEach(site =>{
                                modelElements.onPointSelect({
                                    viewId: viewId,
                                    id: site.id,
                                    refreshGrid: false,
                                    forceSelectValue: active
                                });
                            });

                            projections.refreshGrid({ view: modelElements.views.find(x => x.id === viewId) });
                        }} 
                    />;
                } },
                { id: constants.projections.disaggregateForecastPointColumns.zoom, width: 60, dataType: 'object', caption: '', allowFiltering: false, allowSorting: false },
                { id: constants.projections.disaggregateForecastPointColumns.distance, dataType: 'number', caption: `${translate('distance')} (mi)`, allowFiltering: true, allowSorting: true, allowHeaderFilering: false },
                { id: constants.projections.disaggregateForecastPointColumns.distanceKm, dataType: 'number', caption: `${translate('distance')} (km)`, allowFiltering: true, allowSorting: true, allowHeaderFilering: false },
                ...editForm.map(column =>{
                return {
                    id: column.id,                    
                    caption: column.label,
                    allowFiltering: true, 
                    allowSorting: true
                };
            })].map(column =>{
                return {
                    name: column.id,
                    width: column.width,
                    dataField: column.id,
                    caption: column.caption,
                    allowFiltering: column.allowFiltering, 
                    allowSorting: column.allowSorting,
                    onHeaderRender: column.onHeaderRender,
                    headerFilterSearch: false,//_.isBoolean(column.allowHeaderFilering) ? column.allowHeaderFilering : true,
                    headerFilterDataSource: (o) =>{

                        o.dataSource.useDefaultSearch = false;
                        o.dataSource.load = async (parameters) => {

                            var result = await legacyEndpoints.service({
                                name: 'GetAnalyticsProjectionBulkEditFormColumnFilter',
                                parameters: {
                                    data: layer.data,
                                    fieldId: column.id.replace('_', ''),
                                    skip: _.isNumber(parameters.skip) ? parameters.skip : 0,
                                    take: _.isNumber(parameters.take) ? parameters.take : 0,
                                    searchOperation: helpers.getFilterOperator(parameters.searchOperation),
                                    searchValue: parameters.searchValue,
                                    filters: _.isArray(parameters.filter) ? helpers.getFilterBuilder({ filter: parameters.filter, removePrefix: '_' }) : []
                                }
                            });

                            return {
                                data: result.data,
                                totalCount: result.total
                            };
                        };
                    },
                    onRender: (data) => {
                        
                        if (!_.isObject(data[column.id]))
                            return '';

                        return data[column.id].value;
                    }
                }
            })}
            defaultPageSize={10}
            remoteOperations={ {groupPaging: true, remoteOperations: true} }            
            showScrollbar='onHover'
            showFilterRow={true}
            showHeaderFilter={true}          
            onLoad={async (parameters)=>{

                var siteData = await legacyEndpoints.service({
                    name: 'GetFilteredAnalyticsProjectionBulkEditFormData',
                    parameters: {
                        data: layer.data,
                        skip: parameters.skip,
                        take: parameters.take,
                        filters: _.isArray(parameters.filter) ? helpers.getFilterBuilder({ filter: parameters.filter, removePrefix: '_' }) : [],
                        sorts: _.isArray(parameters.sort) ? parameters.sort.map(sort => { return { fieldId: _.isObject(sort.selector) ? sort.selector.columnIndex : sort.selector.replace('_', ''), sortOrder: sort.desc ? 1 : 0 }; }) : []
                    }
                });

                var datasource = [];

                siteData.results.forEach(row =>{

                    var data = {
                        id: row.id,
                    };
                    
                    data[constants.projections.disaggregateForecastPointColumns.select] = 
                    
                    { value: row.temporary ? <Button 
                            className='app-bulk-info-zoom' 
                            theme='action' 
                            icon={icons.minus} 
                            tooltip={translate('remove')}
                            onClick={() =>{
                                projections.removeTempPoint({
                                    id: row.id,
                                    view: modelElements.views.find(x => x.id === viewId),
                                    layer: modelElements.layers.points
                                })
                            }} 
                        /> 
                        :
                        <Button 
                            className='app-bulk-info-zoom' 
                            theme='action' 
                            active={row.selected}
                            icon={row.selected ? icons.squareCheck : icons.squareEmpty} 
                            tooltip={translate('include')}
                            onClick={() =>{
                                modelElements.onPointSelect({
                                    viewId: viewId,
                                    id: row.id
                                });
                            }} 
                        /> 
                    };

                    data[constants.projections.disaggregateForecastPointColumns.zoom] = { value: <Button 
                        className='app-bulk-info-zoom' 
                        theme='action' 
                        icon={icons.magnifyingGlass} 
                        tooltip={translate('zoom')}
                        onClick={() =>{
                            map.locate({ location: row.location });
                        }} 
                    /> };

                    row.data.forEach(column =>{
                        switch(column.columnId){
                            default:
                                data[column.columnId] = column;
                                break;                                
                            case constants.projections.disaggregateForecastPointColumns.distance:
                                data[column.columnId] = { value: `${parseFloat(column.value).toFixed(2)} mi` };                                
                                data[`${column.columnId}_`] = { value: `${helpers.convertLength(column.value, constants.lengthMeasurements.miles, constants.lengthMeasurements.kilometers).toFixed(2)} km` };
                                break;
                        }
                    });

                    datasource.push(data);
                });

                return {
                    data: datasource,
                    totalCount: siteData.total
                };
            }}
        />;

        await Promise.all(promises);

        return {
            element: element,
            ref: null,
            data: null
        };

    },
    createDisaggregateCannibalizationGrid: async ({ viewId, projectionResults, modelResult, modelElements, layer, editForm }) =>{

        var promises = [];

        var currencyFormat = `${(modelResult.model.salesDistribution.format === 'M' ? '$' : '')}#${helpers.getSeparator({ locale: "en", type: "group" })}###${helpers.getSeparator({ locale: "en", type: "decimal" })}##`;
        var percentFormat = `#0${helpers.getSeparator({ locale: "en", type: "decimal" })}##%`;

        var element = <>
            {modelResult.model.type === constants.projections.models.disaggregateDeliveryCannibalization ? <div className='app-projections-define-trade-area'>{translate("please_define_a_trade_area")}</div> : null}
            <DataGrid
                ref={(forwardedRef) => {
                    modelElements.views.find(x => x.id === viewId).grid.ref = forwardedRef;
                }}
                columns={[
                    { dataField: "id",dataType: "string", visible: false, allowEditing: true },
                    { id: constants.projections.disaggregateCannibalizationColumns.storeSelect, width: 60, caption: '', allowSorting: true, allowFiltering: false, dataType: 'string', onHeaderRender: ()=>{
                        
                        var allSelected = modelResult.sisterSiteData[0].sites.filter(x => x.selected).length === modelResult.sisterSiteData[0].sites.length;                        
                        return <Button 
                            className='app-bulk-info-zoom' 
                            theme='action' 
                            active={allSelected}
                            icon={allSelected ? icons.squareCheck : icons.squareEmpty } 
                            tooltip={translate('include')}
                            onClick={(e, button) =>{

                                var active = modelResult.sisterSiteData[0].sites.filter(x => x.selected).length !== modelResult.sisterSiteData[0].sites.length;
                                button.setActive({ active: active });
                                button.setIcon({ icon: active ? icons.squareCheck : icons.squareEmpty });
                                
                                modelResult.sisterSiteData[0].sites.forEach(site =>{
                                    modelElements.onPointSelect({
                                        viewId: viewId,
                                        id: site.id,
                                        refreshGrid: false,
                                        forceSelectValue: active
                                    });
                                });
    
                                projections.refreshGrid({ view: modelElements.views.find(x => x.id === viewId) });
                            }} 
                        />;
                    } },
                    { id: constants.projections.disaggregateCannibalizationColumns.storeZoom, width: 60, caption: '', allowSorting: false, allowFiltering: false },
                    { id: constants.projections.disaggregateCannibalizationColumns.storeDistance, caption: `${translate('distance')} (mi)`, allowSorting: true, allowFiltering: true, dataType: 'number'  },
                    { id: constants.projections.disaggregateCannibalizationColumns.storeDistanceKm, caption: `${translate('distance')} (km)`, allowSorting: true, allowFiltering: true, dataType: 'number'  },
                    ...modelResult.sisterSiteData[0].keywords.map(keyword =>{

                        var caption = keyword.name;
                        var dataType = "string";
                        var format = null;

                        if (_.isObject(editForm))
                        {
                            var editFormColumn = editForm.find(x => x.id === keyword.editFormColumnId);

                            if (_.isObject(editFormColumn)){
                                caption = editFormColumn.label.length > 0 ? editFormColumn.label : caption;

                                switch (editFormColumn.type) {
                                    case inputBoxTypes.numeric:

                                        format = "#" + helpers.getSeparator({ locale: "en", type: "group" }) + "###";

                                        if (editFormColumn.decimalPlaces > 0)
                                            format += helpers.getSeparator({ locale: "en", type: "decimal" }) + "#".repeat(editFormColumn.decimalPlaces);

                                        dataType = "number";

                                        break;
                                    case inputBoxTypes.currency:

                                        format = "$ #" + helpers.getSeparator({ locale: "en", type: "group" }) + "###";

                                        if (editFormColumn.decimalPlaces > 0)
                                            format += helpers.getSeparator({ locale: "en", type: "decimal" }) + "#".repeat(2);

                                        dataType = "number";

                                        break;
                                    case inputBoxTypes.percentage:

                                        format = "#0";

                                        if (editFormColumn.decimalPlaces > 0)
                                            format += helpers.getSeparator({ locale: "en", type: "decimal" }) + "#".repeat(editFormColumn.decimalPlaces);

                                        format += " %";

                                        dataType = "number";

                                        break;
                                    case inputBoxTypes.date:

                                        dataType = "date";                                    

                                        break;
                                    case inputBoxTypes.checkbox:
                                        dataType = "boolean";
                                        break;

                                }
                            }
                        }

                        return {
                            id: keyword.id,
                            allowSorting: true,
                            allowFiltering: true,
                            caption: caption,
                            dataType: dataType
                        };
                    }),
                    { id: constants.projections.disaggregateCannibalizationColumns.storeVolume, caption: translate('volume'), allowSorting: true, allowFiltering: true, dataType: 'number', format: currencyFormat },
                    { id: constants.projections.disaggregateCannibalizationColumns.storeForecast, caption: translate('forecast'), allowSorting: true, allowFiltering: true, dataType: 'number', format: currencyFormat },
                    { id: constants.projections.disaggregateCannibalizationColumns.storeImpact, caption: translate('impact'), allowSorting: true, allowFiltering: true, dataType: 'number', format: percentFormat },
                    { id: constants.projections.disaggregateCannibalizationColumns.storeTransferred, caption: translate('transferred'), allowSorting: true, allowFiltering: true, dataType: 'number', format: currencyFormat }
                ].map((column) => {

                    return {
                        name: column.id,
                        dataField: column.id,
                        ...column,
                        headerFilterSearch: false,//_.isBoolean(column.allowHeaderFilering) ? column.allowHeaderFilering : true,
                        headerFilterDataSource: (o) =>{

                            o.dataSource.useDefaultSearch = false;
                            o.dataSource.load = async (parameters) => {
                                
                                var result = await legacyEndpoints.service({
                                    name: 'GetAnalyticsProjectionCannibalizationFilter',
                                    parameters: {
                                        layer: projections.removeKeywordSql(layer),
                                        id: column.id,
                                        siteId: null,
                                        skip: _.isNumber(parameters.skip) ? parameters.skip : 0,
                                        take: _.isNumber(parameters.skip) ? parameters.take : 0,
                                        searchOperation: helpers.getFilterOperator(parameters.searchOperation),
                                        searchValue: parameters.searchValue,
                                        filters: _.isArray(parameters.filter) ? helpers.getFilterBuilder({ filter: parameters.filter, removePrefix: '_' }) : [],
                                    }
                                });

                                return {
                                    data: result.data,
                                    totalCount: result.total
                                };
                            };
                        },
                        onRender: (data) => {
                            return data[column.id]?.value;
                        }
                    };
                })}
                defaultPageSize={10}
                remoteOperations={ {groupPaging: true, remoteOperations: true} }
                showScrollbar='onHover'
                showFilterRow={true}
                showHeaderFilter={true}
                onLoad={async (parameters)=>{

                    if (modelResult.sisterSiteData[0].sites.length === 0)
                        return {
                            data: [],
                            totalCount: 0
                        };
                    
                    var cannibalizationData = await legacyEndpoints.service({
                        name: 'GetFilteredAnalyticsProjectionCannibalization',
                        languageOverride: "en",
                        parameters: {
                            layer: modelResult.sisterSiteData[0],
                            siteId: null,                        
                            skip: parameters.skip, 
                            take: parameters.take, 
                            filters: _.isArray(parameters.filter) ? helpers.getFilterBuilder({ filter: parameters.filter, removePrefix: '_' }) : [],
                            sorts: _.isArray(parameters.sort) ? parameters.sort.map(sort => { return { fieldId: _.isObject(sort.selector) ? sort.selector.columnIndex : sort.selector.replace('_', ''), sortOrder: sort.desc ? 1 : 0 }; }) : []
                        }
                    });
                    
                    if (!_.isArray(parameters.sort))
                    {
                        cannibalizationData.results = _.sortBy(cannibalizationData.results, x => x.data.find(y => y.columnId === constants.projections.disaggregateCannibalizationColumns.storeImpact).value);

                        if (modelResult.model.type === constants.projections.models.disaggregateRecapture)
                            cannibalizationData.results.reverse();
                    }

                    var datasource = [];

                    cannibalizationData.results.forEach(row =>{
                        var data = {
                            id: row.id
                        };

                        row.data.forEach(column =>{
                            switch(column.columnId){
                                default:
                                    data[column.columnId] = column;
                                    break;
                                case constants.projections.disaggregateCannibalizationColumns.storeVolume:
                                case constants.projections.disaggregateCannibalizationColumns.storeForecast:
                                case constants.projections.disaggregateCannibalizationColumns.storeTransferred:
                                    column.value = `${(modelResult.model.salesDistribution.format === 'M' ? '$' : '')}${helpers.formatNumber(parseFloat(column.value).toFixed(2))}`;
                                    data[column.columnId] = column;
                                    break
                                case constants.projections.disaggregateCannibalizationColumns.storeImpact:
                                    column.value = `${helpers.formatNumber(parseFloat(column.value * 100).toFixed(2))}%`;
                                    data[column.columnId] = column;
                                    break
                                case constants.projections.disaggregateCannibalizationColumns.storeSelect:
                                    var selected = column.value.toLowerCase() === 'true';
                                    data[column.columnId] = { value: <Button 
                                        className='app-bulk-info-zoom' 
                                        active={selected}
                                        theme='action' 
                                        icon={selected ? icons.squareCheck : icons.squareEmpty} 
                                        tooltip={translate('include')}
                                        onClick={() =>{
                                            modelElements.onPointSelect({
                                                viewId: viewId,
                                                id: row.id
                                            });
                                        }} 
                                    /> };
                                    data[`${column.columnId}_`] = { value: <Button 
                                        className='app-bulk-info-zoom' 
                                        theme='action' 
                                        icon={icons.magnifyingGlass} 
                                        tooltip={translate('zoom')}
                                        onClick={() =>{
                                            map.locate({ location: row.location });
                                        }} 
                                    /> };
                                    break;
                                case constants.projections.disaggregateCannibalizationColumns.storeDistance:
                                    data[column.columnId] = { value: `${parseFloat(column.value).toFixed(2)} mi` };                                
                                    data[`${column.columnId}_`] = { value: `${helpers.convertLength(column.value, constants.lengthMeasurements.miles, constants.lengthMeasurements.kilometers).toFixed(2)} km` };
                                    break;
                            }
                        });

                        datasource.push(data);
                    });

                    return {
                        data: datasource,
                        totalCount: cannibalizationData.total
                    };

                }}
                enableMasterDetail={projectionResults.apiType !== constants.projections.api.external}
                expandOneRowOnly={true}            
                detailTemplate={({ data })=>{
                    
                    var masterKey = data.key.id;

                    return <DataGrid                     
                        columns={[
                            { id: constants.projections.disaggregateCannibalizationColumns.geographyZoom, width: 60, caption: '', allowSorting: false, allowFiltering: false, allowEditing: false },
                            { id: constants.projections.disaggregateCannibalizationColumns.geographyId, caption: translate('geography'), allowEditing: false },
                            { id: constants.projections.disaggregateCannibalizationColumns.geographyDistance, caption: `${translate('distance')} (mi)`, dataType: 'number', allowEditing: false },
                            { id: constants.projections.disaggregateCannibalizationColumns.geographyDistanceKm, caption: `${translate('distance')} (km)`, dataType: 'number', allowEditing: false },
                            { id: constants.projections.disaggregateCannibalizationColumns.geographyAzimuth, caption: translate('azimuth'), allowEditing: false },
                            { id: constants.projections.disaggregateCannibalizationColumns.geographyVolume, caption: translate('volume'), format: currencyFormat, dataType: 'number', allowEditing: false},          
                            { id: constants.projections.disaggregateCannibalizationColumns.geographyForecast, caption: translate('forecast'), format: currencyFormat, dataType: 'number', allowEditing: false },
                            { id: constants.projections.disaggregateCannibalizationColumns.geographyImpact, caption: translate('impact'), format: percentFormat, dataType: 'number', allowEditing: true, className: 'app-data-grid-column-editable',
                                onEditRender: (data) =>{   
                                                                    
                                    return <TextBox                                    
                                        type={inputBoxTypes.numeric}
                                        value={parseFloat(data.value.numberValue)} 
                                        maxLength={3}
                                        allowNegative={true}
                                        allowDecimals={true}
                                        onChange={async (o) => {
                                            
                                            if (o.value === parseFloat(data.value.numberValue))
                                                return;

                                            layer = await legacyEndpoints.service({
                                                name: 'RecalculateAnalyticsProjectionCannibalization',                                            
                                                parameters: {
                                                    layer: projections.removeKeywordSql(layer),
                                                    siteId: masterKey,
                                                    geographyId: data.key.id, 
                                                    transfer: o.value / 100                                            
                                                }
                                            });
                                            
                                            modelResult.sisterSiteData = [layer];
                                            projections.refreshGrid({ view: modelElements.views.find(x => x.id === viewId) });

                                        }}
                                    />;
                                } },
                            { id: constants.projections.disaggregateCannibalizationColumns.geographyTransferred, caption: translate('transferred'), format: currencyFormat, dataType: 'number', allowEditing: false}
                        ].map((column) => {
                            return {
                                name: column.id,
                                dataField: column.id,
                                ...column,
                                headerFilterSearch: false,//_.isBoolean(column.allowHeaderFilering) ? column.allowHeaderFilering : true,,
                                headerFilterDataSource: (o) =>{

                                    o.dataSource.useDefaultSearch = false;
                                    o.dataSource.load = async (parameters) => {
                                        
                                        var result = await legacyEndpoints.service({
                                            name: 'GetAnalyticsProjectionCannibalizationFilter',
                                            parameters: {
                                                layer: projections.removeKeywordSql(layer),
                                                id: column.id,
                                                siteId: masterKey,
                                                skip: _.isNumber(parameters.skip) ? parameters.skip : 0,
                                                take: _.isNumber(parameters.skip) ? parameters.take : 0,
                                                searchOperation: helpers.getFilterOperator(parameters.searchOperation),
                                                searchValue: parameters.searchValue,
                                                filters: _.isArray(parameters.filter) ? helpers.getFilterBuilder({ filter: parameters.filter, removePrefix: '_' }) : [],
                                            }
                                        });

                                        return {
                                            data: result.data,
                                            totalCount: result.total
                                        };
                                    };
                                },
                                onRender: (data) => {
                                    return data[column.id].value;
                                }
                            };
                        })}
                        
                        defaultPageSize={10}
                        remoteOperations={ {groupPaging: true, remoteOperations: true} }
                        showScrollbar='onHover'
                        showFilterRow={true}
                        showHeaderFilter={true}
                        expandOneRowOnly={true}
                        editing={{
                            mode: "batch",
                            allowUpdating: true,
                            selectTextOnEditStart: true,
                            startEditAction: "click"
                        }}
                        onLoad={async (parameters)=>{
                            
                            var geographyData = await legacyEndpoints.service({
                                name: 'GetFilteredAnalyticsProjectionCannibalization',
                                languageOverride: "en",
                                parameters: {
                                    layer: modelResult.sisterSiteData[0],
                                    siteId: masterKey,                        
                                    skip: parameters.skip, 
                                    take: parameters.take, 
                                    filters: _.isArray(parameters.filter) ? helpers.getFilterBuilder({ filter: parameters.filter, removePrefix: '_' }) : [],
                                    sorts: _.isArray(parameters.sort) ? parameters.sort.map(sort => { return { fieldId: _.isObject(sort.selector) ? sort.selector.columnIndex : sort.selector.replace('_', ''), sortOrder: sort.desc ? 1 : 0 }; }) : []
                                }
                            });

                            var datasource = [];

                            geographyData.results.forEach(row =>{
                                
                                var data = {
                                    id: row.id
                                };

                                row.data.forEach(column =>{
                                    switch(column.columnId){
                                        default:
                                            data[column.columnId] = column;
                                            break;
                                        case constants.projections.disaggregateCannibalizationColumns.geographyVolume:
                                        case constants.projections.disaggregateCannibalizationColumns.geographyForecast:
                                        case constants.projections.disaggregateCannibalizationColumns.geographyTransferred:
                                            column.value = `${(modelResult.model.salesDistribution.format === 'M' ? '$' : '')}${helpers.formatNumber(parseFloat(column.value).toFixed(2))}`;
                                            data[column.columnId] = column;
                                            break
                                        case constants.projections.disaggregateCannibalizationColumns.geographyImpact:
                                            column.value = `${helpers.formatNumber(parseFloat(column.value * 100).toFixed(2))}%`;
                                            column.numberValue = column.value;
                                            data[column.columnId] = column;
                                            break
                                        case constants.projections.disaggregateCannibalizationColumns.geographyId:
                                            data[constants.projections.disaggregateCannibalizationColumns.geographyZoom] = { value: 
                                                <Button 
                                                    className='app-bulk-info-zoom' 
                                                    theme='action' 
                                                    icon={icons.magnifyingGlass} 
                                                    tooltip={translate('zoom')}
                                                    onClick={() =>{
                                                        map.locate({ location: row.location });
                                                    }} 
                                                /> 
                                            };
                                            data[column.columnId] = column;
                                            break;
                                        case constants.projections.disaggregateCannibalizationColumns.geographyDistance:
                                            data[column.columnId] = { value: `${parseFloat(column.value).toFixed(2)} mi` };                                
                                            data[constants.projections.disaggregateCannibalizationColumns.geographyDistanceKm] = { value: `${helpers.convertLength(column.value, constants.lengthMeasurements.miles, constants.lengthMeasurements.kilometers).toFixed(2)} km` };
                                            break;
                                    }
                                });

                                datasource.push(data);
                            });

                            return {
                                data: datasource,
                                totalCount: geographyData.total
                            };
                        }}
                    />
                }}
            />
        </>;
        

        await Promise.all(promises);

        return {
            element: element,
            ref: null,
            data: null
        };      
    },
    selectTemporarySite: ({ onAdd }) =>{

        map.setCursor({ cursor: 'crosshair' });
        map.disableEntityClicking();
        return map.addListener({
            executeOnce: true,
            type: constants.listeners.click,
            action: (e) => {
                onAdd(e);
                map.setCursor({ cursor: '' });
                map.enableEntityClicking();
            }
        })
    },
    addTemporarySite: ({ subjectSiteLocation, modelElements, view, updateForm }) =>{        

        var tempId = helpers.newGuid();
        var tempSite = _.cloneDeep(view.layer.emptySite);
        var tempBulk = _.cloneDeep(view.layer.emptyBulkData);        

        tempSite.id = tempId;
        tempBulk.id = tempId;
        tempBulk.infoboxTitle = tempId;
        tempBulk.mapLabel = tempId;        
    
        _.each(updateForm.UpdateSections, function (section) {
            _.each(section.UpdateFields, function (field) {
    
                if (field.FieldId.toLowerCase() === view.layer.addFormLatitudeColumnId.toLowerCase()) {
                    tempSite.location.lat = parseFloat(field.Value);
                    tempBulk.location.lat = parseFloat(field.Value);
                }
    
                if (field.FieldId.toLowerCase() === view.layer.addFormLongitudeColumnId.toLowerCase()) {
                    tempSite.location.lon = parseFloat(field.Value);
                    tempBulk.location.lon = parseFloat(field.Value);
                }
    
                var tempSiteData = tempSite.data.find(x=> x.addFormColumnId === field.FieldId);
    
                if (!_.isObject(tempSiteData))
                    return;
    
                tempSiteData.columnValue = field.Value;
    
                var tempBulkData = tempBulk.data.find(x => x.columnId === tempSiteData.editFormColumnId);
    
                if (!_.isObject(tempBulkData))
                    return;
    
                tempBulkData.value = field.Value;
            });
        });

        var distance = helpers.convertLength( map.computeLength({ locations: [subjectSiteLocation, tempSite.location] }), constants.lengthMeasurements.meters, constants.lengthMeasurements.miles).toFixed(2);
        tempSite.subjectSiteDistanceInMiles = distance;
        tempBulk.data = [
            { columnId: constants.projections.disaggregateForecastPointColumns.distance, value: distance },
            ...tempBulk.data
        ];
    
        view.layer.sites.splice(0, 0, tempSite);
        view.layer.sites.join();
    
        view.layer.data.splice(0, 0, tempBulk);
        view.layer.data.join();    
    
        view.grid.ref.instance.refresh();
    
        projections.createTempPoint({
            id: tempId,
            location: tempSite.location,            
            layer: modelElements.layers.points,
            view: view
        });
    },
    createTempPoint: ({ id, location, layer, view }) => {
    
        var symbolDimensions = helpers.getDimensionsFromSymbolUrl(legacyEndpoints.handlers.getSymbolUrl({ imageUrl: userPreferences.DefaultPushpinSymbol }));

        layer.addEntity({ 
            id: id,
            text: id,
            type: constants.entities.point,
            location: location,
            anchor: { x: symbolDimensions.width / 2, y: symbolDimensions.height / 2 },
            width: symbolDimensions.width,
            height: symbolDimensions.height,
            image: legacyEndpoints.handlers.getSymbolUrl({ imageUrl: userPreferences.DefaultPushpinSymbol }),
            listeners: [{
                type: constants.listeners.click,
                action: (e) =>{                    
                    map.showTooltip({
                        title: translate('remove_temporary_site'),
                        pixel: map.latLonToXY(location),
                        sections: [<>
                            <Button
                                className='app-projections-geography-toggle' 
                                theme='action' 
                                icon={icons.minus} 
                                text={translate('remove')} 
                                onClick={()=>{
                                    projections.removeTempPoint({
                                        id: id,
                                        view: view,
                                        layer: layer
                                    });
                                    map.hideTooltip();
                                }}
                            />
                        </>]
                    });
                }
            }]
        });

    },
    removeTempPoint: ({ id, view, layer }) => {
        layer.entities.find(x => x.id === id)?.dispose();
        view.layer.sites = view.layer.sites.filter(x => x !== view.layer.sites.find(y => y.id === id));
        view.layer.data = view.layer.data.filter(x => x !== view.layer.data.find(y => y.id === id));
        view.grid.ref.instance.refresh();
    },
    saveScenario: async ({ projectionResults, scenario, name, description }) =>{
        
        var id = null;
        if (scenario === null) 
            id = await legacyEndpoints.service({
                name: 'CreateScenario',
                parameters: {
                    SourceId: projectionResults.pointId,
                    TypeId: '37AFCA8B-8FA1-4441-814C-C4519210E5CE',
                    Name: name,
                    Description: description
                }
            });
        else
            await legacyEndpoints.service({
                name: 'UpdateScenario',
                parameters: {
                    Id: scenario.Id,
                    Name: name,
                    Description: description,
                }
            });

        var result = await legacyEndpoints.service({
            name: 'GetScenario',
            parameters: {
                Id: id === null ? scenario.Id : id
            }
        });

        await legacyEndpoints.service({
            name: 'SaveAnalyticsProjectionScenario',
            parameters: {
                scenarioId: result.Id,
                projectionResults: projectionResults
            }
        });

        return result;
    },
    copyScenario: async ({ projectionResults, scenario, copyName, copyDescription })=>{

        var id = await legacyEndpoints.service({
            name: 'CopyScenario',
            parameters: {
                Id: scenario.Id,
                Name: copyName,
                Description: copyDescription,
            }
        });

        await legacyEndpoints.service({
            name: 'SaveAnalyticsProjectionScenario',
            parameters: {
                scenarioId: id,
                projectionResults: projectionResults
            }
        });

        return await legacyEndpoints.service({
            name: 'GetScenario',
            parameters: {
                Id: scenario.Id
            }
        });
    },
    loadScenario: ({ projectionResults, jobName, scenario, orginalParameters }) =>{

        workBench.setContent({ 
            title: projectionResults.name,
            content: <Loader />,
            height: '700px',
            maxHeight: '1000px',
            startMinimized: true,
            dragEnabled: false
        });

        setTimeout(() =>{
            projections.openInteractiveGrid({
                name: projectionResults.name,
                projectionResults: projectionResults,
                jobName: jobName,
                scenario: scenario,
                orginalParameters: orginalParameters
            });
        }, 500);

    },
    linkModels: ({ projectionResults, projectionElements, viewIds }) => {

        projectionResults.modelResults.forEach(modelResult =>{
            projectionElements.find(x => x.id === modelResult.model.id).views.filter(x => x.type === constants.projections.views.disaggregateCannibalizationPoint || x.type === constants.projections.views.disaggregateForecastPoint).forEach(view => {
                view.linked = viewIds.filter(x => x === view.id).length > 0;
            });
        });

        return projectionResults;
    },
    getProjectionResults: async ({ jobId }) =>{

        var data = await legacyEndpoints.service({
            name: 'GetAnalyticsProjectionJobResult',
            parameters: {
                JobId: jobId
            }
        });
        
        return {
            valid: _.map(data.projectionResults.modelResults, 'geographyData').flat().filter(geography => geography.Value.output.filter(output => output.HasTheme).length > 0),
            projectionResults: data.projectionResults
        };
    },
    processJobResults: ({ jobResults, projectionResults }) =>{

        projectionResults.modelResults.filter(x => x.model.type === constants.projections.models.disaggregateForecast).forEach(modelResult =>{
            var jobModelResult = jobResults.modelResults.find(x => x.model.id.toLowerCase() === modelResult.model.id.toLowerCase());

            if (!_.isObject(jobModelResult))
                return;

            modelResult.model.output = jobModelResult.model.output;

            modelResult.geographyData.forEach(geography =>{

                var jobGeography = jobModelResult.geographyData.find(x => x.Key.toLowerCase() === geography.Key.toLowerCase());

                if (!_.isObject(jobGeography))
                    return;

                geography.Value.output = jobGeography.Value.output;                
            });
            
            projectionResults.find(x => x.id === modelResult.model.id).views.find(x => x.type === constants.projections.views.disaggregateForecastTradeArea).grid.ref.instance.refresh();

        });

        return projectionResults;
    },
    clearGridFilters: ({ view }) =>{
        view.grid.ref.instance.clearFilter();
    },
    refreshGrid: ({ view }) =>{
        view.grid.ref.instance.refresh();
    },
    removeKeywordSql: (layer)=>{
        var layerWithoutSql = _.clone(layer);
        layerWithoutSql.keywordSql = "";
        return layerWithoutSql;
    },
    calculateDeliveryAreaCannibalization: async ({ projectionResults, modelResult, modelElements, viewId }) =>{

        var result = await legacyEndpoints.service({
            name: 'GetDisaggregateDeliveryCannibalization',
            parameters: {
                projectionResults: projectionResults,
                modelResult: modelResult,
                polygon: projectionResults.tradeAreas.find(x => x.isDeliveryArea).tradeArea.polygon
            }
        });

        modelResult.geographyData = result.geographyData;
        modelResult.sisterSiteData = result.sisterSiteData;

        if (viewId)
            projections.refreshGrid({ view: modelElements.views.find(x => x.id === viewId) });
    },
    addTradeAreasToMap: ({ projectionResults, tradeAreas }) =>{

        var layer =  map.layers.find(x => x.id === projectionResults.id);
        layer.entities.filter(entity => entity.type === constants.entities.polygon).forEach(entity => entity.dispose());                            

        tradeAreas.filter(x => x.isDeliveryArea).forEach(x => {
            layer.addEntity({
                type: constants.entities.polygon,
                paths: helpers.decodeLocations(x.tradeArea.polygon.polygonString),
                fillColor: { r: 0, g: 0, b: 0, a: 0},
                strokeColor: { r: 0, g: 0, b: 0, a: 1 },
                strokeWidth: 1
            });
        });

        projectionResults.tradeAreas = tradeAreas;
    },
    debug: async ({ projectionResults }) =>{
        return await legacyEndpoints.service({
            name: 'GenerateProjectionInputJson',
            parameters: {
                projectionResults: projectionResults
            }
        });
    }
};