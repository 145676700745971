// React imports
import { useState, useEffect, useCallback} from 'react';

// App imports
import { constants } from '../../../utils/constants';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { Button } from '../../base/button/button';
import { Loader } from '../../base/loader/loader';
import { mapBooks } from '../../../modules/mapbooks';
import { TextBox } from '../../base/textBox/textBox';
import { helpers } from '../../../utils/helpers';
import { Icon } from '../../base/icon/icon';
import { List } from 'devextreme-react';
import { map } from '../map/map';
import { successToast } from '../../base/toast/toast';
import { ModalCard } from '../../base/modalCard/modalCard';
import { MapBookError } from '../tools/mapBooks/mapBookError/mapBookError';

const _ = require('lodash');

export function MapBookRunner({entity}) {
    const [generating, setGenerating] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [availableMapBooks, setAvailableMapBooks] = useState([]);
    const [jobName, setJobName] = useState(new Date().toLocaleString());
    const [outputFormat, setOutputFormat] = useState(constants.mapBooks.outputFormat.onePDF);
    const [validationError, setValidationError] = useState(null);

    const getMapBookList = async () =>{
        setLoaded(false);    

        var items = [];
        var data = await mapBooks.getMapBooksForUser();
        data.adminMapBooks.forEach((item) => {
            items.push({ id: item.id, name: item.name, type: constants.mapBooks.type.admin });
        });

        data.userMapBooks.forEach((item) => {
            items.push({ id: item.id, name: item.name, type: constants.mapBooks.type.user });
        });

        setAvailableMapBooks([...items]);

        setLoaded(true);      
    };

    useEffect(() => {
        getMapBookList();
    }, [setAvailableMapBooks])

    const mapBookDataListRow = (mapBook) =>{
        return <div key={mapBook.id} className='app-map-book-runner-list-item'>
                    <div>
                        <Icon className="" icon={mapBook.type == constants.mapBooks.type.admin ? icons.lock : icons.bookBookmark}  /> 
                        <span className="app-map-book-runner-list-name">{mapBook.name}</span>
                    </div>
                    <div className='app-map-book-runner-list-button-container'>
                        <Button theme='simple' icon={icons.send} tooltip={translate('execute')} onClick={()=>{onSave(mapBook)}}/>
                    </div>
                </div>        
    };
 
    const onSave = (o) =>{
        setGenerating(true);

        var point = {
            BaseImage: null,
            BaseImageType: null,
            Geocode: {},
            Id: entity.id,
            Latitude: entity.location.lat,
            Longitude: entity.location.lon,
            Shape: null,
            FillColor: entity.type != constants.entities.point && entity.type != constants.entities.pushpin ? entity.fillColor : null,
            LineColor: entity.type != constants.entities.point && entity.type != constants.entities.pushpin ? entity.strokeColor : null,
            LineStyle: entity.type != constants.entities.point && entity.type != constants.entities.pushpin ? entity.strokeStyle : null,
            LineWidth: entity.type != constants.entities.point && entity.type != constants.entities.pushpin ? entity.strokeWidth : 0,
            Name: entity.label,
            PointId: _.isNull(entity.groupId) ? null : entity.id ,
            Selected: true,
            ServiceId: _.isNull(entity.groupId) ? null : entity.layer.id,
            Type: null
        };

        switch(entity.type)
        {
            case constants.entities.pushpin:
                point.BaseImage = helpers.getDataFromSymbolUrl(entity.image).name;
                point.BaseImageType = helpers.getDataFromSymbolUrl(entity.image).type;
                point.Type = constants.mapBooks.mapBookPointType.pushpin;

                map.geocode({
                    query: `${entity.location.lat} ${entity.location.lon}`,
                    callback: (result) => {      
                        point.Geocode.Latitude = entity.location.lat;
                        point.Geocode.Longitude = entity.location.lon;
                        point.Geocode.Address = result.street;
                        point.Geocode.City = result.city;
                        point.Geocode.State = result.state;
                        point.Geocode.Zip = result.zip;
                    }
                });
                break;
            case constants.entities.point:
                point.BaseImage = helpers.getDataFromSymbolUrl(entity.image).name;
                point.BaseImageType = helpers.getDataFromSymbolUrl(entity.image).type;
                point.Type = constants.mapBooks.mapBookPointType.point;
                break;
            case constants.entities.cluster:
                break;
            default:
                point.Type = constants.mapBooks.mapBookPointType.shape;
                point.BaseImageType = constants.symbols.types.shapeStyle;
                point.Shape = {
                    polygonString: helpers.pointsToWkt(entity.paths),
                    polygonType: constants.encodedString.wkt
                };
                break;
        }
            
        mapBooks.addMapBookJob({
            id: o.id,
            name: jobName,
            customQueryId: null,
            executionType: constants.mapBooks.executionType.selection,
            outputFormat: outputFormat,
            type: o.type,
            points: [point],
            callBack: (response) => {
                setGenerating(false)
                if (response?.ErrorPages?.length === 0 && response?.Errors?.length === 0) {
                    successToast(translate('successfully_submitted_job'));
                }
                else {
                    setValidationError(response);
                }
            }
        });
    };

    return  <>
        { generating ? <Loader theme='generate' showLogo={true}/> : ''}
        { loaded ? '' : <Loader/>}
        {
            validationError ?
            <ModalCard className='app-modal-card-default app-map-book-runner-error' open={true}>
                <MapBookError data={validationError} onClose={()=>{ setValidationError(null); }} />
            </ModalCard> : ''
        }
        <div className='app-map-book-runner-header'>
            <div className='app-map-book-runner-title'>{translate("map_books")}</div>
        </div>
        <TextBox className='app-map-book-runner-textBox'
            height='40px' label={translate('job_name')} placeholder={translate('job_name')}
            value={jobName}
            onChange={(o) => {setJobName(o.value)}} 
        />
        <div className='app-map-book-runner-list-container'>
                <List
                    keyExpr={'id'}
                    dataSource={availableMapBooks}
                    itemRender={mapBookDataListRow}
	            	grouped={false}
	            	collapsibleGroups={true}
	            	showSelectionControls={true}
	            	selectionMode={"none"}
	            	selectAllMode={'allPages'}
	            	displayExpr={'name'}
	            	searchEnabled={false}
	            	searchExpr={"name"}
	            	searchMode={'contains'}
	            	selectByClick={false}
	            	pageLoadMode={"scrollBottom"}
	            	focusStateEnabled={false}
	            	activeStateEnabled={false}
	            />
        </div>
   </>
}