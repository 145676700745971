// React imports
import React, { useState } from 'react';

// Third party imports
import ColorBox from 'devextreme-react/color-box';
import Tooltip, { Position } from 'devextreme-react/tooltip';

// App imports
import { Hideable } from "../../../base/hideable/hideable";
import { TextArea } from "../../../base/textArea/textArea";
import { DropDown } from "../../../base/dropDown/dropDown";
import { Button } from "../../../base/button/button";
import { RadioButtonSet } from "../../../base/radioButtonSet/radioButtonSet";
import { ConfirmButton } from "../../../base/confirmButton/confirmButton";
import { icons } from "../../../base/icon/icon";
import { translate } from '../../../../utils/translation';
import { constants } from '../../../../utils/constants';

import _ from 'lodash';

export var mapEntityLabelEditor;

var onUpdate = () => {};
var onDelete = () => {};

export const MapEntityLabelEditor = () => {
    
    const [hide, setHide] = useState(true);
    const [pixel, setPixel] = useState({ x: -1, y: -1 });
    const [top, setTop] = useState(false);
    const [text, setText] = useState('');
    const [textDefault, setTextDefault] = useState('');
    const [textOrginal, setTextOrginal] = useState('');
    const [properties, setProperties] = useState(null);
    const [propertiesDefault, setPropertiesDefault] = useState(null);
    const [propertiesOrginal, setPropertiesOrginal] = useState(null);
    const [undoActive, setUndoActive] = useState(false);
    const [showLineDisabled, setShowLineDisabled] = useState(false);

    var fontSizes = [
        { key: 6, value: 6 },
        { key: 7, value: 7 },
        { key: 8, value: 8 },
        { key: 9, value: 9 },
        { key: 10, value: 10 },
        { key: 11, value: 11 },
        { key: 12, value: 12 },
        { key: 14, value: 14 },
        { key: 16, value: 16 },
        { key: 18, value: 18 },
        { key: 20, value: 20 },
        { key: 22, value: 22 },
        { key: 24, value: 24 },
        { key: 26, value: 26 },
        { key: 28, value: 28 },
        { key: 36, value: 36 },
        { key: 48, value: 48 },
        { key: 72, value: 72 }
    ]

    const fontDecorations = [
        { name: translate('bold'), icon: icons.bold, value: 'bold' },
        { name: translate('italic'), icon: icons.italic, value: 'italic' },
        { name: translate('underline'), icon: icons.underline, value: 'underline' }
    ];

    const fontJustification = [
        { name: translate('justify_left'), icon: icons.alignLeft, value: constants.textAlignment.left },
        { name: translate('justify_center'), icon: icons.alignCenter, value: constants.textAlignment.center },
        { name: translate('justify_right'), icon: icons.alignRight, value: constants.textAlignment.right }
    ];

    mapEntityLabelEditor = {
        show: (o) =>{
            setHide(false);     

            var numLines = 1;
            if (_.isString(o.text)) {
                const lines = o.text.replace(/(\r\n|\n|\r)/g, '\r').split('\r');
                numLines = lines.length;
            }

            var width = 462;
            var height = 135 + (numLines * 19);
            var yOffset = 25;

            if (o.pixel.y - yOffset <= 0)
            {
                setPixel({ x: o.pixel.x - (width / 2), y: o.pixel.y + yOffset });
                setTop(false);
            }
            else
            {
                setPixel({ x: o.pixel.x - (width / 2), y: o.pixel.y - (height + yOffset) });
                setTop(true);
            }
            
            if (_.isString(o.text)) {
                setText(o.text);
                setTextOrginal(o.text);
            }
            
            if (_.isString(o.textDefault)) {
                setTextDefault(o.textDefault);
            }
            
            if (_.isObject(o.properties)) {
                setProperties(o.properties);
                setPropertiesOrginal(_.cloneDeep(o.properties));
            }
            
            if (_.isObject(o.propertiesDefault)) {
                setPropertiesDefault(o.propertiesDefault);
            }
            
            setShowLineDisabled(o.showLineDisabled);
            setUndoActive(false);

            onUpdate = _.isFunction(o.onUpdate) ? o.onUpdate : () => {};
            onDelete = _.isFunction(o.onDelete) ? o.onDelete : () => {};
        },
        hide: () =>{
            setHide(true);
        }
    };  

    return <Hideable className='app-map-entity-label-editor' hide={hide} style={{top: pixel.y + 'px', left: pixel.x + 'px'}}>        
        {top === false ? <div className='app-map-entity-label-editor-arrow-top' /> : '' }
        <div className='app-map-entity-label-editor-actions'>
            <div className='app-map-entity-label-editor-grouped-inputs'>
                <Button
                    className={'app-map-entity-label-editor-action-button'}
                    theme='action'
                    active={properties?.showLine}
                    icon={icons.line}
                    tooltip={properties?.showLine ? translate('hide_line') : translate('show_line')}
                    disabled={showLineDisabled}
                    onClick={() => { 
                        const newProps = { ...properties, showLine: !properties?.showLine };
                        setProperties(newProps);
                        onUpdate({ text: text, properties: newProps }); 
                        setUndoActive(true);
                    }}
                />        
                <Button
                    className={'app-map-entity-label-editor-action-button'}
                    theme='action'
                    active={!properties?.draggable}
                    icon={icons.lock}
                    tooltip={properties?.draggable ? translate('prevent_drag') : translate('allow_drag')}
                    onClick={() => { 
                        const newProps = { ...properties, draggable: !properties?.draggable };
                        setProperties(newProps);
                        onUpdate({ text: text, properties: newProps }); 
                        setUndoActive(true);
                    }}
                />        
                <ConfirmButton 
                    className={'app-map-entity-label-editor-action-button'}
                    theme='action'
                    icon={icons.trash}
                    tooltip={translate('delete')}
                    clearText={translate('delete')}
                    onConfirm={async () => { 
                        setHide(true); 
                        onDelete(); 
                    }}
                />
            </div>
            <div>
                <Button 
                    theme='secondary' 
                    size='small' 
                    icon={icons.clockRotateLeft} 
                    tooltip={translate('undo')} 
                    disabled={!undoActive}
                    onClick={()=>{ 
                        setText(textOrginal);
                        setProperties(_.cloneDeep(propertiesOrginal));
                        onUpdate({ text: textOrginal, properties: propertiesOrginal }); 
                    }} 
                />
                <Button 
                    className='app-map-entity-label-editor-spacer'
                    theme='secondary' 
                    size='small' 
                    icon={icons.bookFont} 
                    tooltip={translate('load_defaults')} 
                    disabled={propertiesDefault == null}
                    onClick={()=>{ 
                        setText(textDefault);
                        setProperties(_.cloneDeep(propertiesDefault));
                        onUpdate({ text: textDefault, properties: propertiesDefault }); 
                        setUndoActive(false);
                    }} 
                />
                <Button 
                    className='app-map-entity-label-editor-spacer'
                    theme='secondary' 
                    size='small' 
                    icon={icons.x} 
                    tooltip={translate('close')}
                    onClick={()=>{
                        setHide(true);
                    }} 
                />
            </div>
        </div>
        <div className='app-map-entity-label-editor-style'>
            <DropDown
                className='app-map-entity-label-editor-font-size'
                items={fontSizes} 
                display={'key'} 
                valueProperty={'value'} 
                selected={properties?.style?.fontSize} 
                width={'64px'}
                onChange={(o) => { 
                    if (o.userChanged) {
                        const newProps = { ...properties, style: { ...properties?.style, fontSize: o.value } };
                        setProperties(newProps);
                        onUpdate({ text: text, properties: newProps }); 
                        setUndoActive(true);
                    }
                }} 
            />
            <RadioButtonSet className='app-map-entity-label-editor-spacer'>				
                { 
                    fontDecorations.map((decoration, index) => {
                        return <Button 
                            key={index} 
                            icon={decoration.icon} 
                            tooltip={decoration.name} 
                            theme='map' 
                            active={properties?.style[decoration.value]} 
                            onClick={() => { 
                                const newProps = { ...properties, style: { ...properties?.style, [decoration.value]: !properties?.style[decoration.value] } };
                                setProperties(newProps);
                                onUpdate({ text: text, properties: newProps }); 
                                setUndoActive(true);
                            }} 
                        />
                    })
                }
            </RadioButtonSet>		
            <RadioButtonSet className='app-map-entity-label-editor-spacer'>				
                { 
                    fontJustification.map((justify, index) => {
                        return <Button 
                            key={index} 
                            icon={justify.icon} 
                            tooltip={justify.name} 
                            theme='map' 
                            active={properties?.style.textAlign === justify.value} 
                            onClick={() => { 
                                const newProps = { ...properties, style: { ...properties?.style, textAlign: justify.value } };
                                setProperties(newProps);
                                onUpdate({ text: text, properties: newProps }); 
                                setUndoActive(true);
                            }} 
                        />
                    })
                }
            </RadioButtonSet>		
            <ColorBox 
                id='textColorBox'
                className='app-map-entity-label-editor-colorbox'
                defaultValue={ _.isObject(properties?.style.color) ? `rgba(${properties.style.color.r},${properties.style.color.g},${properties.style.color.b},${properties.style.color.a})` : 'white'} 
                value={ _.isObject(properties?.style.color) ? `rgba(${properties.style.color.r},${properties.style.color.g},${properties.style.color.b},${properties.style.color.a})` : 'white'} 
                editAlphaChannel={true}
                onValueChange={(o) => {
                    var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                    const newProps = { ...properties, style: { ...properties?.style, color: { r: parseFloat(colors[0]), g: parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) } } };
                    setProperties(newProps);
                    onUpdate({ text: text, properties: newProps }); 
                    setUndoActive(true);
                }} 
            />
            <Tooltip target='#textColorBox' className='app-map-entity-label-editor-tooltip' showEvent='dxhoverstart' hideEvent='dxhoverend'>
                <Position offset="0 -7"/>                
                <div>
                    {translate('text_color')}
                </div>
            </Tooltip>
            <ColorBox 
                id='backgroundColorBox'
                className='app-map-entity-label-editor-colorbox'
                defaultValue={ _.isObject(properties?.style.background) ? `rgba(${properties.style.background.r},${properties.style.background.g},${properties.style.background.b},${properties.style.background.a})` : 'white'} 
                value={ _.isObject(properties?.style.background) ? `rgba(${properties.style.background.r},${properties.style.background.g},${properties.style.background.b},${properties.style.background.a})` : 'white'} 
                editAlphaChannel={true}
                onValueChange={(o) => {
                    var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                    const newProps = { ...properties, style: { ...properties?.style, background: { r: parseFloat(colors[0]), g: parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) } } };
                    setProperties(newProps);
                    onUpdate({ text: text, properties: newProps }); 
                    setUndoActive(true);
                }} 
            />
            <Tooltip target='#backgroundColorBox' className='app-map-entity-label-editor-tooltip' showEvent='dxhoverstart' hideEvent='dxhoverend'>
                <Position offset="0 -7"/>                
                <div>
                    {translate('background_color')}
                </div>
            </Tooltip>
        </div>
        <div className='app-map-entity-label-editor-text'>
            <TextArea 
                className='app-map-entity-label-editor-input'
                minHeight={35} 
                maxHeight={200}
                value={text}
                valueChangeEvent={'change keyup'}
                onChange={(o) => { 
                    if (o.userChanged) {
                        setText(o.value);
                        onUpdate({ text: o.value, properties: properties }); 
                        setUndoActive(true);
                    }
                }}
            />
        </div>
        {top === true ? <div className='app-map-entity-label-editor-arrow-bottom' /> : '' }        
    </Hideable>
}