// App imports
import { icons, Icon } from '../../../../base/icon/icon';
import { translate } from '../../../../../utils/translation';
import { mapBooks } from '../../../../../modules/mapbooks';
import { Button } from '../../../../base/button/button';
import { Description } from '../../../../base/description/description';

const _ = require("lodash");

export function MapBookError({data,onClose}) {

    return !_.isObject(data) ? '' :
        <>
            <div className='app-map-book-error-title'>
                {translate('invalid_mapbook')}
                <Button theme='action' icon={icons.x} tooltip={translate('close')} onClick={() => { onClose(); }}/>
            </div>
            <div className='app-map-book-error-container'>
                <Description className='app-map-book-error-description' description={translate('map_book_description')}></Description>
                
                <div className='app-map-book-error-list-container'>
                    <div className='app-map-book-error-list'>
                        { data.Errors.length === 0 ? '' : 
                            <div>
                                <div className='app-map-book-error-1st'>{translate('map_book')}</div>
                                <div className='app-map-book-error-mid'>{translate('base_object')}</div>  
                                {
                                data.Errors.map((error, index) => {
                                    return ( 
                                            <div>
                                                <div className='app-map-book-error-mid-list'>{error.ObjectName}</div>
                                                <div className='app-map-book-error-mid-list'>{mapBooks.getErrorObjectTypeName(error.ObjectType)}</div>
                                                {
                                                    error.ErrorCodes.map((errorCode,i) => {
                                                        return <div key={i} className='app-map-book-error-mid'>
                                                            <Icon className='app-map-book-error-warning' icon={icons.warning} />
                                                            {mapBooks.getErrorCodeName(errorCode)}
                                                    </div>
                                                    })
                                                }
                                            </div>
                                    )
                                }) 
                            }  
                            </div>             
                        }

                        { data.ErrorPages.length === 0 ? '' : 
                            <div className='app-map-book-error-error-pages-container'>
                                {
                                    data.ErrorPages.map((error, index) => {
                                        return ( <div>                            
                                                    <div>
                                                        {error.Bookmark}
                                                    </div>
                                                    <div>
                                                        {`${translate('page')} ${error.Sequence}`}
                                                    </div>                                    
                                                
                                                    {
                                                    error.Errors.map((pageError) => {
                                                        return <div>
                                                            <div className='app-map-book-error-2nd'>{pageError.ObjectName}</div>
                                                            <div className='app-map-book-error-mid'>{mapBooks.getPageErrorObjectName(pageError.ObjectType)}</div>
                                                            {                                            
                                                                    pageError.ErrorCodes.map((errorCode,i) => {
                                                                        return <div key={i} className='app-map-book-error-mid'>
                                                                            <Icon className='app-map-book-error-warning' icon={icons.warning} />
                                                                            {mapBooks.getErrorCodeName(errorCode)}
                                                                        </div>
                                                                    })                                                    
                                                            }
                                                        </div>
                                                    })
                                                    }
                                            </div>
                                        )
                                    }) 
                                
                                }
                            </div>             
                        }                  
                    </div>        
                </div>
            </div>
        </>        
}