// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { projects as projectsModule } from '../../../../modules/projects';
import { helpers } from '../../../../utils/helpers';
import { Loader } from '../../../base/loader/loader';

const _ = require("lodash");

export function MapUsage({mapId, onClose}) {  //todo basic implementation, needs to be improved

    const [mapUsageData, setMapUsagedata] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const getMapUsageData = useCallback(
        async ()=>{        
            if(!mapId)
                return;
            
            setLoaded(false);

            var o = {mapId: mapId, showMyUsage: true}
            const data = await projectsModule.getFilteredSavedMapUsage(o);
            
            if (_.isObject(data)) {
                setMapUsagedata(data.SavedMapUsages[0]);
            }
            setLoaded(true);
        }, [mapId]
    );

    useEffect(() => {
        getMapUsageData();
    }, [getMapUsageData])

    const close = () => {
        setMapUsagedata(null); 
        setLoaded(false);
        onClose();     
    };

    return <>
        
        <div>
            <div className='app-projects-available-maps-usage-header'>      
                <div className='app-projects-available-maps-usage-title'>{translate('map_usage')}</div>
                <div className='app-projects-available-maps-usage-actions'>
                    <Button theme='secondary' size='small' icon={icons.x} tooltip={translate('close')} onClick={() => { close(); }}/>                        
                </div>  
            </div> 
            <div>
                <Loader theme='generate' className='app-card-body-loader app-projects-available-maps-usage-loader-container' loaded={loaded} />
                {
                mapUsageData !== null ? 
                <div className='app-projects-available-maps-usage'>  
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <ul className='app-projects-available-maps-usage-layout'>
                                        <li><label>{translate('name')}</label><div>{mapUsageData.Name}</div></li>
                                        <li><label>{translate('date_created')}</label><div>{helpers.formatDate({ date: helpers.convertJSONDate(mapUsageData.DateCreated), format: 'MM/DD/YYYY h:mm a' })} </div></li>
                                        <li><label>{translate('last_accessed')}</label><div>{helpers.formatDate({ date: helpers.convertJSONDate(mapUsageData.LastAccessDate), format: 'MM/DD/YYYY h:mm a' })} </div></li>
                                        <li><label>{translate('times_loaded')}</label><div>{mapUsageData.LoadCount}</div></li>
                                        <li><label>{translate('user_shared_count')}</label><div>{mapUsageData.SharedCount}</div></li>
                                    </ul>  
                                </td>
                            </tr>
                        </tbody>
                    </table>                    
                </div> : ''
            }   
            </div>
        </div> 
    </>
}
