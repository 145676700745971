// React imports
import { useEffect, useState } from 'react';

//App imports
import { translate } from '../../../../../utils/translation';
import { Button } from '../../../../base/button/button';
import { icons } from '../../../../base/icon/icon';
import { constants } from '../../../../../utils/constants';
import { TextBox } from '../../../../base/textBox/textBox';
import { Icon } from '../../../../base/icon/icon';
import { Bar } from '../../../../base/bar/bar';
import { helpers } from '../../../../../utils/helpers';
import { MenuButton } from '../../../../base/menuButton/menuButton';
import { Hideable } from '../../../../base/hideable/hideable';
import { GeoFeedDataEdit } from './geoFeedDataEdit';
import { UploadPhotos } from '../../../photos/uploadPhotos/uploadPhotos';
import { geoFeeds as geoFeedsModule } from '../../../../../modules/geoFeeds';
import { RadioButtonSet } from '../../../../base/radioButtonSet/radioButtonSet';
import { Description } from '../../../../base/description/description';

const _ = require('lodash');

export function GeoFeedCreationWizardStep1({geoFeed,updateGeoFeed,onReadyNext}) {
    
    const [hideDataEdit, setHideDataEdit] = useState(true);
    const [hideUpload, setHideUpload] = useState(true);
    const [editSourceId, setEditSourceId] = useState(null);
    const [allowedExtensionsByType, setAllowedExtensionsByType] = useState([]);

    useEffect(() => {
        if (onReadyNext)
                onReadyNext(geoFeed.sources.length>0);
    }, [onReadyNext,geoFeed])


    const sourceType =
    {
        url: 0,
        file: 1,
        data: 2
    };

    const addUrl = async () =>{ 
        updateGeoFeed({ ...geoFeed, sources: [ ...geoFeed.sources, { id: helpers.newGuid(), type: sourceType.url, imageOverlay: false } ] });
    }; 
    
    const addFile = async () =>{ 
        updateGeoFeed({ ...geoFeed, sources: [ ...geoFeed.sources, { id: helpers.newGuid(), type: sourceType.file, imageOverlay: false, name:'', fileId:'' } ] });      
    }; 

    const addData = async () =>{ 
        updateGeoFeed({ ...geoFeed, sources: [ ...geoFeed.sources, { id: helpers.newGuid(), type: sourceType.data, imageOverlay: false, data: null } ] });      
    }; 

    function deleteSource (id){
        updateGeoFeed({ ...geoFeed, sources: geoFeed.sources.filter(x => x.id !== id) }); 
    };

    function handleFileUpload (o){
        if (_.isObject(o)) {
            updateGeoFeed({...geoFeed, sources: geoFeed.sources.map(source => {
                if (source.id === editSourceId) {
                    return { ...source, fileId: o.items[0].resultId, name: o.items[0].name};
                } else {
                    return source;
                }
                })});       
                setEditSourceId(null)            
        }

        setHideUpload(true);
    };    

    const showKMZSourceOverlayType = (geoFeedRow)=>{
		return <RadioButtonSet className='app-geo-feeds-creation-wizard-source-overlay-type'>
				{[{ 
					key: 0, tooltip: translate('standard'), text: translate('standard'), disabled: false  
				},			
				{ 
					key: 1, tooltip: translate('static_image'), text: translate('static_image'), disabled: false 
				}]
				.map((type, i) =>{
					return <Button key={type.key} 
                            active={(geoFeedRow.isImageOverlay && type.key === 1) || (!geoFeedRow.isImageOverlay && type.key === 0)} 
                            tooltip={type.tooltip} theme='secondary' size='medium' text={type.text} disabled={false} 
                            onClick={() => {                             
                                updateGeoFeed((prevState) => ({...prevState, sources: prevState.sources.map(source => {
                                    if (source.id === geoFeedRow.id) {
                                        return { ...source, isImageOverlay: !geoFeedRow.isImageOverlay};
                                    } else {
                                        return source;
                                    }
                                    })}));                                                                                                                                   
                            }} />
				})}			
			</RadioButtonSet>
	};	

    var getRow = (geoFeedRow) =>{

        switch (geoFeedRow.type)
        {
            default:
            case sourceType.url:
                return <div key={geoFeedRow.id} className={'app-geo-feeds-creation-wizard-source-row'}>           
                            <div className={'app-geo-feeds-creation-wizard-source-type-icon'}>                                
                                <Icon className={'app-geo-feeds-creation-wizard-source-type'} icon={icons.globe} />
                            </div>               
                            <div className='app-geo-feeds-creation-wizard-text'>
                                <TextBox className='app-geo-feeds-creation-wizard-name' label={translate('name')} value={geoFeedRow.name} height={'36px'}
                                    onChange={(o) => {
                                        updateGeoFeed({...geoFeed, sources: geoFeed.sources.map(source => {
                                            if (source.id === geoFeedRow.id) {
                                                return { ...source, name: o.value };
                                            } else {
                                                return source;
                                            }
                                            })});                                                                                                                                                
                                }} />
                            </div>                                     
                            <div className='app-geo-feeds-creation-wizard-text'>
                                <TextBox className='app-geo-feeds-creation-wizard-url' label={translate('data_feed_url')} value={geoFeedRow.url}  height={'36px'}
                                    onChange={(o) => {
                                        updateGeoFeed({...geoFeed, sources: geoFeed.sources.map(geo => {
                                            if (geo.id === geoFeedRow.id) {
                                                return { ...geo, url: o.value };
                                            } else {
                                                return geo;
                                            }
                                            })});                                                                                                                                                
                                }} />     
                            </div>    
                            {
                                geoFeed.type !== constants.geoFeed.kmz ? '' : showKMZSourceOverlayType(geoFeedRow)             
                            }   
                            <div className='app-geo-feeds-creation-wizard-actions'>
                                <Button theme='simple' icon={icons.trash} tooltip={translate('remove')} onClick={() => { deleteSource(geoFeedRow.id) }} />
                            </div>
                        </div>
            case sourceType.file:
                return <div key={geoFeedRow.id} className={'app-geo-feeds-creation-wizard-source-row'}>     
                            <div className={'app-geo-feeds-creation-wizard-source-type-icon'}>
                                <Icon className={'app-geo-feeds-creation-wizard-source-type'} icon={icons.file} />
                            </div>         
                            <div className='app-geo-feeds-creation-wizard-text'>
                                <TextBox className='app-geo-feeds-creation-wizard-name' label={translate('name')} value={geoFeedRow.name} height={'36px'}
                                    onChange={(o) => {
                                        updateGeoFeed((prevState) => ({...prevState, sources: prevState.sources.map(geo => {
                                            if (geo.id === geoFeedRow.id) {
                                                return { ...geo, name: o.value };
                                            } else {
                                                return geo;
                                            }
                                            })}));                                                     
                                    }}
                                />
                            </div>                          
                            {
                                geoFeed.type !== constants.geoFeed.kmz ? '' : showKMZSourceOverlayType(geoFeedRow)             
                            }                                   
                            <div className='app-geo-feeds-creation-wizard-actions'>
                                <Button theme='simple' icon={icons.gear} tooltip={translate('edit')} 
                                onClick={()=> { 
                                        setEditSourceId(geoFeedRow.id);
                                        setAllowedExtensionsByType(geoFeedsModule.getAllowedExtensionsByType(geoFeed.type));
                                        setHideUpload(false);                                
                                    }
                                }                             
                                />
                                <Button theme='simple' icon={icons.ellipses} tooltip={translate('reorder')} />
                                <Button theme='simple' icon={icons.trash} tooltip={translate('remove')} onClick={() => { deleteSource(geoFeedRow.id) }} />
                            </div>
                        </div>
            case sourceType.data:
                return <div key={geoFeedRow.id} className={'app-geo-feeds-creation-wizard-source-row'}>
                            <div className={'app-geo-feeds-creation-wizard-source-type-icon'}>
                                <Icon className={'app-geo-feeds-creation-wizard-source-type'} icon={icons.database} />
                            </div>
                            <div className='app-geo-feeds-creation-wizard-text'>
                                <TextBox className='app-geo-feeds-creation-wizard-name' label={translate('name')} value={geoFeedRow.name} height={'36px'}
                                    onChange={(o) => {
                                        updateGeoFeed({...geoFeed, sources: geoFeed.sources.map(source => {
                                            if (source.id === geoFeedRow.id) {
                                                return { ...source, name: o.value };
                                            } else {
                                                return source;
                                            }
                                            })});                                                                                                                                                
                                }} />  
                            </div>       
                            {
                                geoFeed.type !== constants.geoFeed.kmz ? '' : showKMZSourceOverlayType(geoFeedRow)             
                            }                                                                      
                            <div className='app-geo-feeds-creation-wizard-actions'>
                                <Button theme='simple' icon={icons.gear} tooltip={translate('edit')} 
                                    onClick={()=>{ 
                                        setEditSourceId(geoFeedRow.id);
                                        setHideDataEdit(false); 
                                    }}
                                />
                                <Button theme='simple' icon={icons.ellipses} tooltip={translate('reorder')} />
                                <Button theme='simple' icon={icons.trash} tooltip={translate('remove')} onClick={() => { deleteSource(geoFeedRow.id) }} />
                            </div>
                        </div>              
        }
    };    


    return <div className='app-geo-feeds-creation-wizard-container'>
            <Hideable hide={hideDataEdit}>                  
                <GeoFeedDataEdit geoFeed={geoFeed.sources.find(x => x.id === editSourceId)} updateGeoFeed={updateGeoFeed}                 
                        onClose={()=> {
                                    setHideDataEdit(true);
                                    setEditSourceId(null);
                                }} 
                />   
            </Hideable>   
            <Hideable  hide={hideUpload}>  
                <UploadPhotos className={'app-geo-feeds-creation-wizard-photo-upload'} entity={null} photoCount={0} onClose={(o)=>handleFileUpload(o)} //todo temp use of UploadPhoto, needs refactor
                    maxNumberOfFiles={1}
                    useType={2} 
                    allowedExtensions={allowedExtensionsByType}
                    instructions={geoFeed.type === constants.geoFeed.kml ? translate('geo_feed_kml_instructions') : ''}
                />    
            </Hideable>             

            <Hideable hide = {!hideDataEdit || !hideUpload}>
                <div className='app-geo-feeds-creation-wizardlabels'>{translate('what_type_are_sources')}</div>
                <div className='app-geo-feeds-creation-wizard-rows'>
                    {
                        [
                            { id: constants.geoFeed.geoRss, text: 'GeoRSS' },
                            { id: constants.geoFeed.kml, text: 'KML' },
                            { id: constants.geoFeed.kmz, text: 'KMZ' },
                            { id: constants.geoFeed.geoJson, text: 'GeoJSON' },
                            { id: constants.geoFeed.wkt, text: 'WKT' },
                            { id: constants.geoFeed.gpx, text: 'GPX' },
                        ].map((source) => {
                            return (
                                <div key={source.id} className='app-option-selector'>
                                    {geoFeed.type === source.id ? 
                                    <div className='app-option-selector-check'>
                                        <Icon icon={icons.check} />
                                    </div> : ''}
                                    <Bar className={'app-geo-feeds-creation-wizard-bar-buttons app-geo-feeds-creation-wizard-bar-long'} 
                                        text={source.text} 
                                        disabled={geoFeed.id !== helpers.emptyGuid()}
                                        active={geoFeed.type === source.id} 
                                        onClick={() => { 
                                            updateGeoFeed({...geoFeed, type: source.id});
                                        }} 
                                    /> 
                                </div>
                            );
                        })
                    }
                </div>    
                <div className='app-geo-feeds-creation-wizard-add'>
                    { geoFeed.type === constants.geoFeed.kmz ? 
                            <Description description={translate('geo_feed_kmz_instructions')} bullets={[ 
                                {id: 1, text: translate('standard')}, 
                                {id: 100, bullets: [ 
                                    {id: 2, text: translate('max_uncompressed_kmz_image_file_size_500kb')}, 
                                    {id: 3, text: translate('max_uncompressed_kml_file_size_10mb')}, 
                                ]},
                                {id: 7, text: translate('static_image')}, 
                                {id: 102, bullets: [ 
                                    {id: 9, text: translate('static_image_overlay_no_interaction')},
                                    {id: 8, text: translate('static_image_overlay_file_restrictions')}
                                ]} 
                            ]} 
                        />                            
                            
                            : ''}
                    <MenuButton icon={icons.plus} 
                        tooltip={translate('add')} 
                        theme='primary' 
                        size='small'
                        items={[
                            {icon: icons.plus,  text: translate("add_url"), onClick: () =>{ addUrl(); }},
                            {icon: icons.plus, text: translate("file"), onClick: () =>{ addFile(); }},
                            {icon: icons.plus, text: translate("data"), onClick: () =>{ addData(); }}
                        ]}
                    />    
                </div>

                <div className='app-geo-feeds-creation-wizard-sources'>
                {
                    geoFeed.sources.length === 0 ? <div className='app-geo-feeds-creation-wizard-empty'>{translate('add_geo_feed_sources')}</div> :
                    geoFeed.sources.map((geoFeed) => {                 
                        return getRow(geoFeed);
                    })
                }
                </div>
            </Hideable>
        
    </div>
}