// React imports
import { useState, useEffect, useRef, useCallback } from 'react';

// Third party imports
import FileUploader from 'devextreme-react/file-uploader';

// App imports
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from "../../../../utils/translation";
import { legacyEndpoints } from "../../../../services/legacyEndpoints";
import { successToast, errorToast } from '../../../base/toast/toast';
import { EditPhoto } from '../../photos/editPhoto/editPhoto';
import { helpers } from '../../../../utils/helpers';
import { upload } from '../../../../modules/upload';
import { photos as photosModule } from '../../../../modules/photos';

export function UserPhoto({ editPhotoMode, onUpdate, onClose }) {

    const [photoData, setPhotoData] = useState(null);
    const [newPhotoQueueId, setNewPhotoQueueId] = useState(null);
    const [showEditNewPhoto, setShowEditNewPhoto] = useState(false);
    const uploadQueueId = useRef(helpers.newGuid());
    const dropzoneExternal = useRef(null);

    useEffect(() => {
        dropzoneExternal.current = "dropzone-external" + helpers.newGuid();
    }, []);

    const init = useCallback(
        async () => {
            var userPhotoInformation = await photosModule.getUserPhotoInformation();

            setPhotoData(userPhotoInformation);
        },
        []
    );

    useEffect(() => {
        init();
    }, [init]);

    const uploadFileChunk = async (file) => {
        var fileId = helpers.newGuid();

        await upload.uploadFileChunk(file, {
            uploadQueueId: uploadQueueId.current,
            fileId: fileId
        });

        return fileId;
    };

    const uploadNewUserPhoto = async (file) => {
        var allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        var regExpresson = new RegExp(".(" + allowedExtensions.join("|") + ")$", "i");
        if (!(regExpresson).exec(file.name)) {
            alert(`${translate('file_type_not_allowed')}: .${file.name.split(".")[1]}`);
            return false;
        }

        if (file.size > 10000000) {
            alert(translate('file_size_too_large'));
            return false;
        }

        var fileId = await uploadFileChunk(file, file.type);

        var queueId = await photosModule.addUserPhotoToQueueFromTempDirectory({
            uploadQueueId: uploadQueueId.current,
            fileId: fileId,
            contentType: file.type
        });

        setNewPhotoQueueId(queueId);
        setShowEditNewPhoto(true);
    };

    const closePhotoEditor = () => {
        onClose();
    };

    const saveEditedNewImage = async () => {
        const result = await photosModule.publish({
            uploadQueueId: uploadQueueId.current,
            data: []
        });

        successToast(translate('success'));

        onUpdate();
        closePhotoEditor();
    };

    const saveEditedImage = async (image) => {
        var file = convertToFile(image, "userPhoto");

        uploadQueueId.current = helpers.newGuid();

        var fileId = await uploadFileChunk(file.file, file.type);

        await photosModule.addUserPhotoToQueueFromTempDirectory({
            uploadQueueId: uploadQueueId.current,
            fileId: fileId,
            contentType: file.type
        });
        
        const result = await photosModule.publish({
            uploadQueueId: uploadQueueId.current,
            data: []
        });

        onUpdate();
        closePhotoEditor();
        successToast(translate('success'));
    };

    const convertToFile = (image, name) => {
        var arr = image.split(','),
            mimeType = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return { file: new File([u8arr], name, { type: mimeType }), type: mimeType };
    };

    return <>
        {
            editPhotoMode ? '' :
                <div className='app-user-profile-image-upload-container'>
                    <div className='app-user-profile-image-upload-actions'>
                        <Button
                            className={'app-user-profile-image-upload-actions-buttons'}
                            theme='secondary'
                            size='tiny'
                            icon={icons.x}
                            tooltip={translate('cancel')}
                            onClick={() => { onClose(); }}
                        />
                    </div>
                    <div className={dropzoneExternal.current}>
                        <div className='app-user-profile-image-upload-location'>
                            <div className="dropzone-text">
                                {translate('photo_upload_message')}
                            </div>
                        </div>
                    </div>
                    <FileUploader
                        id={"file-uploader" + helpers.newGuid()}
                        multiple={true}
                        accept={'*'}
                        uploadMode={'instantly'}
                        uploadFile={uploadNewUserPhoto}
                        dialogTrigger={'.' + dropzoneExternal.current}
                        dropZone={'.' + dropzoneExternal.current}
                        visible={false}
                    />
                </div>
        }
        {
            showEditNewPhoto ?
                <div id='photo_edit_photo_container'>
                    <EditPhoto photo={{ type: photoData.ContentType, name: 'userPhoto' }}
                        cropShape='round' 
                        showGrid={false} 
                        aspect={1}
                        imageURL={legacyEndpoints.handlers.getUploadUrl({ fileId: newPhotoQueueId, task: 'get' })}
                        onSaveEditedImage={saveEditedNewImage}
                        onClose={closePhotoEditor}
                    />
                </div> : ''
        }
        {
            editPhotoMode ?
                <div id='photo_edit_photo_container'>
                    {photoData === null ? '' :
                        <EditPhoto photo={{ type: photoData.ContentType, name: 'userPhoto' }}
                            cropShape='round'
                            aspect={1}
                            showGrid={false}
                            imageURL={`data:${photoData.ContentType};base64,${photoData.Base64String}`}
                            onSaveEditedImage={saveEditedImage}
                            onClose={closePhotoEditor}
                        />
                    }
                </div> : ''
        }
    </>
}